import { DataItem } from "vis-timeline";
import { baseURL } from "../../constants";
import { ScheduleListDto, ScheduleResourceType } from "../../services";

type Props = {
    data: DataItem,
    schedule: ScheduleListDto | undefined
}

export default function ScheduleTimelineAssetItem({ data, schedule }: Props) {

    const thumnailLink = baseURL + "/api/customer/" + schedule?.customerId + "/assets/" + schedule?.resourceId + "/thumbnail/" + schedule?.assetThumbnail;

    return (
        <div>
            <img
                src={thumnailLink}
                alt="logo" />
            <div>
                <p>{schedule?.name}</p>
                <small>{schedule?.resourceType === ScheduleResourceType.Image ? "IMAGE" : "VIDEO"}</small>
            </div>
        </div>
    );
}