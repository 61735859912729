import { useRecoilState } from "recoil";
import { ModalTypeState, SelectedUserState } from "../../atoms";
import { BaseModal } from "./BaseModal";
import { UsersClient } from "../../services";
import { GetNewInstance } from "../../helpers/HttpCommon"
import { ModalTypes } from "./ModalTypes";


type Props = {
    onSuccess: () => void;
}

export function ActivateUserModal({ onSuccess }: Props) {

    const [, setModalType] = useRecoilState(ModalTypeState);
    const [selectedUser, setSelectedUser] = useRecoilState(SelectedUserState);

    const client = new UsersClient(undefined, GetNewInstance());

    const onCancel = () => {
        setSelectedUser(null);
        setModalType(null);
    }

    const onActivateClick = () => {
        selectedUser?.email && client.activateUser(selectedUser?.email)
            .then(x => {
                onSuccess();
            })
            .finally(() => {
                setSelectedUser(null);
                setModalType(null);
            })
    }

    return (
        <BaseModal id={ModalTypes.ActivateUserModal} title="Activate user" description={"Are you sure you want to activate " + selectedUser?.firstName + " " + selectedUser?.lastName + "?"}>
            <div className="py-4 text-sm text-white">
                <div className="mt-8 flex justify-end gap-4">
                    <button onClick={onCancel}
                        className="py-2.5 px-4 rounded-md border border-page-border bg-white bg-opacity-5 hover:bg-opacity-10">Cancel
                    </button>
                    <button onClick={onActivateClick}
                        className="py-2.5 px-4 rounded-md bg-button-bg-primary-default hover:bg-button-bg-primary-hover active:shadow-button-bg-primary-default">
                        Activate</button>
                </div>
            </div>
        </BaseModal>
    );
}