import { IconStyle } from "../buttons";
import MainHeader from "./MainHeader";
import { AdminSidebar, CustomerSidebar } from "../sidebar";
import { Store } from "../../helpers";
import { LogoutModal } from "../modal";
import 'react-toastify/dist/ReactToastify.css';

type Props = {
    title: string,
    showButton: boolean,
    buttonText?: string,
    icon_style?: IconStyle,
    icon?: JSX.Element,
    children: JSX.Element | string
}

export function BasePageLayout({ title, showButton, buttonText, icon, icon_style, children }: Props) {

    const usersCustomerId = Store.getCustomerId();

    return (
        <div className="flex">
            <div className="h-screen w-full flex bg-bg text-white">
                {usersCustomerId ? <CustomerSidebar /> : <AdminSidebar />}
                <div className="h-screen w-full flex flex-col">
                    <MainHeader title={title} buttonText={buttonText} icon_style={icon_style} icon={icon} showButton={showButton} />
                    {children}
                </div>
            </div>
            <LogoutModal />
            
        </div>
    );
}