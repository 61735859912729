import { UilEllipsisH, UilArrowLeft, UilArrowRight } from '@iconscout/react-unicons';
import { useEffect } from 'react';

type Props = {
    totalPageCount: number,
    currentPageNumber: number,
    setCurrentPageNumber: (value: number) => void
}

export function Pagination({ currentPageNumber, totalPageCount, setCurrentPageNumber }: Props) {


    const pageNumberShownLimit = 7;

    useEffect(() => { }, [currentPageNumber])

    const handleNextPageNumber = () => {
        setCurrentPageNumber(currentPageNumber + 1);
    }
    const handlePreviousPageNumber = () => {
        setCurrentPageNumber(currentPageNumber - 1);
    }

    if (totalPageCount === 1) {
        return (<></>);
    }
    else if (totalPageCount <= pageNumberShownLimit) {
        const pageNumbers = Array.from(Array(totalPageCount).keys()).map(x => x + 1);

        return (
            <div className="flex justify-end my-5">
                <ul className="flex items-center border rounded-lg border-page-border text-sm">
                    {
                        currentPageNumber !== 1 && <li onClick={handlePreviousPageNumber} className={`cursor-pointer py-3 px-5 border-r border-page-border flex items-center `}>
                            <UilArrowLeft className="w-5 h-5 mr-2" />
                            Previous
                        </li>
                    }
                    {
                        pageNumbers.map(x => <PagingNumber key={x} pageNumber={x} currentPageNumber={currentPageNumber} setCurrentPageNumber={setCurrentPageNumber} ></PagingNumber>)
                    }
                    {
                        currentPageNumber !== totalPageCount && <li onClick={handleNextPageNumber} className={`cursor-pointer py-3 px-5 flex items-center `}>
                            Next
                            <UilArrowRight className="w-5 h-5 ml-2" />
                        </li>
                    }
                </ul>
            </div>
        );
    }
    else {
        const leftCount = currentPageNumber - 1;
        const rightCount = totalPageCount - currentPageNumber;
        const showLeftDots = leftCount > 3;
        const showRightDots = rightCount > 3;
        const rightExtraNumbers = showRightDots ? 0 : 3 - rightCount;
        const smallestPageNumber = showLeftDots ? currentPageNumber - 3 - rightExtraNumbers : 1;
        const pageNumbers = Array.from(Array(pageNumberShownLimit).keys()).map(x => x + smallestPageNumber);

        return (
            <div className="flex justify-end my-5">
                <ul className="flex items-center border rounded-lg border-page-border text-sm">
                    {
                        currentPageNumber !== 1 && <li onClick={handlePreviousPageNumber} className={`cursor-pointer py-3 px-5 border-r border-page-border flex items-center `}>
                            <UilArrowLeft className="w-5 h-5 mr-2" />
                            Previous
                        </li>
                    }
                    {showLeftDots &&
                        <> <PagingNumber pageNumber={1} currentPageNumber={currentPageNumber} setCurrentPageNumber={setCurrentPageNumber}></PagingNumber>
                            <li className={"py-3 px-5 border-r border-page-border"}>
                                <UilEllipsisH className="w-5 h-5" />
                            </li>
                        </>
                    }
                    {
                        pageNumbers.map(x => <PagingNumber key={x} pageNumber={x} currentPageNumber={currentPageNumber} setCurrentPageNumber={setCurrentPageNumber}></PagingNumber>)
                    }
                    {showRightDots &&
                        <>
                            <li className={`py-3 px-5 border-r border-page-border ${showRightDots ? "" : "invisible"} `}>
                                <UilEllipsisH className="w-5 h-5" />
                            </li>
                            <PagingNumber pageNumber={totalPageCount} currentPageNumber={currentPageNumber} setCurrentPageNumber={setCurrentPageNumber}></PagingNumber>
                        </>
                    }
                    {
                        currentPageNumber !== totalPageCount && <li onClick={handleNextPageNumber} className={`cursor-pointer py-3 px-5 flex items-center `}>
                            Next
                            <UilArrowRight className="w-5 h-5 ml-2" />
                        </li>
                    }
                </ul>
            </div>
        );
    }
}

type PagingNumberProps = {
    currentPageNumber: number,
    pageNumber: number,
    setCurrentPageNumber: (value: number) => void
}

function PagingNumber({ currentPageNumber, pageNumber, setCurrentPageNumber: setPageNumber }: PagingNumberProps) {

    const onPageNumberSelected = () => {
        setPageNumber(pageNumber);
    }

    return (
        <li onClick={onPageNumberSelected} className={`py-3 px-5 border-r cursor-pointer border-page-border ${currentPageNumber === pageNumber && "bg-white bg-opacity-5"}`}>{pageNumber}</li>
    );
}