import { AssetAuditLogModal, DeleteAssetModal } from "../modal";
import { useRecoilState } from "recoil";
import { CurrentCustomerAssetSizeState, UploadedFileState } from "../../atoms";
import { useEffect, useRef, useState } from "react";
import { GetNewInstance, Store } from "../../helpers";
import {
  AssetListDto,
  AssetsClient,
  AssetSort,
  CustomersClient,
  PagedServerResponseOfAssetListDto,
} from "../../services";
import { AssetListItem } from "./AssetListItem";
import { AssetFilter } from "../filters";
import { AssetSorter } from "../sort";
import { FilterIsShownState } from "../../atoms/FilterIsShownState";
import { UilRedo, UilSearch } from "@iconscout/react-unicons";
import { FilterLines } from "../Svgs";
import { AssetDetailState } from "../../atoms/AssetDetailState";
import { AssetFilterState } from "../../atoms/AssetFilterState";
import { AssetSortState } from "../../atoms/AssetSortState";
import { AssetListPageState } from "../../atoms/AssetListPageState";
import { Pagination } from "../layouts";

export default function AssetList() {
  const [filterIsShown, setFilterIsShown] = useRecoilState(FilterIsShownState);
  const [, setAssetDetailState] = useRecoilState(AssetDetailState);
  const [assetFilterState, setAssetFilterState] =
    useRecoilState(AssetFilterState);
  const [assetSortState, setAssetSortState] = useRecoilState(AssetSortState);
  const [assets, setAssets] = useState<AssetListDto[]>([]);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [currentPageNumber, setCurrentPageNumber] =
    useRecoilState(AssetListPageState);
  const [totalPageNumber, setTotalPageNumber] = useState(1);
  const [, setCurrentCustomerAssetSize] = useRecoilState(
    CurrentCustomerAssetSizeState
  );
  const [uploadedFiles] = useRecoilState(UploadedFileState);

  const usersCustomerId = Store.getCustomerId();
  let timeOutId = useRef<NodeJS.Timeout>();
  const loggedInUser = Store.getLoggedInUser();

  const retrieveAssets = () => {
    const axiosInstance = GetNewInstance();
    const assetsClient = new AssetsClient(undefined, axiosInstance);
    const customersClient = new CustomersClient(undefined, axiosInstance);

    clearTimeout(timeOutId.current);
    timeOutId.current = setTimeout(() => {
      if (usersCustomerId && assetFilterState) {
        assetsClient
          .getAssets(
            usersCustomerId,
            searchTerm,
            null,
            assetFilterState.fileTypes ?? [],
            assetFilterState.fileCodecs ?? [],
            assetFilterState.aspectRatios ?? [],
            assetFilterState.resolution ?? [],
            assetFilterState.tags ?? [],
            assetSortState,
            currentPageNumber
          )
          .then((response: PagedServerResponseOfAssetListDto | null) => {
            response?.data && setAssets(response.data);
            response?.pageCount && setTotalPageNumber(response?.pageCount);
          })
          .catch((e: Error) => {
            console.log(e);
          });

        assetsClient
          .getAssetsIds(
            usersCustomerId,
            searchTerm,
            null,
            assetFilterState.fileTypes ?? [],
            assetFilterState.fileCodecs ?? [],
            assetFilterState.aspectRatios ?? [],
            assetFilterState.resolution ?? [],
            assetFilterState.tags ?? [],
            assetSortState
          )
          .then((x) => {
            x?.data &&
              setAssetDetailState({
                assetId: undefined,
                assetName: undefined,
                assetIds: x?.data,
              });
          })
          .catch((e: Error) => {
            console.log(e);
          });

        customersClient
          .getCustomerTotalFileSizeUsed(usersCustomerId)
          .then((response) => {
            setCurrentCustomerAssetSize(response?.data ?? 0);
          });
      }
    }, 300);
  };

  useEffect(() => {
    return () => {
      setAssetFilterState({
        excludedPlaylistId: "",
        fileTypes: [],
        fileCodecs: [],
        aspectRatios: [],
        resolution: [],
        tags: [],
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (uploadedFiles.every((x) => x.isFinished)) {
      retrieveAssets();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles]);

  useEffect(() => {
    retrieveAssets();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, assetFilterState, assetSortState, currentPageNumber]);

  const showFilter = () => {
    setFilterIsShown(true);
  };

  const handleSearchChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPageNumber(1);
  };

  return (
    <div className="h-full overflow-auto p-5 text-white scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
      <div className="flex justify-between mb-5 relative z-10">
        <div>
          <div className="h-11 rounded-md border border-page-border flex items-center bg-white bg-opacity-5 overflow-hidden">
            <UilSearch className="w-4 h-4 mx-4" />
            <input
              onChange={handleSearchChanged}
              className="h-full bg-transparent border-0"
              type="text"
              placeholder="Search for asset"
              value={searchTerm ?? ""}
            />
          </div>
        </div>
        <div className="flex items-center gap-3">
          {!filterIsShown &&
            ((assetFilterState !== null &&
              (assetFilterState.aspectRatios.length > 0 ||
                assetFilterState.fileTypes.length > 0 ||
                assetFilterState.resolution.length > 0 ||
                assetFilterState.tags.length > 0)) ||
              assetSortState !== AssetSort.FileName ||
              (searchTerm !== null && searchTerm !== "")) && (
              <UilRedo
                className="text-gray-100 cursor-pointer"
                onClick={() => {
                  setAssetFilterState({
                    aspectRatios: [],
                    fileTypes: [],
                    fileCodecs: [],
                    resolution: [],
                    tags: [],
                  });
                  setAssetSortState(AssetSort.FileName);
                  setSearchTerm("");
                }}
              />
            )}
          <AssetSorter />
          <div className="relative text-sm">
            <button
              onClick={showFilter}
              className="h-11 gap-2 px-4 rounded-md border border-page-border flex items-center bg-white bg-opacity-5 overflow-hidden"
            >
              <FilterLines />
              <span>Filter</span>
            </button>
          </div>
        </div>
      </div>
      <ul className="text-sm flex justify-start  flex-wrap gap-8 mb-8">
        {assets.map((x) => (
          <AssetListItem
            key={x.id}
            asset={x}
            onAssetDeletion={() => retrieveAssets}
            loggedInUser={loggedInUser}
          />
        ))}
      </ul>
      <Pagination
        totalPageCount={totalPageNumber}
        currentPageNumber={currentPageNumber}
        setCurrentPageNumber={setCurrentPageNumber}
      />
      <DeleteAssetModal
        onSuccess={() => {
          retrieveAssets();
        }}
      />
      <AssetFilter />
      <AssetAuditLogModal />
    </div>
  );
}
