import { atom } from "recoil";

export interface UploadState {
  id?: string | null;
  customerId: string;
  index: number;
  fileName: string;
  fileSize: number;
  percentCompleted: number;
  isFinished: boolean;
  isCanceled: boolean;
  isQued: boolean;
  handleCancel: (index: number) => void;
  handleResume: (index: number) => void;
}

export const UploadedFileState = atom<UploadState[]>({
  key: "uploadState",
  default: [],
});
