import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { PlaylistAssetSelectionItem, PlaylistAssetListItem } from ".";
import {
  AssetFilterState,
  FilterIsShownState,
  ModalTypeState,
  PlaylistUpdateState,
  SelectedPlaylistState,
} from "../../atoms";
import {
  UilAngleDown,
  UilAngleUp,
  UilPlus,
  UilSearch,
  UilSlidersVAlt,
} from "@iconscout/react-unicons";
import { Color } from "../../constants";
import { GetNewInstance, Store } from "../../helpers";
import {
  AssetListDto,
  AssetPlaylistListDto,
  AssetsClient,
  AssetStatus,
  AssetType,
  CanvasBasicDto,
  CanvasesClient,
  PagedServerResponseOfAssetListDto,
  PlaylistDetailDto,
  PlaylistsClient,
  PutAssetPlaylistDto,
  UpdatePlaylistDto,
} from "../../services";
import { ColorInput, NameLogo, TimeDuration } from "../Misc";
import { AssetFilter } from "../filters";
import { AssetUploadModal } from "../modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export function PlaylistEdit() {
  const [selectedPlaylist] = useRecoilState(SelectedPlaylistState);
  const [filterIsShown, setFilterIsShown] = useRecoilState(FilterIsShownState);
  const [color, setColor] = useState<keyof typeof Color>("Red");
  const [playlist, setPlaylist] = useState<PlaylistDetailDto | null>(null);
  const [playlistAssets, setPlaylistAssets] = useState<AssetPlaylistListDto[]>(
    []
  );
  const [assetFilterState, setAssetFilterState] =
    useRecoilState(AssetFilterState);
  const [searchedAsset, setSearchedAssets] = useState<AssetListDto[]>([]);
  const [isMinimalForm, setIsMinimalForm] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [totalPageCount, setTotalPageCount] = useState<number>(1);
  const [, setAssetUploadIsShowing] = useRecoilState(ModalTypeState);
  const [playlistUpdate, setPlaylistUpdate] =
    useRecoilState(PlaylistUpdateState);
  const [canvases, setCanvases] = useState<CanvasBasicDto[]>([]);
  const [showCanvasOption, setShowCanvasOption] = useState(false);
  const usersCustomerId = Store.getCustomerId();
  const canvasClient = new CanvasesClient(undefined, GetNewInstance());
  const selectedAssetListDto = useRef<AssetListDto | undefined>();
  const dragItem = useRef<number | undefined>();
  const dragOverItem = useRef<number | undefined>();
  const timeOutId = useRef<NodeJS.Timeout>();
  const playlistsCanvas = useRef<string[]>([]);
  let canvasTimeOutId: NodeJS.Timeout;
  const loggedInUser = Store.getLoggedInUser();
  const navigate = useNavigate();

  const retrieveAssets = () => {
    clearTimeout(timeOutId.current);
    timeOutId.current = setTimeout(() => {
      const assetClient = new AssetsClient(undefined, GetNewInstance());
      assetFilterState &&
        usersCustomerId &&
        assetClient
          .getAssets(
            usersCustomerId,
            searchTerm,
            null,
            assetFilterState.fileTypes ?? [],
            assetFilterState.fileCodecs ?? [],
            assetFilterState.aspectRatios ?? [],
            assetFilterState.resolution ?? [],
            assetFilterState.tags ?? [],
            null,
            currentPageNumber
          )
          .then((response: PagedServerResponseOfAssetListDto | null) => {
            if (currentPageNumber !== 1)
              setSearchedAssets([...searchedAsset, ...(response?.data ?? [])]);
            else setSearchedAssets(response?.data ?? []);

            response?.pageCount && setTotalPageCount(response?.pageCount);
          })
          .catch((e: Error) => {
            console.log(e);
          });
    }, 300);
  };

  useEffect(() => {
    selectedPlaylist === null && navigate("/playlist");

    const client = new PlaylistsClient(undefined, GetNewInstance());
    selectedPlaylist &&
      usersCustomerId &&
      client
        .getPlaylistFullDetailById(usersCustomerId, selectedPlaylist.id)
        .then((response) => {
          if (response?.data) {
            setPlaylistUpdate(
              new UpdatePlaylistDto({
                id: response?.data.id,
                name: response?.data.name,
                description: response?.data.description,
                defaultImageDurationInSecounds:
                  response?.data.defaultImageDurationInSecounds,
                transitionDurationInSecounds:
                  response?.data.transitionDurationInSecounds,
                color: response?.data.color,
                updatePlaylistAssets: response?.data.assets?.map(
                  (y) =>
                    new PutAssetPlaylistDto({
                      assetPlaylistId: y.assetPlaylistId,
                      assetId: y.id,
                      durationInSecound: y.playlistDurationInSecounds,
                    })
                ),
                canvasIds: response?.data.canvasIds,
              })
            );
            playlistsCanvas.current = response?.data.canvasIds ?? [];
            setPlaylist(response?.data);
            setColor(response?.data.color as keyof typeof Color);
            setPlaylistAssets(response?.data?.assets ?? []);
            loadCanvases();
          }
        })
        .catch((error) => {
          console.log(error);
        });

    retrieveAssets();

    return () => {
      setAssetFilterState({
        excludedPlaylistId: "",
        fileTypes: [],
        fileCodecs: [],
        aspectRatios: [],
        resolution: [],
        tags: [],
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    retrieveAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber]);

  useEffect(() => {
    if (currentPageNumber === 1) retrieveAssets();
    else setCurrentPageNumber(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, assetFilterState]);

  const loadCanvases = () => {
    usersCustomerId &&
      canvasClient.getAllCanvases(usersCustomerId).then((x) => {
        x?.data && setCanvases(x?.data);
      });
  };

  const handlDragReorder = () => {
    if (
      dragItem.current === undefined ||
      dragOverItem.current === undefined ||
      playlistAssets === undefined
    )
      return;

    let currentAssets = [...playlistAssets];

    const draggedItem = currentAssets.splice(dragItem.current, 1)[0];
    currentAssets.splice(dragOverItem.current, 0, draggedItem);

    setPlaylistAssets(currentAssets);
    setPlaylistUpdate(
      new UpdatePlaylistDto({
        id: selectedPlaylist?.id,
        name: playlistUpdate?.name,
        description: playlistUpdate?.description,
        defaultImageDurationInSecounds:
          playlistUpdate?.defaultImageDurationInSecounds,
        transitionDurationInSecounds:
          playlistUpdate?.transitionDurationInSecounds,
        color: playlistUpdate?.color,
        updatePlaylistAssets: currentAssets.map(
          (x) =>
            new PutAssetPlaylistDto({
              assetPlaylistId: x.assetPlaylistId,
              assetId: x.id,
              durationInSecound: x.playlistDurationInSecounds,
            })
        ),
        canvasIds: playlistUpdate?.canvasIds,
      })
    );
    dragItem.current = undefined;
    dragOverItem.current = undefined;
  };

  const handlNewAssetDrop = () => {
    if (selectedAssetListDto.current === undefined) return;

    if (selectedAssetListDto.current.status === AssetStatus.Pending) {
      toast.warning(
        `${selectedAssetListDto.current.name} is still being pushed to your connected devices. You can still add this asset to the playlist, but it will not be added until the download process is completed. Check the asset details screen to view the download status for this asset.`
      );
    }

    let newAssetPlaylist = [...playlistAssets];
    newAssetPlaylist.splice(
      dragOverItem.current ?? newAssetPlaylist.length,
      0,
      convertAssetListDtoToPlaylistAssetListDto(selectedAssetListDto.current)
    );

    let duration =
      playlistUpdate?.defaultImageDurationInSecounds === 0
        ? 30
        : playlistUpdate?.defaultImageDurationInSecounds;

    setPlaylistAssets(newAssetPlaylist);
    setPlaylistUpdate(
      new UpdatePlaylistDto({
        id: selectedPlaylist?.id,
        name: playlistUpdate?.name,
        description: playlistUpdate?.description,
        defaultImageDurationInSecounds: duration,
        transitionDurationInSecounds:
          playlistUpdate?.transitionDurationInSecounds,
        color: playlistUpdate?.color,
        updatePlaylistAssets: newAssetPlaylist.map(
          (x) =>
            new PutAssetPlaylistDto({
              assetId: x.id,
              durationInSecound: x.playlistDurationInSecounds,
            })
        ),
        canvasIds: playlistUpdate?.canvasIds,
      })
    );
    selectedAssetListDto.current = undefined;
  };

  const handleAssetDurationChange = (
    index: number,
    durationInSecounds: number
  ) => {
    let currentAssets = [...playlistAssets];
    const draggedItem = currentAssets.splice(index, 1)[0];
    draggedItem.playlistDurationInSecounds = durationInSecounds;
    currentAssets.splice(index, 0, draggedItem);
    setPlaylistAssets(currentAssets);

    setPlaylistUpdate(
      new UpdatePlaylistDto({
        id: selectedPlaylist?.id,
        name: playlistUpdate?.name,
        description: playlistUpdate?.description,
        defaultImageDurationInSecounds:
          playlistUpdate?.defaultImageDurationInSecounds,
        transitionDurationInSecounds:
          playlistUpdate?.transitionDurationInSecounds,
        color: playlistUpdate?.color,
        updatePlaylistAssets: currentAssets.map(
          (x) =>
            new PutAssetPlaylistDto({
              assetId: x.id,
              durationInSecound: x.playlistDurationInSecounds,
            })
        ),
        canvasIds: playlistUpdate?.canvasIds,
      })
    );
  };

  const onAssetUploadSuccess = () => {
    setAssetUploadIsShowing(null);
  };

  const handleAddAsseToPlaylist = (asset: AssetListDto) => {
    const newAssetPlaylist = [
      ...playlistAssets,
      convertAssetListDtoToPlaylistAssetListDto(asset),
    ];

    let duration =
      playlistUpdate?.defaultImageDurationInSecounds === 0
        ? 30
        : playlistUpdate?.defaultImageDurationInSecounds;

    setPlaylistAssets(newAssetPlaylist);
    setPlaylistUpdate(
      new UpdatePlaylistDto({
        id: selectedPlaylist?.id,
        name: playlistUpdate?.name,
        description: playlistUpdate?.description,
        defaultImageDurationInSecounds: duration,
        transitionDurationInSecounds:
          playlistUpdate?.transitionDurationInSecounds,
        color: playlistUpdate?.color,
        updatePlaylistAssets: newAssetPlaylist.map(
          (x) =>
            new PutAssetPlaylistDto({
              assetId: x.id,
              durationInSecound: x.playlistDurationInSecounds,
            })
        ),
        canvasIds: playlistUpdate?.canvasIds,
      })
    );
  };

  const handleSearchChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const convertAssetListDtoToPlaylistAssetListDto = (asset: AssetListDto) => {
    let duration =
      asset.type === AssetType.Image
        ? playlistUpdate?.defaultImageDurationInSecounds
        : asset.durationInSecounds;

    return new AssetPlaylistListDto({
      id: asset.id,
      createdAt: asset.createdAt,
      name: asset.name,
      thumbnailImage: asset.thumbnailImage,
      type: asset.type,
      width: asset.width,
      height: asset.height,
      aspectRatio: asset.aspectRatio,
      resolution: asset.resolution,
      fileExtension: asset.fileExtension,
      assetDurationInSecound: asset.durationInSecounds,
      playlistDurationInSecounds: duration === 0 ? 30 : duration,
    });
  };

  const handleColorChange = (selectedColor: Color) => {
    setColor(selectedColor as keyof typeof Color);

    setPlaylistUpdate(
      new UpdatePlaylistDto({
        id: selectedPlaylist?.id,
        name: playlistUpdate?.name,
        description: playlistUpdate?.description,
        defaultImageDurationInSecounds:
          playlistUpdate?.defaultImageDurationInSecounds,
        transitionDurationInSecounds:
          playlistUpdate?.transitionDurationInSecounds,
        updatePlaylistAssets: playlistUpdate?.updatePlaylistAssets ?? [],
        color: selectedColor,
        canvasIds: playlistUpdate?.canvasIds,
      })
    );
  };

  const handleTransitionDurationChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseInt(e.target.value);
    setPlaylistUpdate(
      new UpdatePlaylistDto({
        id: selectedPlaylist?.id,
        name: playlistUpdate?.name,
        description: playlistUpdate?.description,
        defaultImageDurationInSecounds:
          playlistUpdate?.defaultImageDurationInSecounds,
        updatePlaylistAssets: playlistUpdate?.updatePlaylistAssets ?? [],
        color: playlistUpdate?.color,
        transitionDurationInSecounds: newValue,
        canvasIds: playlistUpdate?.canvasIds,
      })
    );
  };

  const handleCanvasSelection = (value: string | undefined) => {
    if (value === undefined) return;

    let isChecked = playlistsCanvas.current.includes(value);

    if (isChecked) {
      playlistsCanvas.current = [
        ...playlistsCanvas.current.filter((x) => x !== value),
      ];
    } else {
      playlistsCanvas.current = [...playlistsCanvas.current, value];
    }

    setPlaylistUpdate(
      new UpdatePlaylistDto({
        id: selectedPlaylist?.id,
        name: playlistUpdate?.name,
        description: playlistUpdate?.description,
        defaultImageDurationInSecounds:
          playlistUpdate?.defaultImageDurationInSecounds,
        updatePlaylistAssets: playlistUpdate?.updatePlaylistAssets ?? [],
        color: playlistUpdate?.color,
        transitionDurationInSecounds:
          playlistUpdate?.transitionDurationInSecounds,
        canvasIds: playlistsCanvas.current,
      })
    );
  };

  const handleOnCanvasBlur = () => {
    canvasTimeOutId = setTimeout(() => {
      setShowCanvasOption(false);
    }, 100);
  };

  const handleOnCanvasFocus = () => {
    clearTimeout(canvasTimeOutId);
  };

  const handleDefaultImageDurationChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseInt(e.target.value);
    setPlaylistUpdate(
      new UpdatePlaylistDto({
        id: selectedPlaylist?.id,
        name: playlistUpdate?.name,
        description: playlistUpdate?.description,
        updatePlaylistAssets: playlistUpdate?.updatePlaylistAssets ?? [],
        transitionDurationInSecounds:
          playlistUpdate?.transitionDurationInSecounds,
        color: playlistUpdate?.color,
        defaultImageDurationInSecounds: newValue,
        canvasIds: playlistUpdate?.canvasIds,
      })
    );
  };

  const handlePlaylistAssetRemoval = (index: number) => {
    let currentAssetPlaylist = [
      ...playlistAssets.filter((a, i) => i !== index),
    ];
    setPlaylistAssets(currentAssetPlaylist);
    setPlaylistUpdate(
      new UpdatePlaylistDto({
        id: selectedPlaylist?.id,
        name: playlistUpdate?.name,
        description: playlistUpdate?.description,
        defaultImageDurationInSecounds:
          playlistUpdate?.defaultImageDurationInSecounds,
        transitionDurationInSecounds:
          playlistUpdate?.transitionDurationInSecounds,
        color: playlistUpdate?.color,
        updatePlaylistAssets: currentAssetPlaylist.map(
          (x) =>
            new PutAssetPlaylistDto({
              assetId: x.id,
              durationInSecound: x.playlistDurationInSecounds,
            })
        ),
        canvasIds: playlistUpdate?.canvasIds,
      })
    );
  };

  if (playlist === null) return <></>;
  else
    return (
      <div className="h-full overflow-hidden p-5 flex flex-col">
        <div className=" flex justify-between border-b pb-5 border-page-border">
          {isMinimalForm ? (
            <>
              <div className="flex gap-3 items-center ">
                <NameLogo
                  className="text-Palette-dark-1 h-10 w-10 rounded text-lg"
                  color={Color[color]}
                  name={playlist?.name ?? ""}
                  letterCount={2}
                />
                <div className="text-gray-50 text-xl justify-start">
                  <input
                    name="name"
                    defaultValue={playlist?.name}
                    onChange={(e) =>
                      setPlaylistUpdate(
                        new UpdatePlaylistDto({
                          id: playlistUpdate?.id,
                          updatePlaylistAssets:
                            playlistUpdate?.updatePlaylistAssets,
                          description: playlistUpdate?.description,
                          defaultImageDurationInSecounds:
                            playlistUpdate?.defaultImageDurationInSecounds,
                          transitionDurationInSecounds:
                            playlistUpdate?.transitionDurationInSecounds,
                          color: playlistUpdate?.color,
                          name: e.target.value,
                          canvasIds: playlistUpdate?.canvasIds,
                        })
                      )
                    }
                    className="w-full bg-transparent focus:bg-white focus:bg-opacity-5 py-2 px-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500"
                    type="text"
                  />
                </div>
                <div className="flex items-center gap-2 text-gray-100">
                  <span className="flex items-center gap-2">
                    {playlistUpdate?.updatePlaylistAssets?.length + " assets"}
                  </span>
                  <small className="w-1 h-1 bg-white rounded-full "></small>
                  <TimeDuration
                    nameId={"timeduration_detail" + playlist?.id}
                    totalSecounds={
                      playlistUpdate?.updatePlaylistAssets?.length !== 0
                        ? playlistUpdate?.updatePlaylistAssets
                            ?.map((x) => x.durationInSecound)
                            ?.reduce((previousValue, currentValue) => {
                              return (previousValue ?? 0) + (currentValue ?? 0);
                            })
                        : 0
                    }
                    showLongformat
                  />
                </div>
              </div>
              <div className="flex flex-col justify-between">
                <div className="flex justify-end">
                  <UilAngleDown
                    className="flex justify-end cursor-pointer"
                    onClick={() => setIsMinimalForm(!isMinimalForm)}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex gap-3 items-center  w-1/2">
                <div>
                  <NameLogo
                    className="text-Palette-dark-1 h-48 w-48 rounded-xl text-6xl"
                    color={Color[color]}
                    name={playlist?.name ?? ""}
                    letterCount={2}
                  />
                </div>
                <div className="w-full">
                  <div className="text-gray-50 text-xl justify-start w-full ">
                    <input
                      name="name"
                      defaultValue={playlist?.name}
                      onChange={(e) =>
                        setPlaylistUpdate(
                          new UpdatePlaylistDto({
                            id: playlistUpdate?.id,
                            updatePlaylistAssets:
                              playlistUpdate?.updatePlaylistAssets ?? [],
                            description: playlistUpdate?.description,
                            defaultImageDurationInSecounds:
                              playlistUpdate?.defaultImageDurationInSecounds,
                            transitionDurationInSecounds:
                              playlistUpdate?.transitionDurationInSecounds,
                            color: playlistUpdate?.color,
                            name: e.target.value,
                            canvasIds: playlistUpdate?.canvasIds,
                          })
                        )
                      }
                      className="w-full bg-transparent focus:bg-white focus:bg-opacity-5 py-2 px-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500 
                      scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent overflow-hidden"
                      type="text"
                    />
                  </div>
                  <div className="text-gray-100 text-body_14  w-full ">
                    <textarea
                      name="name"
                      defaultValue={playlist?.description}
                      onChange={(e) =>
                        setPlaylistUpdate(
                          new UpdatePlaylistDto({
                            id: playlistUpdate?.id,
                            name: playlistUpdate?.name,
                            updatePlaylistAssets:
                              playlistUpdate?.updatePlaylistAssets ?? [],
                            defaultImageDurationInSecounds:
                              playlistUpdate?.defaultImageDurationInSecounds,
                            transitionDurationInSecounds:
                              playlistUpdate?.transitionDurationInSecounds,
                            color: playlistUpdate?.color,
                            description: e.target.value,
                            canvasIds: playlistUpdate?.canvasIds,
                          })
                        )
                      }
                      className="w-full bg-transparent focus:bg-white focus:bg-opacity-5 py-2 px-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500 
                      scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent overflow-hidden"
                    />
                  </div>
                  <div className="flex items-center gap-2 text-gray-100">
                    <span className="flex items-center gap-2">
                      {playlistUpdate?.updatePlaylistAssets?.length + " assets"}
                    </span>
                    <small className="w-1 h-1 bg-white rounded-full "></small>
                    <TimeDuration
                      nameId={"timeduration_absolute" + playlist?.id}
                      totalSecounds={
                        playlistUpdate?.updatePlaylistAssets?.length !== 0
                          ? playlistUpdate?.updatePlaylistAssets
                              ?.map((x) => x.durationInSecound)
                              ?.reduce((previousValue, currentValue) => {
                                return (
                                  (previousValue ?? 0) + (currentValue ?? 0)
                                );
                              })
                          : 0
                      }
                      showLongformat
                    />
                  </div>
                  <ColorInput
                    currentColor={Color[color]}
                    onSelected={handleColorChange}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-between">
                <div className="flex justify-end">
                  <UilAngleUp
                    className="cursor-pointer"
                    onClick={() => setIsMinimalForm(!isMinimalForm)}
                  />
                </div>
                <div className="flex gap-2 flex-col justify-end">
                  <div className="flex items-center justify-end gap-2 text-gray-100 text-body_14">
                    <span>Transition duration: </span>
                    <div className="h-11 rounded-md border border-page-border flex items-center bg-input-bg">
                      <input
                        onChange={handleTransitionDurationChange}
                        defaultValue={playlist?.transitionDurationInSecounds}
                        className="h-full w-16 px-3 bg-transparent  border-r border-page-border border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
                        type="number"
                        min="0"
                        max="7"
                      />
                      <span className="w-auto h-5 mx-3 text-gray-200">sec</span>
                    </div>
                  </div>
                  <div className="flex justify-end items-center gap-2 text-gray-100 text-body_14">
                    <span>Default image duration: </span>
                    <div className="h-11 rounded-md border border-page-border flex items-center bg-input-bg">
                      <input
                        onChange={handleDefaultImageDurationChange}
                        defaultValue={
                          playlistUpdate?.defaultImageDurationInSecounds === 0
                            ? 30
                            : playlistUpdate?.defaultImageDurationInSecounds
                        }
                        className="h-full w-16 px-3  bg-transparent  border-r border-page-border border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
                        type="number"
                        min="0"
                      />
                      <span className="w-auto h-5 mx-3 text-gray-200">sec</span>
                    </div>
                  </div>
                  <div className="flex items-center justify-end gap-2 text-gray-100 text-body_14">
                    <span>Canvases: </span>
                    <div className="relative">
                      <button
                        onClick={() => {
                          let element =
                            document.getElementById("canvas_dropdown");
                          element?.focus();
                          setShowCanvasOption(!showCanvasOption);
                        }}
                        className="h-11 w-min-44 rounded-md border border-page-border flex items-center bg-white bg-opacity-5 overflow-hidden pr-6"
                      >
                        <UilPlus className="w-5 h-5 mx-4 text-gray-200" />
                        Selected canvases
                      </button>
                      {showCanvasOption && (
                        <div
                          className="absolute w-44 top-18 left-0 p-3 z-10 rounded-md border border-page-border  text-sm bg-dropdown min-w-max"
                          tabIndex={1}
                          onBlur={handleOnCanvasBlur}
                          onFocus={handleOnCanvasFocus}
                        >
                          <ul className=" max-h-64 overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
                            {canvases.map((x) => (
                              <li key={x.id}>
                                <label className="flex items-center gap-2 px-1 py-1">
                                  <input
                                    className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4"
                                    type="checkbox"
                                    checked={playlistsCanvas.current.includes(
                                      x?.id ?? ""
                                    )}
                                    onChange={() =>
                                      handleCanvasSelection(x?.id)
                                    }
                                  />
                                  <span>
                                    {"[" +
                                      x.siteId +
                                      "," +
                                      x.multiplexNumber +
                                      "] " +
                                      x.name}
                                  </span>
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className=" flex justify-between h-full w-full overflow-hidden  pt-8">
          <div className="w-1/4 flex flex-col">
            <div className="flex items-center justify-between gap-2">
              <div className="h-11 w-full rounded-md border border-page-border flex items-center justify-between bg-white bg-opacity-5">
                <div className="flex items-center">
                  <UilSearch className="w-5 h-5 mx-4" />
                  <input
                    onChange={handleSearchChanged}
                    className="h-full bg-transparent border-0"
                    type="text"
                    placeholder="Search for asset"
                    style={{ width: "calc(100% - 48px)" }}
                  />
                </div>
                <UilSlidersVAlt
                  onClick={() => setFilterIsShown(!filterIsShown)}
                  className="border m-1 rounded border-page-border bg-white bg-opacity-5"
                />
              </div>
            </div>
            <div className="w-full overflow-y-auto  scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
              <div className="py-4">
                <ul className="w-full text-sm">
                  {searchedAsset.map((x) => (
                    <PlaylistAssetSelectionItem
                      key={x.id}
                      asset={x}
                      className="truncate"
                      onDragStart={() => {
                        dragOverItem.current = undefined;
                        selectedAssetListDto.current = x;
                      }}
                      onDragEnd={(e) => e?.preventDefault()}
                      onAssetAddClick={handleAddAsseToPlaylist}
                      defaultImageDurationInSecounds={
                        playlistUpdate?.defaultImageDurationInSecounds
                      }
                    />
                  ))}
                </ul>
                {currentPageNumber !== totalPageCount && (
                  <div
                    className="text-button-bg-primary-default p-2 cursor-pointer text-center"
                    onClick={() => setCurrentPageNumber(currentPageNumber + 1)}
                  >
                    load more
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="ml-7 w-3/4 p-4 overflow-y-auto  scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent"
            onDrop={() => {
              handlNewAssetDrop();
            }}
            onDragEnter={(e) => {
              e?.preventDefault();
            }}
            onDragOver={(e) => {
              e?.preventDefault();
            }}
          >
            <div className="table w-full">
              <table className="w-full text-sm">
                <thead className="text-gray-300 text-body_14 px-4 py-3 border-b border-page-border">
                  <tr>
                    <td></td>
                    <td>Assets</td>
                    <td>Play time</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {playlistAssets !== undefined &&
                    playlistAssets.map((x, i) => (
                      <PlaylistAssetListItem
                        key={x.order?.toString()}
                        asset={x}
                        isEditable
                        onDragStart={() => (dragItem.current = i)}
                        onDragEnter={() => (dragOverItem.current = i)}
                        onDragEnd={() => handlDragReorder()}
                        onDragOver={(e) => e?.preventDefault()}
                        onDurationChange={(newDuration) =>
                          handleAssetDurationChange(i, newDuration)
                        }
                        onAssetRemove={() => handlePlaylistAssetRemoval(i)}
                        loggedInUser={loggedInUser}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <AssetFilter />
        <AssetUploadModal onSuccess={onAssetUploadSuccess} />
      </div>
    );
}
