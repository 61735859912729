import { UilAngleDown, UilAngleUp } from "@iconscout/react-unicons";
import { useState } from "react";
import { CanvasBasicDto, CanvasListDto } from "../../services";

type Props = {
  value?: string;
  canvases: CanvasBasicDto[];
  onChange: (updated: string | undefined) => void;
};

export function PreviewCanvasSelection({ value, canvases, onChange }: Props) {
  const [showOption, setShowOption] = useState<boolean>(false);
  const [selectedCanvase, setSelectedCanvase] = useState<
    CanvasListDto | undefined
  >();

  let timeOutId: NodeJS.Timeout;

  const handleSelection = (value: CanvasListDto | undefined) => {
    selectedCanvase?.id !== value?.id
      ? setSelectedCanvase(value)
      : setSelectedCanvase(undefined);
    onChange(value?.id);
  };

  const handleShowOptions = () => {
    setShowOption(!showOption);
  };

  const handleOnBlur = () => {
    timeOutId = setTimeout(() => {
      setShowOption(false);
    }, 100);
  };

  const handleOnFocus = () => {
    clearTimeout(timeOutId);
  };

  return (
    <div
      className="relative mr-3 z-10 text-sm w-full"
      tabIndex={1}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
    >
      <button
        onClick={handleShowOptions}
        className="h-11 gap-2 px-4 rounded-md w-full border border-page-border flex justify-between items-center bg-white bg-opacity-5 overflow-hidden"
      >
        {selectedCanvase === undefined ? (
          <span className="opacity-50">Choose canvas</span>
        ) : (
          <span>{selectedCanvase.name}</span>
        )}
        {showOption ? <UilAngleUp className="" /> : <UilAngleDown />}
      </button>
      {showOption && (
        <div className="absolute top-12 right-0 rounded-md border border-page-border bg-dropdown min-w-max w-full">
          <ul className=" max-h-64 overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
            {canvases.map((x) => (
              <li
                key={x.id}
                onClick={() => handleSelection(x)}
                className="cursor-pointer hover:bg-button-bg-primary-hover px-2.5"
              >
                <span className="block py-2">
                  {"[" + x.siteId + "," + x.multiplexNumber + "] " + x.name}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
