import { AssetListDto, AssetStatus } from "../../services";
import { useState } from "react";
import { baseURL } from "../../constants/ApiConstans";
import { Image, Video } from "react-feather";
import { TimeDuration } from "../Misc";
import { Dragable } from "../Svgs";
import { toast } from "react-toastify";

type Props = {
  asset: AssetListDto;
  draggable?: boolean;
  className?: string;
  defaultImageDurationInSecounds?: number;
};

export function ScheduleAssetItem({
  className,
  asset,
  defaultImageDurationInSecounds,
}: Props) {
  const [thumbnailLink] = useState(
    baseURL +
      "/api/customer/" +
      asset.customerId +
      "/assets/" +
      asset.id +
      "/thumbnail/" +
      asset.thumbnailImage
  );
  // const [isDragging, setIsDragging] = useState<boolean>();

  const handleDragStart = (event: React.DragEvent<HTMLTableRowElement>) => {
    event.dataTransfer.effectAllowed = "move";

    const itemType = "box";
    const item = {
      id: new Date(),
      name: asset?.name,
      type: itemType,
      content: "Placeholder",
      resourceId: asset?.id,
      canvasIds: asset?.canvasIds,
      status: asset?.status,
      resourceType: "Asset",
      durationInSecounds:
        asset?.durationInSecounds === 0 ? 30 : asset?.durationInSecounds,
    };
    event.dataTransfer.setData("text", JSON.stringify(item));
  };

  return (
    <tr
      key={asset.id}
      draggable={true}
      onDragStart={handleDragStart}
      // onDrag={() => setIsDragging(true)}
      // onDragEnd={() => {
      //
      //   setIsDragging(false);
      // }}
    >
      {/* {isDragging ? (
        <td className="px-4 py-2 border-b border-page-border group-d">
          <div className="h-12"></div>
        </td>
      ) : ( */}
      <td className="px-4 py-2 border-b border-page-border group-d">
        <div className="flex gap-4 items-center">
          <div className="flex flex-row items-center gap-2 w-24">
            <Dragable className="text-gray-50" />
            <img
              className="h-12 w-12 object-cover rounded"
              src={` ${thumbnailLink} `}
              alt="logo"
            />
          </div>
          <div className="w-full group">
            <div
              className="text-gray-50 text-body_16 truncate"
              style={{ width: "calc(30vh - 40px)" }}
            >
              {asset.name}
            </div>
            <div className="flex justify-between">
              <div className="inline-flex py-1 items-center gap-2 text text-gray-300">
                <span>
                  {asset.type === "Image" ? (
                    <Image className="w-4 h-4 p-0 m-0" />
                  ) : (
                    <Video className="w-4 h-4 p-0 m-0" />
                  )}
                </span>
                <span>{asset.fileExtension}</span>
                <small className="w-1 h-1 bg-white rounded-full "></small>
                <TimeDuration
                  nameId={"timeduration" + asset.id}
                  totalSecounds={
                    asset?.durationInSecounds === 0
                      ? defaultImageDurationInSecounds
                      : asset?.durationInSecounds
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </td>
      {/* )} */}
    </tr>
  );
}
