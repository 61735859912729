import { useEffect } from "react";
import { Color } from "../../constants";

type Props = {
  color: Color;
  name: string;
  className: string;
  letterCount: 1 | 2;
  onClick?: () => void;
};

export function NameLogo({
  color,
  name,
  className,
  letterCount,
  onClick,
}: Props) {
  useEffect(() => { }, [name]);

  const getLetters = () => {
    const uppserCaseName = name.replace(/[a-z]/g, "").replace(" ", "");
    const logoName = uppserCaseName.substring(0, letterCount);
    return logoName.length > 0 ? logoName : name.substring(0, letterCount);
  };

  return (
    <div
      onClick={() => onClick && onClick()}
      className={`${className} flex items-center
        ${color === Color.Red || color?.toString() === Color.Red.toString() ? " bg-Palette-1" : ""}
        ${color === Color.Orange || color?.toString() === Color.Orange.toString() ? " bg-Palette-2" : ""}
        ${color === Color.Lime || color?.toString() === Color.Lime.toString() ? " bg-Palette-3" : ""}
        ${color === Color.Green || color?.toString() === Color.Green.toString() ? " bg-Palette-4" : ""}
        ${color === Color.Teal || color?.toString() === Color.Teal.toString() ? " bg-Palette-5" : ""}
        ${color === Color.Blue || color?.toString() === Color.Blue.toString() ? " bg-Palette-6" : ""} 
        ${color === Color.Gray || color?.toString() === Color.Gray.toString() ? " bg-gray-600" : ""} 
        justify-center`}
    >
      <span>{getLetters()}</span>
    </div>
  );
}
