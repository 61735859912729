import { useEffect, useRef, useState } from "react";
import {
  AssetDetailDto,
  AssetPreviewRequest,
  AssetPreviewStopRequest,
  AssetResourceDataDto,
  AssetsClient,
  AssetType,
  CanvasBasicDto,
  CanvasesClient,
  PlaylistBasicDto,
  PlaylistsClient,
} from "../../services";
import { GetNewInstance, Store } from "../../helpers";
import { useRecoilState } from "recoil";
import { AssetDetailState } from "../../atoms/AssetDetailState";
import ReactPlayer from "react-player";
import { SelectedAssetState } from "../../atoms/SelectedAssetState";
import { Chip, NameLogo, Spinner } from "../Misc";
import { AssetUpdateState } from "../../atoms/AssetUpdateState";
import { PreviewCanvasSelection } from "../actionButtons";
import {
  UilArrowLeft,
  UilArrowRight,
  UilEye,
  UilEyeSlash,
  UilPlus,
} from "@iconscout/react-unicons";
import { useNavigate } from "react-router-dom";
import { Color, baseURL } from "../../constants";
import { AssetAuditLogModal } from "../modal";
import { RequireEditorRoleMinimum, RoleCheck } from "../auth";

export function AssetDetail() {
  const [selectedAsset, setSelectedAsset] = useState<AssetDetailDto | null>(
    null
  );
  const [assetDetailState, setAssetDetailState] =
    useRecoilState(AssetDetailState);
  const [, setSelectedAssetId] = useRecoilState(SelectedAssetState);

  const [selectedIndex, setIndex] = useState<number | undefined>(
    assetDetailState && assetDetailState.assetId
      ? assetDetailState.assetIds.indexOf(assetDetailState.assetId)
      : undefined
  );

  const [, setAssetUpdate] = useRecoilState(AssetUpdateState);
  const [showPrevious, setShowPrevious] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const [fallbackToThumbnail, setFallbackToThumbnail] = useState(false);
  const [showPlaylistOption, setShowPlaylistOption] = useState(false);
  const [showCanvasOption, setShowCanvasOption] = useState(false);
  const [previewIsOn, setPreviewIsOn] = useState(false);
  const [playlists, setPlaylists] = useState<PlaylistBasicDto[]>([]);
  const [canvases, setCanvases] = useState<CanvasBasicDto[]>([]);
  const [selectedCanvas, setSelectedCanvas] = useState<string | undefined>(
    undefined
  );
  const [assetUploadStatus, setAssetUploadStatus] = useState<string>();
  const [assetLink, setAssetLink] = useState<AssetResourceDataDto | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const usersCustomerId = Store.getCustomerId();
  const navigate = useNavigate();
  const playlistClient = new PlaylistsClient(undefined, GetNewInstance());
  const canvasClient = new CanvasesClient(undefined, GetNewInstance());
  const assetClient = new AssetsClient(undefined, GetNewInstance());
  const name = useRef<string>("");
  const tags = useRef<string[]>([]);
  const assetsPlaylist = useRef<string[]>([]);
  const assetsCanvas = useRef<string[]>([]);
  const initialAssetsCanvas = useRef<string[]>([]);
  let canvasTimeOutId: NodeJS.Timeout;
  let playlistTimeOutId: NodeJS.Timeout;
  const loggedInUser = Store.getLoggedInUser();

  const unsupportedVideoCodecs: string[] = ["MPEG2VIDEO", "NOTCHLC", "HAP"];

  useEffect(() => {
    setIsLoading(true);
    assetDetailState === null && navigate("/asset");
    usersCustomerId &&
      assetDetailState?.assetId &&
      assetClient
        .getAssetDetail(usersCustomerId, assetDetailState?.assetId)
        .then((x) => {
          x?.data && setSelectedAsset(x?.data);
          x?.data?.id && setSelectedAssetId(x?.data?.id);
          setAssetUpdate({
            id: x?.data?.id ?? "",
            name: x?.data?.name ?? "",
            tags: x?.data?.tags ?? [],
            playlistIds: [],
            canvasIds: [],
          });
          name.current = x?.data?.name ?? "";
          tags.current = x?.data?.tags ?? [];
          setAssetDetailState({
            ...assetDetailState,
            assetId: x?.data?.id,
            assetName: x?.data?.name ?? "",
          });

          loadPlaylist(x?.data?.id);
          loadCanvases(x?.data?.id);
          getAssetResourceLink(x?.data);

          assetClient
            .getAssetUploadStatusDetail(usersCustomerId, x?.data?.id ?? "")
            .then((uploadStatusResponse) => {
              setAssetUploadStatus(
                uploadStatusResponse?.data
                  ?.filter((uploadStatus) => uploadStatus.connectedDeviceName)
                  ?.map(
                    (uploadStatus) =>
                      `${uploadStatus.connectedDeviceName} : ${uploadStatus.assetStatus}`
                  )
                  .join("\n")
              );
            });
        });

    setShowPrevious(
      selectedIndex !== undefined &&
        selectedIndex > 0 &&
        assetDetailState?.assetIds.length !== 1 &&
        assetDetailState?.assetIds.length !== 0
    );
    setShowNext(
      selectedIndex !== undefined &&
        assetDetailState?.assetIds.length !== selectedIndex + 1
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  const getAssetResourceLink = (asset: AssetDetailDto | undefined) => {
    usersCustomerId &&
      asset?.id &&
      assetClient
        .getAssetResourceDataUrl(usersCustomerId, asset.id)
        .then((x) => {
          if (x && !x.resourceFallbackUrl) {
            x.resourceFallbackUrl = `${baseURL}/api/customer/${usersCustomerId}/assets/${asset.id}/thumbnail/${asset.thumbnailImage}`;
          }
          setAssetLink(x);
          setFallbackToThumbnail(
            asset.type !== AssetType.Image &&
              unsupportedVideoCodecs.includes(asset?.codec?.toUpperCase() ?? "")
          );
        })
        .finally(() => setIsLoading(false));
  };

  const loadPlaylist = (assetId: string | undefined) => {
    usersCustomerId &&
      playlistClient.getAllPlaylists(usersCustomerId).then((x) => {
        x?.data && setPlaylists(x?.data);
        loadAssetsPlaylist(assetId);
      });
  };

  const loadCanvases = (assetId: string | undefined) => {
    usersCustomerId &&
      canvasClient.getAllCanvases(usersCustomerId).then((x) => {
        x?.data && setCanvases(x?.data);
        loadAssetsCanvas(assetId);
      });
  };

  const loadAssetsPlaylist = (assetId: string | undefined) => {
    usersCustomerId &&
      assetId &&
      playlistClient.getAssetsPlaylists(usersCustomerId, assetId).then((x) => {
        x?.data &&
          setAssetUpdate({
            id: assetId ?? "",
            name: name.current,
            tags: tags.current,
            playlistIds: x?.data.map((p) => p.id ?? ""),
            canvasIds: assetsCanvas.current,
          });
        assetsPlaylist.current = x?.data?.map((p) => p.id ?? "") ?? [];
      });
  };

  const loadAssetsCanvas = (assetId: string | undefined) => {
    usersCustomerId &&
      assetId &&
      canvasClient.getAssetsCanvases(usersCustomerId, assetId).then((x) => {
        x?.data &&
          setAssetUpdate({
            id: assetId ?? "",
            name: name.current,
            tags: tags.current,
            playlistIds: assetsPlaylist.current,
            canvasIds: x?.data.map((p) => p.id ?? ""),
          });
        assetsCanvas.current = x?.data?.map((p) => p.id ?? "") ?? [];
        initialAssetsCanvas.current = assetsCanvas.current;
      });
  };

  const getHumanReadableFileSize = () => {
    const thresh = 1024;
    let currentFileSize = selectedAsset?.fileSize ?? 0;

    if (Math.abs(currentFileSize) < thresh) {
      return currentFileSize + " B";
    }

    const units = currentFileSize
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** 2;

    do {
      currentFileSize /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(currentFileSize) * r) / r >= thresh &&
      u < units.length - 1
    );

    return currentFileSize.toFixed(2) + " " + units[u];
  };

  const hanldeNextAsset = () => {
    selectedIndex !== undefined && setIndex(selectedIndex + 1);
    selectedIndex !== undefined &&
      assetDetailState &&
      setAssetDetailState({
        ...assetDetailState,
        assetId: assetDetailState.assetIds[selectedIndex + 1],
        assetName: "",
      });
  };

  const hanldePreviousAsset = () => {
    selectedIndex && setIndex(selectedIndex - 1);
    selectedIndex &&
      assetDetailState &&
      setAssetDetailState({
        ...assetDetailState,
        assetId: assetDetailState.assetIds[selectedIndex - 1],
        assetName: "",
      });
  };

  const handleAddTag = (e: any) => {
    if (e.key === "Enter") {
      const previousTagIsFound = tags.current.find(
        (x) => x.toLowerCase() === e.target.value.toLowerCase()
      );

      if (previousTagIsFound) {
        return;
      }

      tags.current = [...tags.current, e.target.value];
      e.target.value = "";

      setAssetUpdate({
        id: assetDetailState?.assetId ?? "",
        name: name.current,
        playlistIds: assetsPlaylist.current,
        canvasIds: assetsCanvas.current,
        tags: tags.current,
      });
    }
  };

  const handleRemoveTag = (value: string) => {
    tags.current = [
      ...tags.current.filter((x) => x.toLowerCase() !== value.toLowerCase()),
    ];
    setAssetUpdate({
      id: assetDetailState?.assetId ?? "",
      name: name.current,
      playlistIds: assetsPlaylist.current,
      canvasIds: assetsCanvas.current,
      tags: tags.current,
    });
  };

  const handleNameChange = (value: string) => {
    setAssetUpdate({
      id: assetDetailState?.assetId ?? "",
      playlistIds: assetsPlaylist.current,
      canvasIds: assetsCanvas.current,
      tags: tags.current,
      name: value,
    });
    name.current = value;
  };

  const handlePlaylistSelection = (value: string | undefined) => {
    if (value === undefined) return;

    let isChecked = assetsPlaylist.current.includes(value);

    if (isChecked) {
      assetsPlaylist.current = [
        ...assetsPlaylist.current.filter((x) => x !== value),
      ];
    } else {
      assetsPlaylist.current = [...assetsPlaylist.current, value];
    }
    setAssetUpdate({
      id: assetDetailState?.assetId ?? "",
      name: name.current,
      tags: tags.current,
      playlistIds: assetsPlaylist.current,
      canvasIds: assetsCanvas.current,
    });
  };

  const handleCanvasSelection = (value: string | undefined) => {
    if (value === undefined) return;

    let isChecked = assetsCanvas.current.includes(value);

    if (isChecked) {
      assetsCanvas.current = [
        ...assetsCanvas.current.filter((x) => x !== value),
      ];
    } else {
      assetsCanvas.current = [...assetsCanvas.current, value];
    }
    setAssetUpdate({
      id: assetDetailState?.assetId ?? "",
      name: name.current,
      tags: tags.current,
      playlistIds: assetsPlaylist.current,
      canvasIds: assetsCanvas.current,
    });
  };

  const handleOnPlaylistBlur = () => {
    playlistTimeOutId = setTimeout(() => {
      setShowPlaylistOption(false);
    }, 100);
  };

  const handleOnPlaylistFocus = () => {
    clearTimeout(playlistTimeOutId);
  };

  const handleOnCanvasBlur = () => {
    canvasTimeOutId = setTimeout(() => {
      setShowCanvasOption(false);
    }, 100);
  };

  const handleOnCanvasFocus = () => {
    clearTimeout(canvasTimeOutId);
  };

  const handleSelectedCanvasChange = (value: string | undefined) => {
    if (previewIsOn) {
      usersCustomerId &&
        assetDetailState?.assetId &&
        assetClient
          .previewAssetStop(
            usersCustomerId,
            new AssetPreviewStopRequest({
              customerId: usersCustomerId,
              canvasId: selectedCanvas,
            })
          )
          .then((x) => {
            setPreviewIsOn(!previewIsOn);
          });
    }
    setSelectedCanvas(value);
  };

  const handlePreviewClick = () => {
    if (selectedCanvas === undefined) return;

    if (previewIsOn) {
      usersCustomerId &&
        assetDetailState?.assetId &&
        assetClient
          .previewAssetStop(
            usersCustomerId,
            new AssetPreviewStopRequest({
              customerId: usersCustomerId,
              canvasId: selectedCanvas,
              assetId: assetDetailState?.assetId,
            })
          )
          .then((x) => {
            setPreviewIsOn(!previewIsOn);
          });
    } else {
      usersCustomerId &&
        assetDetailState?.assetId &&
        assetClient
          .previewAsset(
            usersCustomerId,
            new AssetPreviewRequest({
              customerId: usersCustomerId,
              assetId: assetDetailState?.assetId,
              assetFileExtension: selectedAsset?.fileExtension,
              canvasId: selectedCanvas,
            })
          )
          .then((x) => {
            setPreviewIsOn(!previewIsOn);
          });
    }
  };

  return (
    <>
      <div className="h-full overflow-auto p-5 scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
        <div className="flex text-sm">
          <div className="w-2/3 pr-6">
            <RequireEditorRoleMinimum user={loggedInUser}>
              <div className="-ml-5 -mt-5 p-5 bg-black">
                <p>Preview on canvas</p>
                <div className="flex py-4 justify-start items-center gap-3">
                  <div className="w-72">
                    <PreviewCanvasSelection
                      canvases={canvases.filter((x) =>
                        initialAssetsCanvas.current.includes(x.id ?? "")
                      )}
                      onChange={handleSelectedCanvasChange}
                    />
                  </div>
                  {previewIsOn ? (
                    <button
                      onClick={handlePreviewClick}
                      className="flex items-center py-2.5 px-4 rounded-md gap-2 
                                    disabled:opacity-40 bg-button-bg-primary-default hover:bg-button-bg-primary-hover active:shadow-button_primary"
                    >
                      <UilEyeSlash className="w-5 h-5 text-gray-100" />
                      Stop preview
                    </button>
                  ) : (
                    <button
                      onClick={handlePreviewClick}
                      disabled={selectedCanvas === undefined}
                      className="flex items-center py-2.5 px-4 rounded-md gap-2 
                                    disabled:opacity-40 bg-button-bg-primary-default hover:bg-button-bg-primary-hover active:shadow-button_primary"
                    >
                      <UilEye className="w-5 h-5 text-gray-100" />
                      Preview asset
                    </button>
                  )}
                </div>
              </div>
            </RequireEditorRoleMinimum>
            <div className="-ml-5 h-full bg-cover bg-center flex items-center justify-center bg-black">
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  {fallbackToThumbnail ||
                  selectedAsset?.type === AssetType.Image ? (
                    <img
                      className="w-full h-full object-contain"
                      src={
                        fallbackToThumbnail
                          ? assetLink?.resourceFallbackUrl
                          : assetLink?.resourceUrl
                      }
                      alt="Asset"
                      onError={(e: any) => {
                        e.target.src =
                          selectedAsset?.type === AssetType.Image
                            ? require("../../assets/Image_Placeholder.png")
                            : require("../../assets/Video_Placeholder.png");
                      }}
                    />
                  ) : (
                    <ReactPlayer
                      style={{ objectFit: "contain" }}
                      controls
                      width="100%"
                      height="100%"
                      url={assetLink?.resourceUrl ?? ""}
                      // light
                      onError={(e) => {
                        setFallbackToThumbnail(true);
                      }}
                      muted
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <div className="w-1/3">
            <p className="text-lg w-full">
              {RoleCheck.isEditorRoleMinimum(loggedInUser) ? (
                <textarea
                  name="name"
                  defaultValue={selectedAsset?.name}
                  onChange={(e) => handleNameChange(e.target.value)}
                  className="w-full bg-transparent focus:bg-white focus:bg-opacity-5 py-2 px-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500"
                />
              ) : (
                <p className="text-lg py-5">{selectedAsset?.name}</p>
              )}
            </p>
            <p className="my-2" title={assetUploadStatus}>
              {selectedAsset?.status}
            </p>
            <p className="my-2 opacity-75">
              {"Uploaded " + selectedAsset?.createdAt?.toLocaleDateString()}
            </p>
            <div className="py-4">
              <span>Tags</span>
              <div
                className={`h-auto flex-wrap rounded-md my-4 border border-page-border`}
              >
                <div
                  className="flex-wrap flex p-1 gap-1 items-center"
                  style={{ minHeight: "44px" }}
                >
                  {tags.current.map((x) => (
                    <Chip
                      key={x}
                      onCloseClicked={
                        RoleCheck.isEditorRoleMinimum(loggedInUser)
                          ? handleRemoveTag
                          : undefined
                      }
                      className="bg-transparent px-3 py-1 border-page-border "
                    >
                      {x}
                    </Chip>
                  ))}
                  <RequireEditorRoleMinimum user={loggedInUser}>
                    <input
                      onKeyPress={handleAddTag}
                      className="h-full bg-transparent border-0 px-2"
                      type="text"
                    />
                  </RequireEditorRoleMinimum>
                </div>
              </div>
            </div>
            <RequireEditorRoleMinimum user={loggedInUser}>
              <div className="pb-4">
                <div className="flex row">
                  <div
                    id="canvas_dropdown"
                    className="col w-1/2 relative"
                    tabIndex={1}
                  >
                    <p>Canvases</p>
                    <p className="my-2 opacity-75 text-xs">
                      Add asset to one or more canvases.
                    </p>
                    <button
                      onClick={() => {
                        let element =
                          document.getElementById("canvas_dropdown");
                        element?.focus();
                        setShowCanvasOption(!showCanvasOption);
                      }}
                      className="h-11 w-min-44 rounded-md border border-page-border flex items-center bg-white bg-opacity-5 overflow-hidden pr-6"
                    >
                      <UilPlus className="w-5 h-5 mx-4 text-gray-200" />
                      Add to canvases
                    </button>
                    {showCanvasOption && (
                      <div
                        className="absolute w-44 top-18 left-0 p-3 z-10 rounded-md border border-page-border  text-sm bg-dropdown min-w-max"
                        tabIndex={1}
                        onBlur={handleOnCanvasBlur}
                        onFocus={handleOnCanvasFocus}
                      >
                        <ul className=" max-h-64 overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
                          {canvases.map((x) => (
                            <li key={x.id}>
                              <label className="flex items-center gap-2 px-1 py-1">
                                <input
                                  className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4"
                                  type="checkbox"
                                  checked={assetsCanvas.current.includes(
                                    x?.id ?? ""
                                  )}
                                  onChange={() => handleCanvasSelection(x?.id)}
                                />
                                <span>
                                  {"[" +
                                    x.siteId +
                                    "," +
                                    x.multiplexNumber +
                                    "] " +
                                    x.name}
                                </span>
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div
                    id="playlist_dropdown"
                    className="col w-1/2 relative"
                    tabIndex={1}
                  >
                    <p>Playlists</p>
                    <p className="my-2 opacity-75 text-xs">
                      Add asset to one or more playlists
                    </p>
                    <button
                      onClick={() => {
                        let element =
                          document.getElementById("playlist_dropdown");
                        element?.focus();
                        setShowPlaylistOption(!showPlaylistOption);
                      }}
                      className="h-11 w-min-44 rounded-md border border-page-border flex items-center bg-white bg-opacity-5 overflow-hidden pr-6"
                    >
                      <UilPlus className="w-5 h-5 mx-4 text-gray-200" />
                      Add to playlists
                    </button>
                    {showPlaylistOption && (
                      <div
                        className="absolute w-44 top-18 left-0 p-3 z-10 rounded-md border border-page-border  text-sm bg-dropdown min-w-max"
                        tabIndex={1}
                        onBlur={handleOnPlaylistBlur}
                        onFocus={handleOnPlaylistFocus}
                      >
                        <ul className=" max-h-64 overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
                          {playlists.map((x) => (
                            <li key={x.id}>
                              <label className="flex items-center gap-2 px-1 py-1">
                                <input
                                  className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4"
                                  type="checkbox"
                                  checked={assetsPlaylist.current.includes(
                                    x?.id ?? ""
                                  )}
                                  onChange={() =>
                                    handlePlaylistSelection(x?.id)
                                  }
                                />
                                <NameLogo
                                  className="text-sm text-Palette-dark-1 w-6 h-6 rounded-full cursor-pointer"
                                  color={Color[x.color as keyof typeof Color]}
                                  name={x.name ?? ""}
                                  letterCount={2}
                                />
                                <span>{x.name}</span>
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </RequireEditorRoleMinimum>
            <div className="py-4">
              <p className="mb-4">Metadata</p>
              <ul className="flex flex-wrap gap-y-6">
                <li className="w-1/2">
                  File size <br />
                  <span className="opacity-60">
                    {getHumanReadableFileSize()}
                  </span>
                </li>
                <li className="w-1/2">
                  File type <br />
                  <span className="opacity-60">
                    {selectedAsset?.fileExtension}
                  </span>
                </li>
                {selectedAsset?.type === "Video" && (
                  <li className="w-1/2">
                    Duration <br />
                    <span className="opacity-60">
                      {selectedAsset?.duration?.split(".")[0]}
                    </span>
                  </li>
                )}
                <li className="w-1/2">
                  Resolution <br />
                  {selectedAsset?.type === "Video" ? (
                    <span className="opacity-60">
                      {selectedAsset?.resolution}p
                    </span>
                  ) : (
                    <span className="opacity-60">
                      {selectedAsset?.width + " x " + selectedAsset?.height}
                    </span>
                  )}
                </li>
                {selectedAsset?.type === "Video" && (
                  <li className="w-1/2">
                    Codec <br />
                    <span className="opacity-60">{selectedAsset?.codec}</span>
                  </li>
                )}
                <li className="w-1/2">
                  Aspect ratio <br />
                  <span className="opacity-60">
                    {selectedAsset?.aspectRatio}
                  </span>
                </li>
                {selectedAsset?.colorSpace && (
                  <li className="w-1/2">
                    Color space <br />
                    <span className="opacity-60">
                      {selectedAsset.colorSpace}
                    </span>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between text-sm p-5 border-t border-page-border text-blue-500">
        <div
          onClick={hanldePreviousAsset}
          className="flex items-center gap-2 cursor-pointer"
        >
          {showPrevious && (
            <>
              <UilArrowLeft />
              Previous asset
            </>
          )}
        </div>
        <div
          onClick={hanldeNextAsset}
          className="flex items-center gap-2 cursor-pointer"
        >
          {showNext && (
            <>
              Next asset
              <UilArrowRight />
            </>
          )}
        </div>
      </div>

      <AssetAuditLogModal />
    </>
  );
}
