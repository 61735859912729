type Props = {
    className?: string
}

export function Dragable({ className }: Props) {

    return (
        <svg className={className} width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="2" height="2" fill="#AFBAC5" />
            <rect y="6" width="2" height="2" fill="#AFBAC5" />
            <rect y="12" width="2" height="2" fill="#AFBAC5" />
            <rect x="6" width="2" height="2" fill="#AFBAC5" />
            <rect x="6" y="6" width="2" height="2" fill="#AFBAC5" />
            <rect x="6" y="12" width="2" height="2" fill="#AFBAC5" />
        </svg>
    );
}