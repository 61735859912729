import { BaseModal } from "./BaseModal";
import { UpdateCustomerForm } from "../form";
import { ModalTypes } from "./ModalTypes";

type Props = {
    onSuccess: () => void;
    onFailure: () => void;
}

export function UpdateCustomerModal({ onSuccess, onFailure }: Props) {

    return (
        <BaseModal id={ModalTypes.UpdateCustomerModal} title='Update customer' description='Update a customer account'>
            <UpdateCustomerForm onSuccess={onSuccess} onFailure={onFailure} />
        </BaseModal>
    );
}