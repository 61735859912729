import { useEffect, useState } from "react";

type Timespan = {
    hour: number,
    minute: number,
    secound: number
}

type Props = {
    nameId: string,
    className?: string,
    totalSecounds: number | undefined,
    showLongformat?: boolean,
    hideSecounds?: boolean
};

export function TimeDuration({ nameId, className, totalSecounds, showLongformat, hideSecounds }: Props) {

    const [duration, setDuration] = useState<Timespan | undefined>(undefined);

    useEffect(() => {
        setDuration(convertSecoundsIntoTimeSpan(totalSecounds));

    }, [totalSecounds])

    const convertSecoundsIntoTimeSpan = (totalSecounds: number | undefined) => {
        totalSecounds = totalSecounds ?? 0;
        const hour = Math.floor(totalSecounds / 3600);
        const minute = Math.floor((totalSecounds - (3600 * hour)) / 60);
        const secound = Math.floor(totalSecounds - (3600 * hour) - (60 * minute));

        const timeSpan: Timespan = {
            hour: hour,
            minute: minute,
            secound: secound
        };
        return timeSpan;
    }

    const padWithZero = (num: number) => {
        const fort = String(num).padStart(2, '0')
        return fort;
    }

    const getLongFormat = () => {

        if (duration === undefined)
            return "";

        let formatedDuration: string = "";
        if (duration.hour > 0) {
            formatedDuration += (duration.hour + "hr ");
        }
        if (duration.minute > 0) {
            formatedDuration += (duration.minute + "min ");
        }
        if (hideSecounds !== true)
            formatedDuration += (duration.secound + "sec ");

        return formatedDuration;
    }

    const getShortFormat = () => {

        if (duration === undefined)
            return "";

        var shortDuration = padWithZero(duration.hour) + ":" + padWithZero(duration.minute);
        shortDuration = hideSecounds === true ? shortDuration : (shortDuration + ":" + padWithZero(duration.secound));
        return shortDuration;
    }

    return (
        <div key={nameId} className={` ${className ?? ""}`}>
            {
                showLongformat ?
                    <span>{getLongFormat()}</span>
                    : <span>{getShortFormat()}</span>
            }
        </div>);
}