import { UilAngleRight } from "@iconscout/react-unicons";
import { Circle } from "rc-progress";
import { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  ShowAssetUploadState,
  UploadedFileState,
  UploadFileCountState,
  UploadFilePercentageState,
  UploadIsInProgressState,
} from "../../atoms";

export function MinimizedUploadStatus() {
  const [, setUploadedFiles] = useRecoilState(UploadedFileState);
  const [showStatus, setShowStatus] = useState(false);
  const uploadFileCountState = useRecoilValue(UploadFileCountState);
  const uploadFilePercentageState = useRecoilValue(UploadFilePercentageState);
  const uploadIsInProgressState = useRecoilValue(UploadIsInProgressState);
  const [, setShowAssetUpload] = useRecoilState(ShowAssetUploadState);

  let timeOutId = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (uploadIsInProgressState && !showStatus) {
      window.addEventListener("beforeunload", unloadCallback.current);
      setShowStatus(true);
      clearTimeout(timeOutId.current);
    } else if (!uploadIsInProgressState && showStatus) {
      window.removeEventListener("beforeunload", unloadCallback.current);
      timeOutId.current = setTimeout(() => {
        setShowStatus(false);
        setUploadedFiles([]);
      }, 10000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    uploadFileCountState,
    uploadFilePercentageState,
    uploadIsInProgressState,
  ]);

  const unloadCallback = useRef((event: any) => {
    return;
  });

  const hadnleOpenfullClick = () => {
    setShowAssetUpload(true);
  };

  return (
    <div>
      {showStatus && (
        <div className="m-4 bg-white bg-opacity-5 rounded px-4 py-2 flex justify-between  items-center">
          <div className="flex flex-row justify-center items-center gap-2">
            <Circle
              className="w-6 h-6"
              percent={uploadFilePercentageState}
              strokeWidth={10}
              strokeColor="#0767B9"
            />
            <div>
              {uploadFileCountState.completedCount +
                " of " +
                uploadFileCountState.totalCount}
            </div>
          </div>
          <UilAngleRight
            className="cursor-pointer"
            onClick={hadnleOpenfullClick}
          />
        </div>
      )}
    </div>
  );
}
