import "./Spinner.css";

type Props = {
    className?: string
};

export function Spinner({ className }: Props) {

    return (
        <div className={`loaders ${className}`}>
            <div className="loader">
                <div className="loader-inner line-spin-fade-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
}