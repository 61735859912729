import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  PlaylistsClient,
  CreatePlaylistDto,
  CanvasBasicDto,
  CanvasesClient,
} from "../../services";
import { GetNewInstance, Store } from "../../helpers";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { Color } from "../../constants";
import { ColorInput } from "../Misc";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SelectedPlaylistState } from "../../atoms";
import { SpinnerButton } from "../buttons";
import { toast } from "react-toastify";
import { UilPlus } from "@iconscout/react-unicons";

const addPlaylistRequestSchema = yup
  .object({
    name: yup.string().required("Name is required"),
    description: yup.string(),
    transitionDurationInSecounds: yup
      .number()
      .typeError("Default transition duration must be between 0 and 7 seconds")
      .min(0, "Default transition duration must be greater or equal to 0")
      .max(7, "Maximumm default transition duration is 7 secounds"),
  })
  .required();

type Props = {
  onSuccess: () => void;
  onFailure: () => void;
};

export function AddPlaylistForm({ onSuccess, onFailure }: Props) {
  const [selectedColor, setSelectedColor] = useState<Color>(Color.Red);
  const [, setSelectedPlaylist] = useRecoilState(SelectedPlaylistState);
  const [showCanvasOption, setShowCanvasOption] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [canvases, setCanvases] = useState<CanvasBasicDto[]>([]);
  const [playlistsCanvas, setPlaylistsCanvas] = useState<string[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreatePlaylistDto>({
    resolver: yupResolver(addPlaylistRequestSchema),
  });

  const canvasClient = new CanvasesClient(undefined, GetNewInstance());
  const client = new PlaylistsClient(undefined, GetNewInstance());
  const usersCustomerId = Store.getCustomerId();
  const navigate = useNavigate();
  let canvasTimeOutId: NodeJS.Timeout;
  // const playlistsCanvas = useRef<string[]>([]);

  useEffect(() => {
    loadCanvases();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (request: CreatePlaylistDto) => {
    request.color = selectedColor.toString();
    request.canvasIds = playlistsCanvas;
    usersCustomerId &&
      client
        .createPlaylist(usersCustomerId, request)
        .then((response) => {
          toast.success("Playlist created successfully");
          setShowSpinner(false);
          onSuccess();
          response?.data &&
            request.name &&
            setSelectedPlaylist({ id: response?.data, name: request.name });
          response?.data && navigate("/playlist/edit");
        })
        .catch((error) => {
          setShowSpinner(false);
          console.log(error);
        });
    setShowSpinner(true);
  };

  const loadCanvases = () => {
    usersCustomerId &&
      canvasClient.getAllCanvases(usersCustomerId).then((x) => {
        x?.data && setCanvases(x?.data);
      });
  };

  const handleCanvasSelection = (value: string | undefined) => {
    if (value === undefined) return;

    let isChecked = playlistsCanvas.includes(value);

    if (isChecked) {
      setPlaylistsCanvas([...playlistsCanvas.filter((x) => x !== value)]);
    } else {
      setPlaylistsCanvas([...playlistsCanvas, value]);
    }
  };

  const handleOnCanvasBlur = () => {
    canvasTimeOutId = setTimeout(() => {
      setShowCanvasOption(false);
    }, 100);
  };

  const handleOnCanvasFocus = () => {
    clearTimeout(canvasTimeOutId);
  };

  return (
    <form className="py-4 text-sm text-gray-100">
      <div className="my-4">
        <label className="flex flex-col">
          <span>Playlist name</span>
          <input
            {...register("name")}
            className="border-page-border bg-white bg-opacity-5 p-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
            type="text"
          />
          <small className="text-red-600">{errors.name?.message}</small>
        </label>
      </div>
      <div className="my-4">
        <label className="flex flex-col">
          <span>Playlist description</span>
          <textarea
            {...register("description")}
            className="border-page-border bg-white bg-opacity-5 p-3 my-2 rounded-md border-0  invalid:outline-red-500 disabled:text-gray-300"
          />
          <small className="text-red-600">{errors.description?.message}</small>
        </label>
      </div>
      <div className="my-4">
        <div className="flex flex-row">
          <div className="col w-1/2 " tabIndex={1}>
            <label className="flex flex-col">
              <span>Color</span>
            </label>
            <ColorInput
              currentColor={selectedColor}
              onSelected={(x) => setSelectedColor(x)}
            />
          </div>
          <div id="canvas_dropdown" className="col w-1/2 relative" tabIndex={2}>
            <p>Canvases</p>
            <button
              onClick={(e) => {
                e.preventDefault();
                let element = document.getElementById("canvas_dropdown");
                element?.focus();
                setShowCanvasOption(!showCanvasOption);
              }}
              className="h-11 w-min-44 rounded-md border border-page-border flex items-center bg-white bg-opacity-5 overflow-hidden pr-6"
            >
              <UilPlus className="w-5 h-5 mx-4 text-gray-200" />
              Add to canvases
            </button>
            {showCanvasOption && (
              <div
                className="absolute w-44 top-18 left-0 p-3 z-10 rounded-md border border-page-border  text-sm bg-dropdown min-w-max"
                tabIndex={1}
                onBlur={handleOnCanvasBlur}
                onFocus={handleOnCanvasFocus}
              >
                <ul className=" max-h-64 overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
                  {canvases.map((x) => (
                    <li key={x.id}>
                      <label className="flex items-center gap-2 px-1 py-1">
                        <input
                          className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4"
                          type="checkbox"
                          checked={playlistsCanvas.includes(x?.id ?? "")}
                          onChange={() => handleCanvasSelection(x?.id)}
                        />
                        <span>{x.name}</span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="my-4">
        <label className="flex flex-col">
          <span>Default transition duration</span>
          <div className="h-11 rounded-md border border-page-border flex items-center bg-input-bg overflow-hidden ">
            <input
              {...register("transitionDurationInSecounds")}
              className="h-full w-full  bg-transparent mx-4 border-r border-page-border border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
              type="number"
              min="0"
              max="7"
            />
            <span className="w-auto h-5 mx-4 text-gray-200">seconds</span>
          </div>
          <small className="text-red-600">
            {errors.transitionDurationInSecounds?.message}
          </small>
        </label>
      </div>
      <div className="mt-8 flex justify-end gap-4">
        <button
          onClick={onFailure}
          className="py-2.5 px-4 rounded-md border border-page-border bg-white bg-opacity-5 hover:bg-opacity-10"
        >
          Cancel
        </button>
        <SpinnerButton
          className={`py-2.5 px-4 rounded-md bg-button-bg-primary-default hover:bg-button-bg-primary-hover active:shadow-button_primary ${
            showSpinner ? "disabled" : ""
          } `}
          showSpinner={showSpinner}
          onClick={handleSubmit(onSubmit)}
        >
          Create playlist
        </SpinnerButton>
      </div>
    </form>
  );
}
