import { useEffect, useState } from "react";
import { SelectListDto } from "../../services";
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';

type PBDropdownProps<T> = {
    options: T[];
    selectedOption: T | undefined;
    onOptionSelected: (option: T) => void;
};

export function PBDropdown<T>({ options, selectedOption, onOptionSelected }: PBDropdownProps<SelectListDto>) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        return false;
    };

    const handleOptionClick = (option: SelectListDto) => {
        onOptionSelected(option);
        toggleDropdown();
    };

    return (
        <div className={`relative text-sm`}>
            <div
                onClick={toggleDropdown}
                className="h-11 gap-2 px-4 rounded-md border border-page-border flex justify-between items-center bg-white bg-opacity-5 overflow-hidden"
            >
                <span>
                    {selectedOption?.label ?? 'Select an option'}
                </span>
                {isOpen ? (
                    <UilAngleUp className="items-end" />
                ) : (
                    <UilAngleDown className="items-end" />
                )}
            </div>
            {isOpen && (
                <div
                    className={`absolute w-full z-10 top-12 right-0 rounded-md border border-page-border bg-dropdown min-w-max cursor-pointer `}
                >
                    <ul className=" max-h-64 overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
                        {options.map((option) => (
                            <li key={option.value} onClick={() => handleOptionClick(option)}
                                className={` px-4 rounded-md hover:bg-button-bg-primary-hover ${selectedOption === option
                                    ? " bg-white bg-opacity-10 border-l-blue-500 border-l-4"
                                    : ""
                                    }`}
                            >
                                <label
                                    className="flex items-center gap-4 py-2.5  cursor-pointer"
                                >
                                    <span>{option.label}</span>
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}