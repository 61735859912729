import { BaseModal } from "./BaseModal";
import { AddUserForm } from "../form";
import { ModalTypes } from "./ModalTypes";

type Props = {
    onSuccess: () => void;
    onFailure: () => void;
}

export function AddUserModal({ onSuccess, onFailure }: Props) {

    return (
        <BaseModal id={ModalTypes.HeaderModal} title='Invite users' description='They will receive invitation email guiding them to complete their registration.'>
            <AddUserForm onSuccess={onSuccess} onFailure={onFailure} />
        </BaseModal>
    );
}