import {
  SchedulesClient,
  CreateScheduleDto,
  DayOfWeek,
  RecurringType,
} from "../../services";
import { GetNewInstance, Store } from "../../helpers";
import { useRecoilState } from "recoil";
import { AddScheduleModalState, ModalTypeState } from "../../atoms";
import { useEffect, useRef, useState } from "react";
import {
  Chip,
  CustomDatePicker,
  CustomDateTimePicker,
  CustomTimePicker,
} from "../Misc";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { RepeatSelectionDropDown } from "../actionButtons";
import { SpinnerButton } from "../buttons";
import { toast } from "react-toastify";

type Props = {
  onSuccess: () => void;
  onFailure?: () => void;
};

export function AddScheduleForm({ onSuccess, onFailure }: Props) {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const [addScheduleInfo] = useRecoilState(AddScheduleModalState);
  const [isRepeat, setIsRepeat] = useState<boolean>(false);
  const [selectedRepeat, setSelectedRepeat] = useState<RecurringType>(
    RecurringType.Daily
  );
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [startTime, setStartTime] = useState<Date | undefined>(undefined);
  const [endRepeateDate, setEndRepeateDate] = useState<Date | undefined>(
    undefined
  );
  const [duration, setDuration] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<dayjs.Dayjs>();
  const [, setModalType] = useRecoilState(ModalTypeState);
  const [selectedWeekDays, setSelectedWeekDays] = useState<number[]>([]);
  const [showSpinner, setShowSpinner] = useState(false);

  const usersCustomerId = Store.getCustomerId();
  const currentCustomer = Store.getCurrentCustomer();
  const client = new SchedulesClient(undefined, GetNewInstance());
  const durationInSecounds = useRef<number>(0);
  const maxDuration = useRef<Date | undefined>(undefined);

  useEffect(() => {
    if (
      !addScheduleInfo ||
      addScheduleInfo === undefined ||
      addScheduleInfo.canvasId === undefined
    ) {
      return;
    }

    let startDay = dayjs(addScheduleInfo.scheduleStart).tz(
      currentCustomer?.timeZoneIANA
    );
    durationInSecounds.current = addScheduleInfo.durationInSecounds;

    let newStartTime = new Date(
      0,
      0,
      0,
      startDay.hour(),
      startDay.minute(),
      startDay.second()
    );

    maxDuration.current = dayjs(new Date(0, 0, 0, 23, 59, 59))
      .subtract(newStartTime.getHours(), "hour")
      .subtract(newStartTime.getMinutes(), "minute")
      .subtract(newStartTime.getSeconds(), "second")
      .toDate();

    setStartTime(newStartTime);

    maxDuration.current = dayjs(new Date(0, 0, 0, 23, 59, 59))
      .subtract(newStartTime.getHours(), "hour")
      .subtract(newStartTime.getMinutes(), "minute")
      .subtract(newStartTime.getSeconds(), "second")
      .toDate();

    const newStartDate = new Date(
      startDay.year(),
      startDay.month(),
      startDay.date()
    );
    setStartDate(newStartDate);

    const newDuration = new Date(0, 0, 0, 0, 0, durationInSecounds.current);
    setDuration(newDuration);

    const endDay = startDay.add(durationInSecounds.current, "second");
    setEndDate(endDay);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (
      !addScheduleInfo ||
      addScheduleInfo === undefined ||
      startDate === undefined ||
      startTime === undefined ||
      duration === undefined
    ) {
      return;
    }

    let request: CreateScheduleDto = new CreateScheduleDto();
    request.canvasId = addScheduleInfo?.canvasId;
    let startDay = dayjs(
      new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        startTime.getHours(),
        startTime.getMinutes(),
        startTime.getSeconds()
      )
    ).tz(currentCustomer?.timeZoneIANA, true);

    request.isPlaylist = addScheduleInfo.resourceType === "Playlist";
    request.resourceId = addScheduleInfo.resourceId;
    request.startDate = startDay.toDate();
    request.endDate = endDate?.toDate();
    request.recurringType = isRepeat ? selectedRepeat : RecurringType.None;
    request.recurringDays = isRepeat
      ? selectedWeekDays.map((x) => convertSelectionToDayOfWeekEnum(x))
      : [];

    if (isRepeat && endRepeateDate) {
      let endRepeatDay = dayjs(
        new Date(
          endRepeateDate.getFullYear(),
          endRepeateDate.getMonth(),
          endRepeateDate.getDate(),
          endRepeateDate.getHours(),
          endRepeateDate.getMinutes(),
          endRepeateDate.getSeconds()
        )
      ).tz(currentCustomer?.timeZoneIANA, true);

      request.recurringEndDateTime = endRepeatDay.toDate();
    } else {
      request.recurringEndDateTime = undefined;
    }

    usersCustomerId &&
      client
        .createSchedule(usersCustomerId, request)
        .then((response) => {
          toast.success("Schedule created successfully");
          setShowSpinner(false);
          setModalType(null);
          onSuccess();
        })
        .catch((error) => {
          setShowSpinner(false);
          setModalType(null);
          console.log(error);
        });
    setShowSpinner(true);
  };

  const handleDurationChange = (updated: Date) => {
    setDuration(updated);
  };

  const handleStartTimeChange = (updated: Date) => {
    maxDuration.current = dayjs(new Date(0, 0, 0, 23, 59, 59))
      .subtract(updated.getHours(), "hour")
      .subtract(updated.getMinutes(), "minute")
      .subtract(updated.getSeconds(), "second")
      .toDate();

    setStartTime(updated);
  };

  useEffect(() => {
    if (
      startDate === undefined ||
      startTime === undefined ||
      duration === undefined
    ) {
      return;
    }

    let startDay = dayjs(
      new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        startTime.getHours(),
        startTime.getMinutes(),
        startTime.getSeconds()
      )
    ).tz(currentCustomer?.timeZoneIANA, true);

    let durationDay = dayjs(duration);

    let endDay = startDay.add(durationDay.hour(), "hour");
    endDay = endDay.add(durationDay.minute(), "minute");
    endDay = endDay.add(durationDay.second(), "second");
    setEndDate(endDay);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, startDate, duration]);

  const convertSelectionToDayOfWeekEnum = (num: number): DayOfWeek => {
    const index = num + 1;
    return Object.values(DayOfWeek)[index === 7 ? 0 : index];
  };

  return (
    <form className="py-4 text-sm text-white">
      <div className="my-4">
        <label className="flex flex-col">
          <span>Canvases</span>
          <div className="h-auto flex-wrap rounded-md border border-page-border my-4 bg-Secondary-Input-bg">
            <div className="flex-wrap flex p-1 gap-1 items-center">
              <Chip className="bg-Secondary-Dashboard-bg text-gray-100 px-3 py-1 border-page-border ">
                {addScheduleInfo?.canvaseName ?? ""}
              </Chip>

              <input
                disabled
                className="h-full bg-transparent border-0 px-2"
                type="text"
              />
            </div>
          </div>
          <small className="text-red-600"></small>
        </label>
      </div>
      <div className="flex flex-row gap-4 justify-between">
        <div className="my-4 w-full">
          <label className="flex flex-col">
            <span>Start date</span>
            {startDate !== undefined && (
              <CustomDatePicker
                value={startDate}
                onChange={(updated) => setStartDate(updated)}
              />
            )}
            <small className="text-red-600"></small>
          </label>
        </div>
        <div className="my-4 w-full">
          <label className="flex flex-col">
            <span>Start time</span>
            {startTime !== undefined && (
              <CustomTimePicker
                key="start_time"
                nameId="start"
                show24={false}
                value={startTime}
                onChange={(updated) => handleStartTimeChange(updated)}
              />
            )}
            <small className="text-red-600"></small>
          </label>
        </div>
      </div>
      <div className="flex flex-row gap-4 justify-between">
        <div className="my-4 w-full">
          <label className="flex flex-col">
            <span>Duration</span>
            {startTime !== undefined && (
              <CustomTimePicker
                key="duration"
                nameId="duration"
                show24
                value={duration}
                maxTime={maxDuration.current}
                onChange={(updated) => handleDurationChange(updated)}
              />
            )}
            <small className="text-red-600"></small>
          </label>
        </div>
        <div className="my-4 w-full">
          <label className="flex flex-col">
            <span>End date</span>
            <div className="h-11 rounded-md border border-page-border flex justify-center items-center bg-input-bg overflow-hidden">
              <div className="h-full w-full flex justify-start text-gray-400 bg-transparent mx-4 items-center">
                {endDate && endDate.format("MM/DD/YYYY   hh:mm:ss A")}
              </div>
            </div>
            <small className="text-red-600"></small>
          </label>
        </div>
      </div>
      <div>
        <label className="flex items-center gap-2 px-1 py-2.5 w-36 cursor-pointer">
          <input
            className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4"
            type="checkbox"
            onChange={(x) => setIsRepeat(!isRepeat)}
          />
          <span>Repeat schedule</span>
        </label>
      </div>
      {isRepeat ? (
        <div className={`flex flex-row gap-4 justify-between w-full `}>
          <div className="my-4 w-full">
            <label className="flex flex-col">
              <span>Repeat</span>
              <RepeatSelectionDropDown
                onChange={(value) => setSelectedRepeat(value)}
              />
              <small className="text-red-600"></small>
            </label>
          </div>
          <div className="my-4 w-full">
            <label className="flex flex-col">
              <span>End repeat</span>
              {
                <CustomDateTimePicker
                  value={endRepeateDate}
                  onChange={(updated) =>
                    setEndRepeateDate(updated ?? undefined)
                  }
                />
              }
              <small className="text-red-600"></small>
            </label>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isRepeat && selectedRepeat === "Weekly" ? (
        <div className={`flex flex-row gap-4 justify-between w-full`}>
          <div className="my-4 w-full">
            <label className="flex flex-col">
              <span>Repeat schedule</span>
              <div className="flex flex-row gap-2">
                {["M", "T", "W", "T", "F", "S", "S"].map((x, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      selectedWeekDays.includes(i)
                        ? setSelectedWeekDays(
                            selectedWeekDays.filter((d) => d !== i)
                          )
                        : setSelectedWeekDays([...selectedWeekDays, i]);
                    }}
                    className={`rounded-full cursor-pointer h-8 w-8 flex justify-center items-center
                                        ${
                                          selectedWeekDays.includes(i)
                                            ? " bg-Palette-6"
                                            : "bg-Secondary-Input-bg"
                                        }                        
                                    `}
                  >
                    <span> {x}</span>
                  </div>
                ))}
              </div>
            </label>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="mt-8 flex justify-end gap-4">
        <button
          onClick={() => setModalType(null)}
          className="py-2.5 px-4 rounded-md border border-page-border bg-white bg-opacity-5 hover:bg-opacity-10"
        >
          Cancel
        </button>
        <SpinnerButton
          className={`py-2.5 px-4 rounded-md bg-button-bg-primary-default hover:bg-button-bg-primary-hover active:shadow-button_primary ${
            showSpinner ? "disabled" : ""
          } `}
          showSpinner={showSpinner}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            handleOnSubmit(e);
          }}
        >
          Add to schedule
        </SpinnerButton>
      </div>
    </form>
  );
}
