import { UserInfoListDto } from "../services";
import { CurrentCustomer } from "../datas";

const getToken = () => sessionStorage.getItem("token");

const setToken = (token: string) => sessionStorage.setItem("token", token);

const removeToken = () => sessionStorage.removeItem("token");

const getCustomerId = () => getCurrentCustomer()?.id;

const getLoggedInUser = (): UserInfoListDto | null => {
  const userJson = sessionStorage.getItem("loggedInUser");
  return userJson ? JSON.parse(userJson) : null;
};

const setLoggedInUser = (user: UserInfoListDto) =>
  sessionStorage.setItem("loggedInUser", JSON.stringify(user));

const removeLoggedInUser = () => sessionStorage.removeItem("loggedInUser");

const getCurrentCustomer = (): CurrentCustomer | null => {
    const customerJson = sessionStorage.getItem("currentCustomer");
    return customerJson ? JSON.parse(customerJson) : null;
};

const setCurrentCustomer = (customer: CurrentCustomer) =>
    sessionStorage.setItem("currentCustomer", JSON.stringify(customer));

const removeCurrentCustomer = () => sessionStorage.removeItem("currentCustomer");

export const Store = {
  getToken,
  setToken,
  removeToken,
  getCustomerId,
  getLoggedInUser,
  setLoggedInUser,
  removeLoggedInUser,
  getCurrentCustomer,
  setCurrentCustomer,
  removeCurrentCustomer
};
