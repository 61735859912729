import { useRecoilState } from "recoil";
import { ModalTypeState } from "../../atoms";
import { BaseModal } from "./BaseModal";
import { Store } from "../../helpers";
import { useNavigate } from "react-router-dom";
import { ModalTypes } from "./ModalTypes";

export function LogoutModal() {

    const [, setModalType] = useRecoilState(ModalTypeState);

    const navigate = useNavigate();

    const onCancel = () => {
        setModalType(null);
    }

    const onLogoutClick = () => {
        setModalType(null);
        Store.removeToken();
        navigate('/login');
    }

    return (
        <BaseModal id={ModalTypes.LogoutModal} title="Logout" description="Are you sure you want to logout?">
            <div className="py-4 text-sm text-white">
                <div className="mt-8 flex justify-end gap-4">
                    <button onClick={onCancel}
                        className="py-2.5 px-4 rounded-md border border-page-border bg-white bg-opacity-5 hover:bg-opacity-10">Cancel
                    </button>
                    <button onClick={onLogoutClick}
                        className="py-2.5 px-4 rounded-md bg-button-bg-destructive-default hover:bg-button-bg-destructive-hover active:shadow-button-bg-destructive-default">
                        Logout</button>
                </div>
            </div>
        </BaseModal>
    );
}