import { CanvasListDto } from "../../services";
import { CanvasActionButton } from "../actionButtons";

type Props = {
  canvas: CanvasListDto;
};

export function CanvasListItem({ canvas }: Props) {
  return (
    <tr key={canvas.id}>
      <td className="px-4 py-3 border-b w-28 border-page-border items-center justify-center">
        {canvas.siteId}
      </td>
      <td className="px-4 py-3 border-b w-28 border-page-border items-center justify-center">
        {canvas.multiplexNumber}
      </td>
      <td className="px-4 py-2 border-b border-page-border">
        <div className="flex gap-1 flex-col items-start">
          {/* <img className="h-8 w-8 object-cover rounded-full" src={` ${Canvas.logo === null || Canvas.logo?.length === 0 ? placeholderlogo : thumbnailLink} `} alt="logo" /> */}
          {/* <div className="flex flex-col"> */}
          <span className="justify-center text-body_16 text-gray-100">
            {canvas.name}
          </span>
          <small className="justify-center text-body_14 text-gray-300">
            {canvas.description}
          </small>
          {/* </div> */}
        </div>
      </td>
      <td className="px-4 py-3 border-b border-page-border">
        {canvas.ipAddress}
      </td>
      <td className="px-4 py-3 border-b border-page-border">
        {canvas.widthResolution + "x" + canvas.heightResolution}
      </td>
      <td className="px-4 py-3 border-b border-page-border w-12">
        <CanvasActionButton canvas={canvas} />
      </td>
    </tr>
  );
}
