import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { RequestResetPasswordDto, UsersClient } from "../services";
import { yupResolver } from "@hookform/resolvers/yup";
import { baseURL } from "../constants/ApiConstans";
import * as yup from "yup";

const requestResetPasswordSchema = yup
  .object({
    email: yup.string().required("Email is required"),
  })
  .required();

export function RequestResetPassword() {
  let navigate = useNavigate();

  const client = new UsersClient(baseURL);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RequestResetPasswordDto>({
    resolver: yupResolver(requestResetPasswordSchema),
  });

  const onSubmit = (request: RequestResetPasswordDto) => {
    client.resetUsersPasswordByEmail(request)
      .then((response: any) => {
        //console.log("response : " + response);
      })
      .catch(() => { })
      .finally(() => {
        navigate("/resetpassword/result?email=" + request.email);
      });
  };

  return (
    <div className="h-screen w-full overflow-auto bg-bg text-white font-avenir">
      <div className=" h-32 flex items-center pl-16">
        <img
          className=" h-12"
          src={require("../assets/Pandoras_Box_Logo_RGB_negativ_SOLID 1.png")}
          alt="Pandoras Box"
        />
      </div>
      <div className="flex justify-center">
        <div className="w-full max-w-md">
          <div className="text-h3 text-gray-50 font-medium">
            Forgot password?
          </div>
          <small className="opacity-50 text-gray-200 text-body_14">
            We’ll send you instructions to reset your password.
          </small>
          <form className="py-4 text-sm" onSubmit={handleSubmit(onSubmit)}>
            <div className="my-4">
              <label className="flex flex-col text-gray-100">
                <span>Email</span>
                <input
                  {...register("email")}
                  className="border-page-border bg-white bg-opacity-5 py-2 px-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
                  type="email"
                />
                <small className="text-red-600">{errors.email?.message}</small>
              </label>
            </div>
            <div className="my-8">
              <button className="w-full py-2.5 px-4 rounded-md bg-button-bg-primary-default hover:bg-button-bg-primary-hover active:shadow-button_primary">
                Send reset instructions
              </button>
              <Link to="/login">
                <small className="cursor-pointer w-full my-3 flex justify-center text-gray-50">
                  Back to login
                </small>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
