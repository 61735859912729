import { useState } from "react";
import { useRecoilState } from "recoil";
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';
import { UserRole } from "../../services";
import { UserFilterState } from "../../atoms/UserFilterState";

export function UserFilter() {

    const [filterIsShown, setFilterIsShown] = useState(false);
    const [filterState, setFilterState] = useRecoilState(UserFilterState);

    let timeOutId: NodeJS.Timeout;

    const onFilterClick = () => {
        setFilterIsShown(!filterIsShown);
    }

    const onSelected = (e: any) => {
        let selectedRole = e.target.innerText;
        selectedRole = selectedRole === "View only" ? "ViewOnly" : selectedRole;
        const newRole: keyof typeof UserRole = selectedRole;

        setFilterState(UserRole[newRole]);
    }

    const handleOnBlur = () => {
        timeOutId = setTimeout(() => {
            setFilterIsShown(false);
        }, 100);
    }

    const handleOnFocus = () => {
        clearTimeout(timeOutId);
    }

    return (
        <div className={`relative text-sm `} tabIndex={1} onBlur={handleOnBlur} onFocus={handleOnFocus}>
            <button onClick={onFilterClick}
                className="h-11 gap-2 px-4 rounded-md border border-page-border flex justify-between items-center bg-white bg-opacity-5 overflow-hidden">
                <span>Role</span>
                {
                    filterIsShown ?
                        <UilAngleUp className="items-end" /> :
                        <UilAngleDown className="items-end" />
                }
            </button>
            {
                filterIsShown &&
                <div className={`absolute w-full z-10 top-12 right-0 rounded-md border border-page-border bg-dropdown min-w-max cursor-pointer `}>
                    <ul
                        className=" max-h-64 overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
                        <li onClick={onSelected} className={` px-4 rounded-md hover:bg-button-bg-primary-hover ${filterState === UserRole.Admin ? " bg-white bg-opacity-10 border-l-blue-500 border-l-4" : ""}`}>
                            <label key={UserRole.Admin} className="flex items-center gap-4 py-2.5  cursor-pointer">
                                <span >Admin</span>
                            </label>
                        </li>
                        <li onClick={onSelected} className={` px-4 rounded-md hover:bg-button-bg-primary-hover ${filterState === UserRole.Editor ? "  bg-white bg-opacity-10 border-l-blue-500 border-l-4" : ""}`}>
                            <label key={UserRole.Editor} className="flex items-center gap-4 py-2.5  cursor-pointer">
                                <span >Editor</span>
                            </label>
                        </li>
                        <li onClick={onSelected} className={` px-4 rounded-md hover:bg-button-bg-primary-hover ${filterState === UserRole.ViewOnly ? "  bg-white bg-opacity-10 border-l-blue-500 border-l-4" : ""}`}>
                            <label key={UserRole.ViewOnly} className="flex items-center gap-4 py-2.5  cursor-pointer">
                                <span >View only</span>
                            </label>
                        </li>
                    </ul>
                </div>
            }
        </div>
    );
}