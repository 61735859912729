import { BaseModal } from "./BaseModal";
import { UpdateScheduleForm } from "../form";
import { ModalTypes } from "./ModalTypes";
import { SelectedScheduleState } from "../../atoms";
import { useRecoilState } from "recoil";

type Props = {
  onSuccess: () => void;
  onFailure?: () => void;
};

export function UpdateScheduleModal(props: Props) {
  const [selectedSchedule] = useRecoilState(SelectedScheduleState);

  return (
    <BaseModal
      id={ModalTypes.UpdateScheduleModal}
      title="Update schedule"
      description={
        "Specify date and time for " + selectedSchedule?.name?.toLowerCase()
      }
    >
      <UpdateScheduleForm {...props} />
    </BaseModal>
  );
}
