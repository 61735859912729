import { IconStyle } from "../components/buttons";
import * as Icon from "react-feather";
import { BasePageLayout } from "../components/layouts/BasePageLayout";
import { Store } from "../helpers";
import { PlaylistList } from "../components/mainContent";
import { RoleCheck } from "../components/auth";

export function PlaylistListPage() {
  const loggedInUser = Store.getLoggedInUser();

  return (
    <BasePageLayout
      title="Playlists"
      buttonText="Create playlist"
      icon_style={IconStyle.None}
      icon={<Icon.Camera />}
      showButton={RoleCheck.isEditorRoleMinimum(loggedInUser)}
    >
      <PlaylistList />
    </BasePageLayout>
  );
}
