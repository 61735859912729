import { PlaylistEditHeader } from "../components/layouts";
import { PlaylistEdit } from "../components/mainContent";
import { LogoutModal } from "../components/modal";
import { CustomerSidebar } from "../components/sidebar";

export function PlaylistEditPage() {
    return (
        <div className="flex">
            <div className="h-screen w-full flex bg-bg text-white">
                <CustomerSidebar />
                <div className="h-screen w-full flex flex-col">
                    <PlaylistEditHeader />
                    <PlaylistEdit />
                </div>
            </div>
            <LogoutModal />

        </div>
    );
}