import { selector } from "recoil";
import { UploadedFileState } from ".";
import { Store } from "../helpers";

export interface UploadFileCount {
  totalCount: number;
  completedCount: number;
}

export const UploadFileCountState = selector({
  key: "uploadFileCountState",
  get: ({ get }) => {
    const uploadState = get(UploadedFileState);
    const usersCustomerId = Store.getCustomerId();

    const response: UploadFileCount = {
      totalCount: uploadState.filter(
        (x) => !x.isCanceled && x.customerId === usersCustomerId
      ).length,
      completedCount: uploadState.filter(
        (x) => x.isFinished && x.customerId === usersCustomerId
      ).length,
    };

    return response;
  },
});
