import { Trash2 } from "react-feather";
import { GetNewInstance, Store } from "../../helpers";
import {
  ConnectedDeviceListDto,
  ConnectedDevicesClient,
  ConnectedDeviceStatus,
} from "../../services";
import { StatusText, StatusType } from "../typography";

type Props = {
  connectedDevice: ConnectedDeviceListDto;
  handleDeletion: () => void;
};

export function ConnectedDeviceListItem({
  connectedDevice,
  handleDeletion,
}: Props) {
  const usersCustomerId = Store.getCustomerId();

  const handleDeleteClicked = () => {
    const client = new ConnectedDevicesClient(undefined, GetNewInstance());

    connectedDevice?.id &&
      usersCustomerId &&
      client
        .deleteConnectedDevice(usersCustomerId, connectedDevice?.id)
        .then((x) => handleDeletion());
  };

  return (
    <tr>
      <td className="px-4 py-3 border-b border-page-border">
        {connectedDevice.name}
      </td>
      <td className="px-4 py-3 border-b border-page-border">
        {connectedDevice.masterIpAddress}
      </td>
      <td className="px-4 py-3 border-b border-page-border">
        {connectedDevice.registerationCode?.toUpperCase().split("").join("-")}
      </td>
      <td className="px-4 py-3 border-b border-page-border">
        {connectedDevice.status === ConnectedDeviceStatus.Active ? (
          <StatusText status={StatusType.Active}>Connected</StatusText>
        ) : (
          <StatusText status={StatusType.Inactive}>Inactive</StatusText>
        )}
      </td>
      <td className="px-4 py-3 border-b border-page-border">
        {connectedDevice?.lastConnection?.toLocaleDateString() ?? ""}
      </td>
      <td className="px-4 py-3 border-b border-page-border text-button-bg-destructive-default hover:text-button-bg-destructive-hover">
        <Trash2 onClick={handleDeleteClicked} className="w-4  cursor-pointer" />
      </td>
    </tr>
  );
}
