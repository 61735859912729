import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GetNewInstance } from "../helpers";
import { RequestResetPasswordDto, UsersClient } from "../services";

export function ResetPasswordResult() {
  let [searchParams] = useSearchParams();

  const email = searchParams.get("email") ?? undefined;

  const ResendEmail = () => {
    const client = new UsersClient(undefined, GetNewInstance());

    client
      .resetUsersPasswordByEmail(new RequestResetPasswordDto({ email: email }))
      .then((response: any) => {
        toast.success("Email resent");
      });
  };

  return (
    <div className="h-screen w-full overflow-auto bg-bg text-white font-avenir">
      <div className=" h-32 flex items-center pl-16">
        <img
          className=" h-12"
          src={require("../assets/Pandoras_Box_Logo_RGB_negativ_SOLID 1.png")}
          alt="Pandoras Box"
        />
      </div>
      <div className="flex justify-center">
        <div className="w-full max-w-md">
          <div className="font-medium text-gray-50text-h3">
            <span className="justify-center">Check your email</span>
          </div>
          <small className="text-body_14 text-gray-200 justify-center">
            If the email address {email} is associated with an active account, you will receive an email with a link to set a new password.
          </small>
          <div className=" py-4 text-sm">
            <button
              onClick={ResendEmail}
              className="w-full py-2.5 px-4 rounded-md bg-button-bg-primary-default hover:bg-button-bg-primary-hover active:shadow-button_primary"
            >
              Resend email
            </button>
            <Link to="/login">
              <small className="text-gray-50 cursor-pointer w-full my-3 flex justify-center ">
                Back to login
              </small>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
