
type Props = {
    passwordStrength: number | undefined
}

export function PasswordStrengthIndicator({ passwordStrength }: Props) {
    return (
        <li className={`w-1/4 rounded h-2 
                ${passwordStrength && passwordStrength >= 3 ? "bg-green-400" : ""}
                ${passwordStrength && passwordStrength === 2 ? "bg-orange-400" : ""}
                ${passwordStrength && passwordStrength < 2 ? "bg-red-400" : ""}
                ${!passwordStrength ? "bg-white" : ""}
                `}></li>
    );
}