import { atom } from "recoil";

type Props = {
  xPos: number;
  yPos: number;
  isShown: boolean;
  isPlaylist: boolean;
};

export const ShowContextMenuState = atom<Props>({
  key: "showContextMenuState",
  default: { isShown: false, xPos: 0, yPos: 0, isPlaylist: false },
});
