import { useState } from "react";
import { useRecoilState } from "recoil";
import { CustomerFilterState } from "../../atoms/CustomerFilterState";
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';
import { CustomerStatus } from "../../services";

export function CustomerFilter() {

    const [filterIsShown, setFilterIsShown] = useState(false);
    const [filterState, setFilterState] = useRecoilState(CustomerFilterState);

    let timeOutId: NodeJS.Timeout;

    const onFilterClick = () => {
        setFilterIsShown(!filterIsShown);
    }

    const activeSelected = () => {
        const selectedFilter = CustomerStatus.Active;
        filterState === selectedFilter ?
            setFilterState(null) :
            setFilterState(selectedFilter);
    }
    const archivedSelected = () => {
        const selectedFilter = CustomerStatus.Archived;
        filterState === selectedFilter ?
            setFilterState(null) :
            setFilterState(selectedFilter);            
    }

    const handleOnBlur = () => {
        timeOutId = setTimeout(() => {
            setFilterIsShown(false);
        }, 100);
    }

    const handleOnFocus = () => {
        clearTimeout(timeOutId);
    }

    return (
        <div className="relative text-sm" tabIndex={1} onBlur={handleOnBlur} onFocus={handleOnFocus}>
            <button onClick={onFilterClick}
                className="h-11 gap-2 px-4 rounded-md border border-page-border flex items-center bg-white bg-opacity-5 overflow-hidden">
                <span>Status</span>
                {filterIsShown ? <UilAngleUp className="items-end" /> : <UilAngleDown className="items-end" />}
            </button>
            <div className={`absolute w-full top-12 right-0 rounded-md border border-page-border bg-dropdown min-w-max cursor-pointer ${filterIsShown ? "visible" : "invisible"}`}>
                <ul
                    className=" max-h-64 overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
                    <li onClick={activeSelected} className={` px-4 rounded-md hover:bg-button-bg-primary-hover ${filterState === CustomerStatus.Active ? " bg-white bg-opacity-10 border-l-blue-500 border-l-4" : ""}`}>
                        <label className="flex items-center gap-4 py-2.5  cursor-pointer">
                            <span>Active</span>
                        </label>
                    </li>
                    <li onClick={archivedSelected} className={` px-4 rounded-md hover:bg-button-bg-primary-hover ${filterState === CustomerStatus.Archived ? "  bg-white bg-opacity-10 border-l-blue-500 border-l-4" : ""}`}>
                        <label className="flex items-center gap-4 py-2.5  cursor-pointer">
                            <span>Archived</span>
                        </label>
                    </li>
                </ul>
            </div>
        </div>
    );
}