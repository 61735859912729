
export function ResetPasswordSuccessConfirmation() {
    return (
        <div className="h-screen w-full overflow-auto bg-bg text-white font-avenir">
            <div className=" h-32 flex items-center pl-16">
                <img className=" h-12" src={require('../assets/Pandoras_Box_Logo_RGB_negativ_SOLID 1.png')} alt="Pandoras Box" />
            </div>
            <div className="flex justify-center">
                <div className="w-full max-w-md">
                    <div className="font-medium text-gray-50 text-h3">
                        <span className="justify-center text-body_14 text-gray-200">Password reset</span>
                    </div>
                    <small className="opacity-50 justify-center">Your password has been reset successfully. Login to your account to continue.</small>
                    <div className=" py-4 text-sm">
                        <button className="w-full py-2.5 px-4 rounded-md bg-button-bg-primary-default hover:bg-button-bg-primary-hover active:shadow-button_primary">Login to account</button>
                    </div>
                </div>
            </div>
        </div>
    );
}