import CacheBuster from "react-cache-buster";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { Routing } from "./routes";

function App() {
  const isDevelopment = process.env.NODE_ENV === "development";
  const currentVersion = require("../package.json").version;

  return (
    <CacheBuster
      currentVersion={currentVersion}
      isEnabled={!isDevelopment} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <RecoilRoot>
        <BrowserRouter>
          <Routing />
        </BrowserRouter>
      </RecoilRoot>
    </CacheBuster>
  );
}

export default App;
