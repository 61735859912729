import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  ScheduleTimeline,
  ScheduleAssetItem,
  SearchedPlaylistItem as SchedulePlaylistItem,
} from ".";
import { AssetFilterState, FilterIsShownState } from "../../atoms";
import { UilSearch, UilSlidersVAlt } from "@iconscout/react-unicons";
import { GetNewInstance, Store } from "../../helpers";
import {
  AssetListDto,
  AssetsClient,
  PagedServerResponseOfAssetListDto,
  PlaylistListDto,
  PlaylistsClient,
  PagedServerResponseOfPlaylistListDto,
} from "../../services";
import { AssetFilter } from "../filters";
import * as React from "react";
import { RequireEditorRoleMinimum } from "../auth";

export function Schedule() {
  const [, setFilterIsShown] = useRecoilState(FilterIsShownState);
  const [assetFilterState] = useRecoilState(AssetFilterState);
  const [searchedAsset, setSearchedAssets] = useState<AssetListDto[]>([]);
  const [searchedPlaylist, setSearchedPlaylist] = useState<PlaylistListDto[]>(
    []
  );
  const [assetSearchTerm, setAssetSearchTerm] = useState<string>("");
  const [playlistSearchTerm, setPlaylistSearchTerm] = useState<string>("");
  const [showAsset, setShowAsset] = useState(true);
  const [currentAssetPageNumber, setCurrentAssetPageNumber] =
    useState<number>(1);
  const [currentPlaylistPageNumber, setCurrentPlaylistPageNumber] =
    useState<number>(1);
  const [totalAssetPageCount, setTotalAssetPageCount] = useState<number>(1);
  const [totalPlaylistPageCount, setTotalPlaylistPageCount] =
    useState<number>(1);

  const playlistClient = new PlaylistsClient(undefined, GetNewInstance());
  const assetClient = new AssetsClient(undefined, GetNewInstance());
  const usersCustomerId = Store.getCustomerId();
  const loggedInUser = Store.getLoggedInUser();
  let timeOutId: any;

  useEffect(() => {
    showAsset ? retrieveAssets() : retrievePlaylists();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showAsset,
    assetSearchTerm,
    playlistSearchTerm,
    currentAssetPageNumber,
    currentPlaylistPageNumber,
    assetFilterState,
  ]);

  const retrieveAssets = () => {
    clearTimeout(timeOutId);
    timeOutId = setTimeout(() => {
      assetFilterState &&
        usersCustomerId &&
        assetClient
          .getAssets(
            usersCustomerId,
            assetSearchTerm,
            null,
            assetFilterState.fileTypes ?? [],
            assetFilterState.fileCodecs ?? [],
            assetFilterState.aspectRatios ?? [],
            assetFilterState.resolution ?? [],
            assetFilterState.tags ?? [],
            null,
            currentAssetPageNumber
          )
          .then((response: PagedServerResponseOfAssetListDto | null) => {
            if (response?.data)
              currentAssetPageNumber === 1
                ? setSearchedAssets(response?.data)
                : setSearchedAssets([...searchedAsset, ...response?.data]);
            response?.pageCount && setTotalAssetPageCount(response?.pageCount);
          })
          .catch((e: Error) => {
            console.log(e);
          });
    }, 300);
  };

  const retrievePlaylists = () => {
    clearTimeout(timeOutId);
    timeOutId = setTimeout(() => {
      usersCustomerId &&
        playlistClient
          .getPlaylists(
            usersCustomerId,
            playlistSearchTerm,
            currentPlaylistPageNumber
          )
          .then((response: PagedServerResponseOfPlaylistListDto | null) => {
            if (response?.data)
              currentPlaylistPageNumber === 1
                ? setSearchedPlaylist(response?.data)
                : setSearchedPlaylist([...searchedPlaylist, ...response?.data]);
            response?.pageCount &&
              setTotalPlaylistPageCount(response?.pageCount);
          })
          .catch((e: Error) => {
            console.log(e);
          });
    }, 300);
  };

  const handleSearchChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    showAsset
      ? setAssetSearchTerm(e.target.value)
      : setPlaylistSearchTerm(e.target.value);
  };

  return (
    <div className=" flex h-full w-full overflow-hidden">
      <RequireEditorRoleMinimum user={loggedInUser}>
        <div className="w-80 border-r border-page-border flex-none h-full">
          <div className="pt-8 p-5 h-full w-full flex flex-col">
            <div className="flex items-center justify-between gap-2 text-gray-300">
              <div className="h-11 w-full rounded-md border border-page-border flex items-center justify-between bg-white bg-opacity-5">
                <div className="flex items-center">
                  <UilSearch className="w-5 h-5 mx-2" />
                  <input
                    onChange={handleSearchChanged}
                    className="h-full bg-transparent border-0"
                    type="text"
                    placeholder={`Search for ${
                      showAsset ? "asset" : "playlist"
                    }`}
                  />
                </div>
                {showAsset && (
                  <UilSlidersVAlt
                    onClick={() => setFilterIsShown(true)}
                    className="border m-2 rounded border-page-border bg-white bg-opacity-5"
                  />
                )}
              </div>
            </div>
            <div className="flex justify-center gap-12 border-b mb-10 border-page-border items-center text-gray-300">
              <span
                onClick={() => setShowAsset(false)}
                className={`flex items-center h-16  px-6 cursor-pointer ${
                  !showAsset &&
                  "border-b border-button-bg-primary-default text-button-bg-primary-default"
                } `}
              >
                Playlists
              </span>
              <span
                onClick={() => setShowAsset(true)}
                className={`flex items-center h-16  px-6 cursor-pointer ${
                  showAsset &&
                  "border-b border-button-bg-primary-default text-button-bg-primary-default"
                } `}
              >
                Assets
              </span>
            </div>
            <div className="overflow-y-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
              <div>
                <table className="flex truncate text-sm w-full">
                  <tbody className="w-full">
                    {showAsset
                      ? searchedAsset.map((x) => (
                          <ScheduleAssetItem
                            key={x.id}
                            asset={x}
                            defaultImageDurationInSecounds={30}
                          />
                        ))
                      : searchedPlaylist.map((x) => (
                          <SchedulePlaylistItem
                            key={x.id}
                            playlist={x}
                            draggable
                          />
                        ))}
                  </tbody>
                </table>
                {((showAsset &&
                  currentAssetPageNumber !== totalAssetPageCount) ||
                  (!showAsset &&
                    currentPlaylistPageNumber !== totalPlaylistPageCount)) && (
                  <div
                    className="text-button-bg-primary-default p-2 cursor-pointer text-center"
                    onClick={() => {
                      if (showAsset)
                        setCurrentAssetPageNumber(currentAssetPageNumber + 1);
                      else
                        setCurrentPlaylistPageNumber(
                          currentPlaylistPageNumber + 1
                        );
                    }}
                  >
                    load more
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </RequireEditorRoleMinimum>
      <div className="w-full overflow-y-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
        <ScheduleTimeline loggedInUser={loggedInUser} />
      </div>
      <AssetFilter />
    </div>
  );
}
