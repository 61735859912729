import { useEffect, useRef, useState } from "react";
import { AddCustomerModal } from "../modal";
import { useRecoilState } from "recoil";
import { ModalTypeState } from "../../atoms";
import { UilRedo, UilSearch } from "@iconscout/react-unicons";
import { GetNewInstance } from "../../helpers/HttpCommon";
import { CustomerListDto, CustomersClient } from "../../services";
import { CustomerListItem } from "./CustomerListItem";
import { CustomerFilter } from "../filters";
import { CustomerFilterState } from "../../atoms/CustomerFilterState";
import { ArchiveCustomerModal } from "../modal/ArchiveCustomerModal";
import { ActivateCustomerModal } from "../modal/ActivateCustomerModal";
import { UpdateCustomerModal } from "../modal/UpdateCustomerModal";
import { Pagination } from "../layouts";
import { Store } from "../../helpers";

export default function CusomerList() {
  const client = new CustomersClient(undefined, GetNewInstance());
  const [customers, setCustomers] = useState<CustomerListDto[]>([]);
  const [, setModalType] = useRecoilState(ModalTypeState);

  const [filterState, setFilterState] = useRecoilState(CustomerFilterState);
  const [totalPageNumber, setTotalPageNumber] = useState(1);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  let timeOutId = useRef<NodeJS.Timeout>();

  useEffect(() => {
    Store.removeCurrentCustomer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentPageNumber(1);
    retrieveCustomers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, filterState]);

  useEffect(() => {
    retrieveCustomers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber]);

  const retrieveCustomers = () => {
    clearTimeout(timeOutId.current);
    timeOutId.current = setTimeout(() => {
      client
        .getCustomers(currentPageNumber, filterState, searchTerm)
        .then((response) => {
          response && response.data && setCustomers(response?.data);
          if (response?.pageNumber !== undefined)
            setCurrentPageNumber(response?.pageNumber);
          response &&
            response.pageCount &&
            setTotalPageNumber(response?.pageCount);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 300);
  };

  const onAddCustomerSuccess = () => {
    retrieveCustomers();
    setModalType(null);
  };

  const onAddCustomerFailure = () => {
    setModalType(null);
  };

  return (
    <>
      <div className="h-full p-5  overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
        <div className="flex justify-between mb-5">
          <div>
            <div className="h-11 rounded-md border border-page-border flex items-center bg-white bg-opacity-5 overflow-hidden">
              <UilSearch className="w-5 h-5 mx-4" />
              <input
                onChange={(e) => setSearchTerm(e.target.value)}
                className="h-full bg-transparent border-0"
                type="text"
                placeholder="Search for customer"
                value={searchTerm ?? ""}
              />
            </div>
          </div>
          <div className="flex flex-row items-center gap-3">
            {((filterState !== null && filterState !== undefined) ||
              (searchTerm !== null &&
                searchTerm !== undefined &&
                searchTerm !== "")) && (
              <UilRedo
                className="text-gray-100 cursor-pointer"
                onClick={() => {
                  setFilterState(null);
                  setSearchTerm("");
                }}
              />
            )}
            <CustomerFilter />
          </div>
        </div>
        <div className="rounded-lg">
          <table className="w-full text-sm">
            <thead className="border-b border-page-border text-gray-300">
              <tr className="border-b border-page-border">
                <td className="px-4 py-3 ">Customer</td>
                <td className="px-4 py-3 ">Canvases</td>
                <td className="px-4 py-3 ">Status</td>
                <td className="px-4 py-3 ">Total file size</td>
                <td className="px-4 py-3 "></td>
              </tr>
            </thead>
            <tbody>
              {customers.map((x) => (
                <CustomerListItem key={x.id} Customer={x} />
              ))}
            </tbody>
          </table>
          <Pagination
            totalPageCount={totalPageNumber}
            currentPageNumber={currentPageNumber}
            setCurrentPageNumber={(x) => setCurrentPageNumber(x)}
          />
        </div>
      </div>
      <AddCustomerModal
        onSuccess={onAddCustomerSuccess}
        onFailure={onAddCustomerFailure}
      />
      <UpdateCustomerModal
        onSuccess={onAddCustomerSuccess}
        onFailure={onAddCustomerFailure}
      ></UpdateCustomerModal>
      <ArchiveCustomerModal onSuccess={retrieveCustomers} />
      <ActivateCustomerModal onSuccess={retrieveCustomers} />
    </>
  );
}
