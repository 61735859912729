import { atom } from "recoil";
import { DayOfWeek, RecurringType } from "../services";

type Props = {
  id?: string;
  customerId?: string;
  resourceId?: string;
  canvasId?: string;
  canvaseName?: string;
  name?: string;
  color?: string;
  assetThumbnail?: string;
  isPlaylist?: boolean;
  startDate?: Date;
  endDate?: Date;
  recurringType?: RecurringType;
  recurringDays?: DayOfWeek[];
  recurringStartDateTime?: Date;
  recurringEndDateTime?: Date;
};

export const SelectedScheduleState = atom<Props | null>({
  key: "selectedScheduleState ",
  default: null,
});
