import { useEffect, useState } from "react";
import { PasswordStrengthIndicator } from "./PasswordStrengthIndicator";

type Props = {
  className?: string;
  password: string | undefined;
};

export function PasswordStrength({ className, password }: Props) {
  const [passwordStrength, setPasswordStrength] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    const caseRequirmentRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])");
    const lengthRequirmentRegex = new RegExp("^(?=.{8,})");
    const numberRequirmentRegex = new RegExp("^(?=.*[0-9])");
    const specialCharacterRequirmentRegex = new RegExp("^(?=.*[@$!%*#?&])");

    let strength = 0;

    if (password) {
      caseRequirmentRegex.test(password) && strength++;
      lengthRequirmentRegex.test(password) && strength++;
      numberRequirmentRegex.test(password) && strength++;
      specialCharacterRequirmentRegex.test(password) && strength++;
    }

    setPasswordStrength(strength);
  }, [password]);

  return (
    <>
      <ul className={`flex gap-1 ${className}`}>
        {new Array(passwordStrength).fill(passwordStrength).map((x, i) => (
          <PasswordStrengthIndicator key={i} passwordStrength={x} />
        ))}
        {new Array(4 - (passwordStrength ?? 0)).fill(undefined).map((x, i) => (
          <PasswordStrengthIndicator key={i} passwordStrength={x} />
        ))}
      </ul>
    </>
  );
}
