import { PlaylistDetailHeader } from "../components/layouts";
import { PlaylistDetail } from "../components/mainContent";
import { LogoutModal } from "../components/modal";
import { CustomerSidebar } from "../components/sidebar";

export function PlaylistDetailPage() {
    return (
        <div className="flex">
            <div className="h-screen w-full flex bg-bg text-white">
                <CustomerSidebar />
                <div className="h-screen w-full flex flex-col">
                    <PlaylistDetailHeader />
                    <PlaylistDetail />
                </div>
            </div>
            <LogoutModal />

        </div>
    );
}