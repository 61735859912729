export enum StatusType {
    Active,
    Inactive,
    Danger
}

type Props = {
    status: StatusType,
    children: string,
};

export function StatusText({ status, children }: Props) {
    return (<span className={`border-2 rounded-full px-3 py-1 
    ${status === StatusType.Active ? "text-text-status-active border-text-status-active" : ""} 
    ${status === StatusType.Inactive ? "text-text-status-inactive border-text-status-inactive" : ""} 
    ${status === StatusType.Danger ? "text-text-status-danger border-text-status-danger" : ""}`}>
        {children}
    </span>);
}