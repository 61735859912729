import { AssetPlaylistListDto, UserInfoListDto } from "../../services";
import { useState } from "react";
import { baseURL } from "../../constants/ApiConstans";
import { CustomTimePicker, TimeDuration } from "../Misc";
import { Image, Trash2, Video } from "react-feather";
import { Dragable } from "../Svgs";
import dayjs from "dayjs";
import { Store } from "../../helpers";
import { ModalTypes } from "../modal";
import { useRecoilState } from "recoil";
import {
  ModalTypeState,
  SelectedAssetPlaylistState,
  SelectedAssetState,
} from "../../atoms";
import { RequireEditorRoleMinimum } from "../auth";

type Props = {
  key: string | undefined;
  asset: AssetPlaylistListDto;
  isEditable?: boolean;
  onDragStart?: (e: React.DragEvent<HTMLTableRowElement> | undefined) => void;
  onDragEnter?: (e: React.DragEvent<HTMLTableRowElement> | undefined) => void;
  onDragEnd?: (e: React.DragEvent<HTMLTableRowElement> | undefined) => void;
  onDragOver?: (e: React.DragEvent<HTMLTableRowElement> | undefined) => void;
  onDurationChange?: (updatedDurationInSecound: number) => void;
  onAssetRemove?: () => void;
  loggedInUser: UserInfoListDto | null;
};

export function PlaylistAssetListItem({
  key,
  asset,
  isEditable,
  onDragStart,
  onDragEnter,
  onDragEnd,
  onDragOver,
  onDurationChange,
  onAssetRemove,
  loggedInUser,
}: Props) {
  const [duration, setDuration] = useState<Date | undefined>(
    asset?.playlistDurationInSecounds
      ? new Date(0, 0, 0, 0, 0, asset?.playlistDurationInSecounds)
      : new Date(0, 0, 0, 0, 0, 0)
  );

  const [, setModalType] = useRecoilState(ModalTypeState);
  const [, setSelectedAssetId] = useRecoilState(SelectedAssetState);
  const [, setSelectedAssetPlaylistId] = useRecoilState(
    SelectedAssetPlaylistState
  );

  const usersCustomerId = Store.getCustomerId();

  const thumbnailLink =
    baseURL +
    "/api/customer/" +
    usersCustomerId +
    "/assets/" +
    asset.id +
    "/thumbnail/" +
    asset.thumbnailImage;

  const handleDurationChange = (newDate: Date) => {
    setDuration(newDate);
    const newDuration = dayjs(newDate);
    const secounds =
      newDuration.hour() * 60 * 60 +
      newDuration.minute() * 60 +
      newDuration.second();
    onDurationChange && onDurationChange(secounds);
  };

  const handleDeleteClicked = () => {
    if (isEditable) {
      onAssetRemove && asset.id && onAssetRemove();
    } else {
      asset.id && setSelectedAssetId(asset.id);
      asset.assetPlaylistId &&
        setSelectedAssetPlaylistId(asset.assetPlaylistId);

      setModalType(ModalTypes.DeletePlaylistAssetModal);
    }
  };

  return (
    <tr
      key={key}
      onDragStart={(e) => onDragStart && onDragStart(e)}
      onDragEnter={(e) => onDragEnter && onDragEnter(e)}
      onDragEnd={(e) => onDragEnd && onDragEnd(e)}
      onDragOver={(e) => onDragOver && onDragOver(e)}
      draggable={isEditable}
    >
      {isEditable && (
        <td className="px-1 py-2 border-b border-page-border">
          <div className="flex w-2 h-4 items-center cursor-move">
            <Dragable />
          </div>
        </td>
      )}
      <td className="px-4 py-2 border-b border-page-border">
        <div className="flex gap-4 items-center w-full">
          <img
            className="h-12 w-12 object-cover rounded"
            src={` ${thumbnailLink} `}
            alt="logo"
            onDragStart={(e) => {
              e.preventDefault();
            }}
          />
          <div className=" w-full">
            <p className="text-gray-50 text-body_16">{asset.name}</p>
            <div className="inline-flex py-1 items-center gap-2 text text-gray-300">
              <span>
                {asset.type === "Image" ? (
                  <Image className="w-4 h-4" />
                ) : (
                  <Video className="w-4 h-4" />
                )}
              </span>
              <span>{asset.fileExtension}</span>
              <small className="w-1 h-1 bg-white rounded-full "></small>
              {asset.type !== "Image" ? (
                <span>{asset.aspectRatio}</span>
              ) : (
                <span>{asset.width + " x " + asset.height} </span>
              )}
              {asset.type !== "Image" && (
                <>
                  <small className="w-1 h-1 bg-white rounded-full "></small>
                  <span>{asset.resolution}p</span>
                </>
              )}
              {asset.type !== "Image" && (
                <>
                  <small className="w-1 h-1 bg-white rounded-full "></small>
                  <TimeDuration
                    nameId={"timeduration_asset" + asset.id}
                    totalSecounds={asset.assetDurationInSecound}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </td>
      <td className="px-4 py-3 border-b border-page-border text-gray-200 text-body_16">
        {isEditable ? (
          <CustomTimePicker
            nameId="duration"
            show24
            value={duration}
            onChange={(updated) => handleDurationChange(updated)}
          />
        ) : (
          <TimeDuration
            nameId={"timeduration_totalsecound" + asset.id}
            totalSecounds={asset.playlistDurationInSecounds}
          />
        )}
      </td>
      {isEditable && (
        <td className="px-4 py-3 border-b border-page-border text-button-bg-destructive-default hover:text-button-bg-destructive-hover">
          <RequireEditorRoleMinimum user={loggedInUser}>
            <Trash2
              onClick={handleDeleteClicked}
              className="w-4  cursor-pointer"
            />
          </RequireEditorRoleMinimum>
        </td>
      )}
    </tr>
  );
}
