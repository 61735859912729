import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosResponse } from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { TokenRespone } from "../datas";
import { LoginRequest, loginRequestSchema } from "../datas/Auth/Login";
import { AuthService, UsersClient } from "../services";
import { Store, GetNewInstance } from "../helpers";
import { useState } from "react";
import { UilEye } from "@iconscout/react-unicons";

export function Login() {
  let navigate = useNavigate();

  const [hasServerError, setHasServerError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  Store.removeLoggedInUser();
  Store.removeCurrentCustomer();
  Store.removeToken();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginRequest>({
    resolver: yupResolver(loginRequestSchema),
  });

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = (request: LoginRequest) => {
    setHasServerError(false);
    AuthService.signin(request)
      .then((response: AxiosResponse<TokenRespone>) => {
        const tokenRespone = response.data;
        Store.setToken(tokenRespone.access_token);

        const client = new UsersClient(undefined, GetNewInstance());
        client
          .getLoggedInUserInfo()
          .then((x) => {
            if (x?.data) {
              const userInfo = x?.data;
              Store.setLoggedInUser(userInfo);
              if (userInfo.customerId) {
                Store.setCurrentCustomer({
                  id: userInfo.customerId,
                  name: userInfo.companyName ?? "",
                  timeZoneName: userInfo.timeZoneInfo?.timeZoneName ?? "",
                  timeZoneIANA: userInfo.timeZoneInfo?.timeZoneIANA ?? "",
                  timeZoneOffset: userInfo.timeZoneInfo?.timeZoneOffset ?? "",
                  totalFileSizedUsed: userInfo.totalFileSizedUsed ?? 0,
                  storageLimit: userInfo.storageLimit,
                });
              }
              navigate("/");
            }
          })
          .catch((x) => {
            console.log(x);
          });
      })
      .catch((x) => {
        setHasServerError(true);
      });
  };

  return (
    <div className="h-screen w-full overflow-auto bg-bg text-white font-avenir">
      <div className=" h-32 flex items-center pl-16">
        <img
          className=" h-12"
          src={require("../assets/Pandoras_Box_Logo_RGB_negativ_SOLID 1.png")}
          alt="Pandoras Box"
        />
      </div>
      <div className="flex justify-center">
        <div className="w-full max-w-md">
          <div className="font-medium text-h3">Login</div>
          <small className="opacity-50 text-gray-200 text-body_14 font-normal">
            Login to your Pandoras Box account.
          </small>
          <form className="py-4 text-sm" onSubmit={handleSubmit(onSubmit)}>
            {hasServerError && (
              <small className="text-red-600">Login failed try again</small>
            )}
            <div className="my-4">
              <label className="flex flex-col text-gray-100">
                <span>Email</span>
                <input
                  {...register("username")}
                  autoComplete="email"
                  className="border-page-border bg-input-bg py-2 px-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
                  type="email"
                />
                <small className="text-red-600">
                  {errors.username?.message}
                </small>
              </label>
            </div>
            <div className="my-4">
              <label className="flex flex-col text-gray-100">
                <span>Password</span>
                <div className="h-11 rounded-md border border-page-border flex items-center bg-input-bg overflow-hidden  focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300">
                  <input
                    {...register("password")}
                    autoComplete="current-password"
                    className="h-full w-full pl-4 bg-transparent  border-r border-page-border border-0"
                    type={showPassword ? "text" : "password"}
                  />
                  <UilEye
                    onClick={handleShowPassword}
                    className="w-5 h-5 mx-4 cursor-pointer"
                  />
                </div>
                <small className="text-red-600">
                  {errors.password?.message}
                </small>
                <Link to="/resetpassword">
                  <small className="flex justify-end text-gray-50 leading-4 pt-2 cursor-pointer">
                    Forgot password?
                  </small>
                </Link>
              </label>
            </div>
            <div className="my-8">
              <button className="order-1 w-full py-2.5 px-4 rounded-md bg-button-bg-primary-default hover:bg-button-bg-primary-hover active:shadow-button_primary">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
