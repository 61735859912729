import { useEffect, useRef, useState } from "react";
import { AddUserModal, DeleteUserModal, LogoutModal } from "../modal";
import { useRecoilState } from "recoil";
import { ModalTypeState } from "../../atoms";
import { UilRedo, UilSearch } from "@iconscout/react-unicons";
import { GetNewInstance, Store } from "../../helpers";
import { UserInfoListDto, UsersClient } from "../../services";
import { UserListItem } from "./UserListItem";
import { UserFilterState } from "../../atoms/UserFilterState";
import { UserFilter } from "../filters/UserFilter";
import { ArchiveUserModal } from "../modal/ArchiveUserModal";
import { ActivateUserModal } from "../modal/ActivateUserModal";
import { Pagination } from "../layouts";

export default function UserList() {
  const client = new UsersClient(undefined, GetNewInstance());
  const [users, setUsers] = useState<UserInfoListDto[]>([]);
  const [, setModalType] = useRecoilState(ModalTypeState);
  const [userFilterState, setUserFilterState] = useRecoilState(UserFilterState);
  const [totalPageNumber, setTotalPageNumber] = useState(1);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const usersCustomerId = Store.getCustomerId();
  let timeOutId = useRef<NodeJS.Timeout>();

  useEffect(() => {
    retrieveUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFilterState, searchTerm, currentPageNumber]);

  useEffect(() => {
    setCurrentPageNumber(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retrieveUsers = () => {
    clearTimeout(timeOutId.current);
    timeOutId.current = setTimeout(() => {
      client
        .getUsers(
          usersCustomerId ?? "",
          userFilterState,
          searchTerm,
          currentPageNumber
        )
        .then((response) => {
          response?.data && setUsers(response?.data);
          response?.pageNumber && setCurrentPageNumber(response?.pageNumber);
          response?.pageCount && setTotalPageNumber(response?.pageCount);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 300);
  };

  const onAddUserSuccess = () => {
    retrieveUsers();
    setModalType(null);
  };

  const onAddUserFailure = () => {
    setModalType(null);
  };

  return (
    <>
      <div className="h-full overflow-auto p-5 ">
        <div className="flex justify-between mb-5">
          <div>
            <div className="h-11 rounded-md border border-page-border flex items-center bg-white bg-opacity-5 overflow-hidden">
              <UilSearch className="w-5 h-5 mx-4" />
              <input
                className="h-full bg-transparent border-0"
                type="text"
                placeholder="Search for user"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm ?? ""}
              />
            </div>
          </div>
          <div className="flex flex-row items-center gap-3">
            {((userFilterState !== null && userFilterState !== undefined) ||
              (searchTerm !== null && searchTerm !== "")) && (
              <UilRedo
                className="text-gray-100 cursor-pointer"
                onClick={() => {
                  setUserFilterState(null);
                  setSearchTerm("");
                }}
              />
            )}
            <UserFilter />
          </div>
        </div>
        <div className="border-b rounded-lg border-page-border">
          <table className="w-full text-sm">
            <thead>
              <tr className="border-b border-page-border">
                <td className="px-4 py-3">First name</td>
                <td className="px-4 py-3">Last name</td>
                <td className="px-4 py-3">Email</td>
                <td className="px-4 py-3">Role</td>
                <td className="px-4 py-3">Date added</td>
                <td className="px-4 py-3">Status</td>
                <td className="px-4 py-3"></td>
              </tr>
            </thead>
            <tbody>
              {users.map((x) => (
                <UserListItem key={x.email} User={x} />
              ))}
            </tbody>
          </table>
          <Pagination
            totalPageCount={totalPageNumber}
            currentPageNumber={currentPageNumber}
            setCurrentPageNumber={setCurrentPageNumber}
          />
        </div>
      </div>
      <AddUserModal onSuccess={onAddUserSuccess} onFailure={onAddUserFailure} />
      <ArchiveUserModal onSuccess={retrieveUsers} />
      <ActivateUserModal onSuccess={retrieveUsers} />
      <DeleteUserModal onSuccess={retrieveUsers} />
      <LogoutModal />
    </>
  );
}
