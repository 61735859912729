import { AssetDetail } from "../components/mainContent/AssetDetail";
import { CustomerSidebar } from "../components/sidebar";
import AssetDetailHeader from "../components/layouts/AssetDetailHeader";
import { LogoutModal } from "../components/modal";

export function AssetDetailPage() {

    return (
        <div className="flex">
            <div className="h-screen w-full flex bg-bg text-white">
                <CustomerSidebar />
                <div className="h-screen w-full flex flex-col">
                    <AssetDetailHeader />
                    <AssetDetail />
                </div>
            </div>
            <LogoutModal />
        </div>
    );
}