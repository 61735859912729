type Props = {
  className?: string;
};

export function UploadFile({ className }: Props) {
  return (
    <svg
      className="w-6 h-6"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.95447 0.239647V4.42899C8.95447 6.08584 10.2976 7.42899 11.9545 7.42899H16.2491C16.3358 7.42899 16.4196 7.4217 16.5 7.40785V16C16.5 18.2091 14.7091 20 12.5 20H4.5C2.29086 20 0.5 18.2091 0.5 16V4C0.5 1.79086 2.29086 0 4.5 0H8.97376C8.96111 0.0769835 8.95447 0.156953 8.95447 0.239647ZM10.0268 0.00816131C9.98184 0.0713582 9.95447 0.149884 9.95447 0.239647V0.646922V0.686937V0.687776V4.42899C9.95447 5.53356 10.8499 6.42899 11.9545 6.42899H15.8001H15.8017H15.8562H16.2491C16.3061 6.42899 16.3586 6.41796 16.4055 6.39839C16.3616 6.30461 16.3029 6.21744 16.2311 6.14042L13.8333 3.57143L10.612 0.298531C10.4541 0.138107 10.2477 0.0365526 10.0268 0.00816131Z"
        fill="#0971CB"
      />
      <g clipPath="url(#clip0_496_5390)">
        <path
          d="M8.07141 15.5359V10.1787"
          stroke="#2E363D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.9881 12.262L8.07143 10.1787L10.1548 12.262"
          stroke="#2E363D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_496_5390">
          <rect
            width="7.14286"
            height="7.14286"
            fill="white"
            transform="translate(4.5 9.28613)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
