import { useState } from "react";
import { useRecoilState } from "recoil";
import { CreateUserDto, UserRole, UsersClient } from "../../services";
import { UilPlus, UilAngleDown, UilAngleUp } from "@iconscout/react-unicons";
import { GetNewInstance, Store } from "../../helpers";
import { NewUserSelectedIndex } from "../../atoms/NewUserSelectedIndex";
import { Trash2 } from "react-feather";
import { SpinnerButton } from "../buttons";
import { toast } from "react-toastify";

type Props = {
  onSuccess: () => void;
  onFailure: () => void;
};

type AddUsers = {
  index: number;
  email: string;
  role: UserRole;
  emailIsValid: boolean;
};

const emailRegex: RegExp =
  /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

export function AddUserForm({ onSuccess, onFailure }: Props) {
  const [newUsers, setNewUsers] = useState<AddUsers[]>([
    { index: 0, email: "", role: UserRole.ViewOnly, emailIsValid: true },
  ]);
  const [showSpinner, setShowSpinner] = useState(false);

  const client = new UsersClient(undefined, GetNewInstance());
  const usersCustomerId = Store.getCustomerId();

  const handleInviteUSers = () => {
    if (newUsers.filter((x) => !x.emailIsValid).length > 0) {
      return;
    }
    const request: CreateUserDto[] = newUsers.map(
      (x) => new CreateUserDto({ email: x.email, role: x.role })
    );
    usersCustomerId &&
      client
        .createUser(usersCustomerId, request)
        .then(() => {
          toast.success("User invited successfully");
          onSuccess();
        })
        .catch((x) => {
          onFailure();
        })
        .finally(() => {
          setShowSpinner(false);
        });
    setShowSpinner(true);
  };

  const handleAddUser = () => {
    const newIndex = newUsers
      .slice(newUsers.length - 1, newUsers.length)
      .pop()?.index;
    newIndex !== undefined &&
      setNewUsers([
        ...newUsers,
        {
          index: newIndex + 1,
          email: "",
          role: UserRole.ViewOnly,
          emailIsValid: true,
        },
      ]);
  };

  const handleRemoveUser = (index: number) => {
    if (index !== 0) {
      const updatedUsers = [...newUsers.filter((x) => x.index !== index)];
      setNewUsers(updatedUsers);
    }
  };

  const handleEmailChange = (index: number, email: string) => {
    const emailIsValid = emailRegex.test(email);

    const updatedUsers = [...newUsers];
    updatedUsers.splice(index, 1, {
      email: email,
      index: index,
      role: newUsers[index].role,
      emailIsValid: emailIsValid,
    });
    setNewUsers(updatedUsers);
  };

  const handleRoleChange = (index: number, userRole: UserRole) => {
    const updatedUsers = [...newUsers];
    updatedUsers.splice(index, 1, {
      email: newUsers[index].email,
      index: index,
      role: userRole,
      emailIsValid: newUsers[index].emailIsValid,
    });
    setNewUsers(updatedUsers);
  };

  return (
    <>
      <div className="my-1 flex gap-4 text-white items-center pt-2">
        <label className="flex flex-col w-2/3">
          <span>Email</span>
        </label>
        <label className="flex flex-col items-start w-1/3">
          <span>Role</span>
        </label>
        <Trash2 className="cursor-pointer text-button-bg-destructive-default invisible" />
      </div>
      {newUsers.map((x, i) => (
        <NewUser
          email={x.email}
          index={x.index}
          role={x.role}
          handleEmailChange={handleEmailChange}
          handleRoleSelectionChange={handleRoleChange}
          handleRemoveUser={handleRemoveUser}
          emailIsValid={x.emailIsValid}
        />
      ))}
      <div
        onClick={handleAddUser}
        className="text-gray-300 flex flex-row cursor-pointer"
      >
        <UilPlus className="w-4" />
        <span>Add another user</span>
      </div>
      <div className="mt-8 flex justify-end gap-4">
        <SpinnerButton
          className={`py-2.5 px-4 w-full rounded-md bg-button-bg-primary-default hover:bg-button-bg-primary-hover active:shadow-button_primary text-white ${
            showSpinner ? "disabled" : ""
          } `}
          showSpinner={showSpinner}
          onClick={handleInviteUSers}
        >
          Send Invite
        </SpinnerButton>
      </div>
    </>
  );
}

type NewUserProp = {
  index: number;
  email: string;
  role: UserRole;
  handleEmailChange: (index: number, email: string) => void;
  handleRoleSelectionChange: (index: number, userRole: UserRole) => void;
  handleRemoveUser: (index: number) => void;
  emailIsValid: boolean;
};

function NewUser({
  email,
  role,
  index,
  handleEmailChange,
  handleRoleSelectionChange,
  handleRemoveUser,
  emailIsValid,
}: NewUserProp) {
  const handleOnEmailChanged = (e: any) => {
    handleEmailChange(index, e.target.value);
  };

  const handleRemoveClick = () => {
    handleRemoveUser(index);
  };

  return (
    <div className="my-1 flex gap-4 text-white items-top">
      <label className="flex flex-col w-2/3">
        <input
          value={email}
          onChange={handleOnEmailChanged}
          className="border-page-border bg-white bg-opacity-5 py-2 px-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500"
          type="text"
        />
        {!emailIsValid && (
          <small className="text-button-bg-destructive-default ">
            Email is invalid, please use a valid email address
          </small>
        )}
      </label>
      <RoleDropdown
        className="flex flex-col w-1/3 py-2"
        handleSelection={handleRoleSelectionChange}
        index={index}
        selectedUserRole={role}
      />
      <Trash2
        onClick={handleRemoveClick}
        className={`cursor-pointer text-button-bg-destructive-default mt-4 ${
          index !== 0 ? "visible" : "invisible"
        }`}
      />
    </div>
  );
}

type RoleDropDownProps = {
  handleSelection: (index: number, userRole: UserRole) => void;
  selectedUserRole: UserRole;
  index: number;
  className: string;
};

function RoleDropdown({
  className,
  index,
  selectedUserRole,
  handleSelection,
}: RoleDropDownProps) {
  const [selectedUserIndex, setSelectedUserIndex] =
    useRecoilState(NewUserSelectedIndex);

  const handleOnRoleClick = () => {
    selectedUserIndex === index
      ? setSelectedUserIndex(null)
      : setSelectedUserIndex(index);
  };

  const onSelected = (e: any) => {
    let selectedRole = e.target.innerText;
    selectedRole = selectedRole === "View only" ? "ViewOnly" : selectedRole;
    const newRole: keyof typeof UserRole = selectedRole;
    handleSelection(index, UserRole[newRole]);
    setSelectedUserIndex(null);
  };

  return (
    <div className={`relative text-sm ${className}`}>
      <button
        onClick={handleOnRoleClick}
        className="h-11 gap-2 px-4 rounded-md border border-page-border flex justify-between items-center bg-white bg-opacity-5 overflow-hidden"
      >
        <span>
          {selectedUserRole === UserRole.ViewOnly
            ? "View only"
            : selectedUserRole}
        </span>
        {selectedUserIndex === index ? (
          <UilAngleUp className="items-end" />
        ) : (
          <UilAngleDown className="items-end" />
        )}
      </button>
      {selectedUserIndex === index && (
        <div
          className={`absolute w-full z-10 top-12 right-0 rounded-md border border-page-border bg-dropdown min-w-max cursor-pointer `}
        >
          <ul className=" max-h-64 overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
            <li
              onClick={onSelected}
              className={` px-4 rounded-md hover:bg-button-bg-primary-hover ${
                selectedUserRole === UserRole.Admin
                  ? " bg-white bg-opacity-10 border-l-blue-500 border-l-4"
                  : ""
              }`}
            >
              <label
                key={UserRole.Admin}
                className="flex items-center gap-4 py-2.5  cursor-pointer"
              >
                <span>Admin</span>
              </label>
            </li>
            <li
              onClick={onSelected}
              className={` px-4 rounded-md hover:bg-button-bg-primary-hover ${
                selectedUserRole === UserRole.Editor
                  ? "  bg-white bg-opacity-10 border-l-blue-500 border-l-4"
                  : ""
              }`}
            >
              <label
                key={UserRole.Editor}
                className="flex items-center gap-4 py-2.5  cursor-pointer"
              >
                <span>Editor</span>
              </label>
            </li>
            <li
              onClick={onSelected}
              className={` px-4 rounded-md hover:bg-button-bg-primary-hover ${
                selectedUserRole === UserRole.ViewOnly
                  ? "  bg-white bg-opacity-10 border-l-blue-500 border-l-4"
                  : ""
              }`}
            >
              <label
                key={UserRole.ViewOnly}
                className="flex items-center gap-4 py-2.5  cursor-pointer"
              >
                <span>View only</span>
              </label>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
