import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { ConnectedDeviceListItem } from ".";
import { NewConnectedDeviceState } from "../../atoms";
import { GetNewInstance, Store } from "../../helpers";
import { ConnectedDeviceListDto, ConnectedDevicesClient } from "../../services";
import { Pagination } from "../layouts";

export function ConnectedDeviceList() {
  const [connectedDevices, setConnectedDevices] = useState<
    ConnectedDeviceListDto[]
  >([]);

  const [totalPageNumber, setTotalPageNumber] = useState(1);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [newConnectedDeviceState, setNewConnectedDeviceState] = useRecoilState(
    NewConnectedDeviceState
  );

  const usersCustomerId = Store.getCustomerId();
  const client = new ConnectedDevicesClient(undefined, GetNewInstance());

  useEffect(() => {
    retrieveConnectedDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber]);

  useEffect(() => {
    setCurrentPageNumber(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (newConnectedDeviceState) retrieveConnectedDevices();
    setNewConnectedDeviceState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newConnectedDeviceState]);

  const retrieveConnectedDevices = () => {
    usersCustomerId &&
      client
        .getConnectedDevices(usersCustomerId, currentPageNumber)
        .then((response) => {
          response && response.data && setConnectedDevices(response?.data);
          response &&
            response.pageNumber &&
            setCurrentPageNumber(response?.pageNumber);
          response &&
            response.pageCount &&
            setTotalPageNumber(response?.pageCount);
        })
        .catch((error) => {
          console.log(error);
        });
  };

  return (
    <>
      <div className="h-full overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent text-body_14">
        <div className="border-0 rounded-lg border-page-border">
          <table className="w-full text-sm">
            <thead className="text-gray-300">
              <tr className="border-b border-page-border">
                <td className="px-4 py-3">Name</td>
                <td className="px-4 py-3">IP address</td>
                <td className="px-4 py-3">Registration Code</td>
                <td className="px-4 py-3">Status</td>
                <td className="px-4 py-3">Last connection</td>
              </tr>
            </thead>
            <tbody className="text-gray-100 ">
              {connectedDevices.map((x) => (
                <ConnectedDeviceListItem
                  key={x.id}
                  connectedDevice={x}
                  handleDeletion={retrieveConnectedDevices}
                />
              ))}
            </tbody>
          </table>
          <Pagination
            totalPageCount={totalPageNumber}
            currentPageNumber={currentPageNumber}
            setCurrentPageNumber={setCurrentPageNumber}
          />
        </div>
      </div>
    </>
  );
}
