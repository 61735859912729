export enum TypographyType {
    H1,
    H1_5,
    H2,
    H3,
    H4,
    H5,
    H6,
    Pretitle,
    Button,
    Body_16,
    Body_14,
    Caption
}

type Props = {
    type: TypographyType,
    children: string,
};

export function AppText({ type, children }: Props) {
    switch (type) {
        case TypographyType.H1:
            return <p className="text-white font-medium font-avenir text-h1 not-italic">{children}</p>
        case TypographyType.H1_5:
            return <p className="text-white font-medium font-avenir text-h1_5 not-italic">{children}</p>
        case TypographyType.H2:
            return <p className="text-white font-medium font-avenir text-h2 not-italic">{children}</p>
        case TypographyType.H3:
            return <p className="text-white font-medium font-avenir text-h3 not-italic">{children}</p>
        case TypographyType.H4:
            return <p className="text-white font-medium font-avenir text-h4 not-italic">{children}</p>
        case TypographyType.H5:
            return <p className="text-white font-medium font-avenir text-h5 not-italic">{children}</p>
        case TypographyType.H6:
            return <p className="text-white font-medium font-avenir text-h6 not-italic">{children}</p>
        case TypographyType.Pretitle:
            return <p className="text-white font-medium font-avenir text-pretitle not-italic">{children}</p>
        case TypographyType.Button:
            return <p className="text-white font-medium font-avenir text-button not-italic">{children}</p>
        case TypographyType.Body_16:
            return <p className="text-white font-medium font-avenir text-body_16 not-italic">{children}</p>
        case TypographyType.Body_14:
            return <p className="text-white font-medium font-avenir text-body_14 not-italic">{children}</p>
        default:
            return <p className="text-white">{children}</p>
    }
}