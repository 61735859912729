import { UserInfoListDto } from "../../services";
import { RoleCheck } from "./RoleCheck";

type props = {
  user: UserInfoListDto | null;
  children: JSX.Element;
};

export function RequireAdminRoleMinimum({ user, children }: props) {
  return <>{RoleCheck.isAdminRoleMinimum(user) ? children : <></>}</>;
}
