type Props = {
    className?: string
}

export function HeaderPolygon({ className }: Props) {
    return (
        <div className={`${className}`}>
            <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.12713 3.49407C5.58759 3.89284 5.58759 4.60716 5.12713 5.00593L2.40465 7.36366C1.75701 7.92454 0.75 7.46448 0.75 6.60773L0.75 1.89227C0.75 1.03552 1.75701 0.575463 2.40465 1.13634L5.12713 3.49407Z" fill="#F0F6FC" />
            </svg>
        </div>
    );
}