import { useState } from "react";
import { Plus } from "react-feather";
import { useRecoilState } from "recoil";
import { CanvasList, ConnectedDeviceList, WindowsVersionList } from ".";
import { ModalTypeState, NewConnectedDeviceState } from "../../atoms";
import { GetNewInstance, Store } from "../../helpers";
import {
  ConnectedDevicesClient,
  CreateConnectedDeviceDto,
} from "../../services";
import { AppButton, IconStyle } from "../buttons";
import { ModalTypes } from "../modal";

export function SettingsContent() {
  const [, setModalType] = useRecoilState(ModalTypeState);
  const [, setNewConnectedDeviceState] = useRecoilState(
    NewConnectedDeviceState
  );
  const [selectedSettings, setSelectedSettings] = useState<
    "Manager" | "Canvases" | "Connected devices" | "Windows Versions"
  >("Canvases");

  const usersCustomerId = Store.getCustomerId();

  const handleAddCanvasClick = () => {
    setModalType(ModalTypes.AddCanvasModal);
  };

  const handleAddConnectedDevicesClick = () => {
    var client = new ConnectedDevicesClient(undefined, GetNewInstance());

    usersCustomerId &&
      client
        .createConnectedDevice(
          usersCustomerId,
          new CreateConnectedDeviceDto({
            customerId: usersCustomerId,
            registerationCode: "",
          })
        )
        .then((x) => {
          setNewConnectedDeviceState(true);
        });
  };

  return (
    <>
      <div className="h-full overflow-hidden p-5">
        <div className="flex justify-between border-b mb-10 border-page-border items-center">
          <div className="flex gap-5 text-gray-300">
            <span
              className={`flex items-center h-16  ${
                selectedSettings === "Canvases"
                  ? "border-b border-button-bg-primary-default text-button-bg-primary-default"
                  : " cursor-pointer"
              }`}
              onClick={() => setSelectedSettings("Canvases")}
            >
              Canvases
            </span>
            <span
              className={`flex items-center h-16  ${
                selectedSettings === "Connected devices"
                  ? "border-b border-button-bg-primary-default text-button-bg-primary-default"
                  : " cursor-pointer"
              }`}
              onClick={() => setSelectedSettings("Connected devices")}
            >
              Connected devices
            </span>
            <span
              className={`flex items-center h-16  ${
                selectedSettings === "Windows Versions"
                  ? "border-b border-button-bg-primary-default text-button-bg-primary-default"
                  : " cursor-pointer"
              }`}
              onClick={() => setSelectedSettings("Windows Versions")}
            >
              Windows Versions
            </span>
          </div>
          <div>
            {selectedSettings === "Canvases" ? (
              <AppButton
                onClick={handleAddCanvasClick}
                className="px-3 py-5 h-10 "
                icon_style={IconStyle.Left}
                icon={<Plus />}
              >
                Add canvas
              </AppButton>
            ) : (
              <></>
            )}

            {selectedSettings === "Connected devices" ? (
              <AppButton
                onClick={handleAddConnectedDevicesClick}
                className="px-3 py-5 h-10 "
                icon_style={IconStyle.Left}
                icon={<Plus />}
              >
                Add connected devices
              </AppButton>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="h-full">
          {selectedSettings === "Canvases" ? <CanvasList /> : <></>}
          {selectedSettings === "Connected devices" ? (
            <ConnectedDeviceList />
          ) : (
            <></>
          )}
          {selectedSettings === "Windows Versions" ? (
            <WindowsVersionList />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
