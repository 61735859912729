import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DataItem } from "vis-timeline";
import { ScheduleListDto } from "../../services";
import { Store } from "../../helpers";

type Props = {
    data: DataItem,
    schedule: ScheduleListDto
}

export default function ScheduleTimelineTooltipItem({ data, schedule }: Props) {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const currentCustomer = Store.getCurrentCustomer();

    return (
        // "border-radius: 5px; width: 11px; color:red;"
        <div style={{ backgroundColor: 'red' }} >
            <p><strong>{schedule?.name ?? ""}</strong></p>
            <p>Schedule: </p>
            <p>{dayjs(schedule.startDate).tz(currentCustomer?.timeZoneIANA).format('DD, MMM YYYY')}</p>
            <p>{dayjs(schedule.startDate).tz(currentCustomer?.timeZoneIANA).format('hh:mma')} to {dayjs(schedule.endDate).tz(currentCustomer?.timeZoneIANA).format('hh:mma')}</p>
        </div>
    );
}