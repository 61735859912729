import { UilEllipsisV, UilFileAlt } from "@iconscout/react-unicons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../constants/ApiConstans";
import { AssetsClient, IAssetListDto, UserInfoListDto } from "../../services";
import { Download, Image, Trash2, Video } from "react-feather";
import { useRecoilState } from "recoil";
import { AssetDetailState } from "../../atoms/AssetDetailState";
import { TimeDuration } from "../Misc";
import { GetNewInstance, Store } from "../../helpers";
import { ModalTypeState, SelectedAssetState } from "../../atoms";
import { ModalTypes } from "../modal";
import { RequireEditorRoleMinimum } from "../auth";

type Props = {
  asset: IAssetListDto;
  onAssetDeletion: () => void;
  loggedInUser: UserInfoListDto | null;
};

export function AssetListItem({ asset, onAssetDeletion, loggedInUser }: Props) {
  const {
    id,
    durationInSecounds,
    name,
    type,
    thumbnailImage,
    width,
    height,
    resolution,
    aspectRatio,
    fileExtension,
  } = { ...asset };
  const [, setSelectedAsset] = useRecoilState(SelectedAssetState);
  const [assetDetailState, setAssetDetailState] =
    useRecoilState(AssetDetailState);
  const [, setModalType] = useRecoilState(ModalTypeState);

  const [showAssetOption, setShowAssetOption] = useState(false);

  const navigate = useNavigate();
  const usersCustomerId = Store.getCustomerId();
  let thumbnailLink =
    baseURL +
    "/api/customer/" +
    usersCustomerId +
    "/Assets/" +
    id +
    "/thumbnail/" +
    thumbnailImage;

  let timeOutId: NodeJS.Timeout;
  const assetClient = new AssetsClient(undefined, GetNewInstance());

  function downloadAsset(customerId: string, assetId: string) {
    assetClient.getDownloadAssetDataUrl(customerId, assetId).then((x) => {
      if (x) {
        const link = document.createElement("a");
        link.href = x;
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  }

  const hideAssetOptions = () => {
    setShowAssetOption(false);
  };

  const handleShowAssetClicked = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setShowAssetOption(!showAssetOption);
  };

  const assetDetailClicked = () => {
    setAssetDetailState({
      assetId: id,
      assetName: name,
      assetIds: assetDetailState ? assetDetailState.assetIds : [],
    });
    navigate("/asset/details");
  };

  const handleDeleteAsset = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    asset.id && setSelectedAsset(asset.id);
    setModalType(ModalTypes.DeleteAssetModal);
  };
  const handleAssetLog = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setAssetDetailState({
      assetId: id,
      assetName: name,
      assetIds: assetDetailState ? assetDetailState.assetIds : [],
    });
    setModalType(ModalTypes.AssetAuditLogModal);
  };

  const handleOnBlur = () => {
    timeOutId = setTimeout(() => {
      setShowAssetOption(false);
    }, 200);
  };

  const handleOnFocus = () => {
    clearTimeout(timeOutId);
  };

  return (
    <div
      key={id}
      tabIndex={1}
      className="asset_preview"
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
    >
      <div
        className={`bg-cover bg-center w-60 h-40 relative cursor-pointer`}
        onClick={assetDetailClicked}
      >
        <img
          className="rounded-md w-60 h-40 object-cover"
          src={` ${thumbnailLink}`}
          alt="asset thumbnail"
          onError={(e: any) => {
            e.target.src =
              type === "Image"
                ? require("../../assets/Image_Placeholder.png")
                : require("../../assets/Video_Placeholder.png");
          }}
        />
        {type !== "Image" && (
          <span className="absolute bottom-4 right-4 rounded bg-black px-2 py-0.5">
            <TimeDuration
              nameId={"timeduration_assetList" + id}
              totalSecounds={durationInSecounds}
            />
          </span>
        )}

        <RequireEditorRoleMinimum user={loggedInUser}>
          <>
            <div
              onClick={handleShowAssetClicked}
              className="asset absolute items-center  cursor-pointer right-1 top-1 w-7 h-7 z-10 bg-black bg-opacity-60 rounded-full flex"
            >
              <div className="flex justify-center  place-self-center">
                <UilEllipsisV className="w-5 h-5 m-0  place-self-center mt-1" />
              </div>
            </div>
            {showAssetOption && (
              <ul
                onBlur={hideAssetOptions}
                className=" focus text-sm rounded-lg bg-gray-800 absolute top-7 right-4"
              >
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <span
                    className="flex items-center gap-3 cursor-pointer hover:opacity-60"
                    onClick={() =>
                      downloadAsset(asset.customerId ?? "", asset.id ?? "")
                    }
                  >
                    <div className="flex items-center rounded-md gap-2 p-2.5  cursor-pointer hover:bg-button-bg-primary-hover">
                      <Download className="w-5 text-gray-200" />
                      <span>Download asset</span>
                    </div>
                  </span>
                </li>
                <li>
                  <div
                    className="flex items-center rounded-md gap-2 p-2.5  cursor-pointer hover:bg-button-bg-primary-hover"
                    onClick={handleAssetLog}
                  >
                    <UilFileAlt className="w-5 text-gray-200" />
                    <span>View log</span>
                  </div>
                </li>
                <li>
                  <div
                    className="flex items-center rounded-md gap-2 p-2.5  text-button-bg-destructive-default cursor-pointer hover:bg-button-bg-primary-hover"
                    onClick={handleDeleteAsset}
                  >
                    <Trash2 className="w-5" />
                    <span>Delete asset</span>
                  </div>
                </li>
              </ul>
            )}
          </>
        </RequireEditorRoleMinimum>
      </div>

      <div className="py-3" onClick={assetDetailClicked}>
        <p
          className="w-60 text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer"
          title={name}
        >
          {name}
        </p>
      </div>
      <div className="flex items-center gap-2 text-xs opacity-50">
        <span className="flex items-center gap-2">
          {type === "Image" ? (
            <Image className="w-4 h-4" />
          ) : (
            <Video className="w-4 h-4" />
          )}
          {fileExtension}
        </span>
        <small className="w-1 h-1 bg-white rounded-full "></small>

        {type !== "Image" ? (
          <span>{aspectRatio}</span>
        ) : (
          <span>{width + " x " + height} </span>
        )}
        {type !== "Image" && (
          <>
            <small className="w-1 h-1 bg-white rounded-full "></small>
            <span>{resolution}p</span>
          </>
        )}
      </div>
    </div>
  );
}
