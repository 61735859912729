import { AppButton, ButtonType } from "../buttons";
import { useRecoilState } from "recoil";
import { ShowAssetUploadState } from "../../atoms";
import { RequireEditorRoleMinimum } from "../auth";
import { Store } from "../../helpers";

export default function AssetListHeader() {
  const [showAssetUpload, setShowAssetUpload] =
    useRecoilState(ShowAssetUploadState);

  const onHeaderButtonClick = () => {
    setShowAssetUpload(!showAssetUpload);
  };

  const loggedInUser = Store.getLoggedInUser();

  return (
    <div className="h-17 flex-none flex justify-between items-center p-5 border-b border-page-border">
      <div>
        <div className="font-medium text-h3">Assets</div>
      </div>

      <RequireEditorRoleMinimum user={loggedInUser}>
        <div>
          <AppButton
            onClick={() => {
              onHeaderButtonClick();
            }}
            button_type={ButtonType.Primary}
          >
            Upload assets
          </AppButton>
        </div>
      </RequireEditorRoleMinimum>
    </div>
  );
}
