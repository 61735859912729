import { UilAngleDown, UilAngleUp } from "@iconscout/react-unicons";
import { useState } from "react";
import { RecurringType } from "../../services";

type Props = {
  value?: RecurringType;
  onChange: (updated: RecurringType) => void;
};

export function RepeatSelectionDropDown({ value, onChange }: Props) {
  const [showRepeatOption, setShowRepeatOption] = useState<boolean>(false);
  const [selectedRepeat, setSelectedRepeat] = useState<RecurringType>(
    value ?? RecurringType.Daily
  );

  let timeOutId: NodeJS.Timeout;

  const handleSelection = (value: RecurringType) => {
    setSelectedRepeat(value);
    onChange(value);
  };

  const handleShowOptions = () => {
    setShowRepeatOption(!showRepeatOption);
  };

  const handleOnBlur = () => {
    timeOutId = setTimeout(() => {
      setShowRepeatOption(false);
    }, 100);
  };

  const handleOnFocus = () => {
    clearTimeout(timeOutId);
  };

  return (
    <div
      className="relative text-sm w-full"
      tabIndex={1}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
    >
      <button
        onClick={(event) => {
          event?.preventDefault();
          handleShowOptions();
        }}
        className="h-11 w-full gap-2 px-4 rounded-md border border-page-border flex items-center bg-white bg-opacity-5 overflow-hidden"
      >
        <span className="w-full text-start">{selectedRepeat}</span>
        {showRepeatOption ? (
          <UilAngleUp className={`items-end `} />
        ) : (
          <UilAngleDown className={`items-end `} />
        )}
      </button>
      {showRepeatOption && (
        <div
          className={`absolute w-full top-12 right-0 rounded-md border border-page-border bg-dropdown  cursor-pointer ${
            showRepeatOption ? "visible" : "invisible"
          }`}
        >
          <ul className=" max-h-64 overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
            <li
              onClick={() => handleSelection(RecurringType.Daily)}
              className={` px-4 rounded-md hover:bg-button-bg-primary-hover ${
                selectedRepeat === "Daily"
                  ? " bg-white bg-opacity-10 border-l-blue-500 border-l-4"
                  : ""
              }`}
            >
              <label className="flex items-center gap-4 py-2.5  cursor-pointer">
                <span>Daily</span>
              </label>
            </li>
            <li
              onClick={() => handleSelection(RecurringType.Weekly)}
              className={` px-4 rounded-md hover:bg-button-bg-primary-hover ${
                selectedRepeat === "Weekly"
                  ? "  bg-white bg-opacity-10 border-l-blue-500 border-l-4"
                  : ""
              }`}
            >
              <label className="flex items-center gap-4 py-2.5  cursor-pointer">
                <span>Weekly</span>
              </label>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
