import { AppButton, ButtonType, IconStyle } from "../buttons";
import { useRecoilState } from "recoil";
import { ModalTypeState } from "../../atoms";
import { ModalTypes } from "../modal";

type Props = {
  title: string;
  showButton: boolean;
  buttonText?: string;
  icon_style?: IconStyle;
  icon?: JSX.Element;
};

export default function MainHeader({
  title,
  showButton,
  buttonText,
  icon,
  icon_style,
}: Props) {
  const [modalType, setModalType] = useRecoilState(ModalTypeState);

  const onHeaderButtonClick = () => {
    modalType === null || modalType === undefined
      ? setModalType(ModalTypes.HeaderModal)
      : setModalType(null);
  };

  return (
    <div className="h-17 flex-none flex justify-between items-center p-5 border-b border-page-border">
      <div className="font-medium text-h3">{title}</div>
      {showButton && (
        <div>
          <AppButton
            onClick={onHeaderButtonClick}
            button_type={ButtonType.Primary}
            icon_style={icon_style}
            icon={icon}
          >
            {buttonText ?? ""}
          </AppButton>
        </div>
      )}
    </div>
  );
}
