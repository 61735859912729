import { AssetUpload } from "../form";
import { ShowAssetUploadState } from "../../atoms";
import ReactDOM from "react-dom";
import { useRecoilState } from "recoil";
import { X } from "react-feather";

type Props = {
  onSuccess: () => void;
};

export function AssetUploadModal({ onSuccess }: Props) {
  const [showAssetUpload] = useRecoilState(ShowAssetUploadState);

  return ReactDOM.createPortal(
    <div className={`${showAssetUpload ? "visible" : "invisible"}`}>
      <div className="fixed w-full h-full top-0 left-0 z-20 bg-white bg-opacity-30 flex justify-center items-center overflow-auto py-4">
        <div
          className="w-full max-w-xl max-h-full"
          style={{ maxWidth: "48rem" }}
        >
          <div className="w-full p-5 bg-modal-bg  rounded-lg">
            <div className="flex justify-between items-start">
              <div className="flex flex-col gap-3">
                <h3 className="text-lg font-medium text-gray-50">
                  Upload assets
                </h3>
                <small className="font-normal text-gray-200">
                  Upload new asset
                </small>
              </div>
              <X
                className="text-gray-200 cursor-pointer rounded-full hover:text-gray-50 hover:bg-Secondary-Input-bg  w-6 h-6"
                onClick={onSuccess}
              ></X>
            </div>
            <AssetUpload onSuccess={onSuccess} />
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
}
