import { UilClock } from "@iconscout/react-unicons";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "./DateTimePicker.css";

type Props = {
  nameId?: string;
  show24: boolean;
  value?: Date;
  maxTime?: Date;
  onChange?: (value: Date) => void;
};

export function CustomTimePicker({
  nameId,
  value,
  onChange,
  show24,
  maxTime,
}: Props) {
  const [showCalander, setShowCalander] = useState(false);
  const [selectedTime, setSelectedTime] = useState<Date>(value ?? new Date());

  const handleOnChange = (value: Date) => {
    const newTime = new Date(value.toString());
    setSelectedTime(newTime);
    onChange && onChange(newTime);
  };

  return (
    <div
      key={nameId}
      onClick={() => setShowCalander(!showCalander)}
      className="h-11 rounded-md border border-page-border flex justify-center items-center bg-input-bg overflow-hidden  focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
    >
      <div
        key={nameId + "_div"}
        className="h-full w-full  bg-transparent mx-4 border-r border-page-border border-0 "
      >
        <DatePicker
          key={nameId + "_picker"}
          name={nameId}
          className="h-11 w-40 rounded-md flex justify-center border-0 items-center bg-input-bg"
          popperClassName="overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent"
          selected={selectedTime}
          onChange={(date) => date && handleOnChange(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          maxTime={maxTime ?? new Date(0, 0, 0, 23, 59, 59)}
          minTime={new Date(0, 0, 0, 0, 0, 1, 0)}
          timeCaption=""
          showPopperArrow={false}
          showTimeInput={false}
          timeFormat={show24 ? "HH:mm:ss" : "hh:mm:ss aa"}
          dateFormat={!show24 ? "hh:mm:ss aa" : "HH:mm:ss"}
        />
      </div>
      <UilClock
        onClick={() => setShowCalander(!showCalander)}
        className="text-gray-300 w-4 h-4 mr-4"
      />
    </div>
  );
}
