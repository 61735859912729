type Props = {
  children: React.ReactNode;
  text: string;
};

export function Tooltip({ children, text }: Props) {
  return (
    <div className="group relative">
      {children}
      <span className="absolute bottom-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
        {text}
      </span>
    </div>
  );
}
