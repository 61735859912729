import * as yup from "yup";

export interface LoginRequest {
  username: string;
  password: string;
}

export const loginRequestSchema = yup
  .object({
    username: yup.string().required("Name is required"),
    password: yup.string().required("Password is required"),
  })
  .required();
