import { atom } from "recoil";

type Props = {
  id: string;
  name: string;
};
export const SelectedPlaylistState = atom<Props | null>({
  key: "selectedPlaylistState ",
  default: null,
});
