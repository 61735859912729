import { atom } from "recoil";

type Props = {
  excludedPlaylistId?: string;
  fileTypes: string[];
  fileCodecs: string[];
  aspectRatios: string[];
  resolution: string[];
  tags: string[];
};

export const AssetFilterState = atom<Props | null>({
  key: "assetFilterState",
  default: {
    excludedPlaylistId: "",
    fileTypes: [],
    fileCodecs: [],
    aspectRatios: [],
    resolution: [],
    tags: [],
  },
});
