import { UilUsersAlt } from "@iconscout/react-unicons";
import { ProfileInfo } from "./ProfileInfo";
import { Link, useLocation } from "react-router-dom";

export function AdminSidebar() {
  let location = useLocation();

  return (
    <div className="h-screen w-72 flex flex-none flex-col border-r border-page-border">
      <div className="h-17 flex-none border-b border-page-border">
        <img
          src={require("../../assets/Pandoras_Box_Logo_RGB_negativ_SOLID 1.png")}
          alt="pandoras box logo"
          className="pt-3 pl-6 pb-3"
        />
      </div>
      <div className="h-full text-gray-300 overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
        <ul className="mt-7">
          <Link to="/">
            <li
              className={`flex items-center gap-3 py-4 pl-5 font-medium ${
                location.pathname === "/"
                  ? "bg-white bg-opacity-10 border-l-blue-500 border-l-4 text-white"
                  : ""
              }`}
            >
              <UilUsersAlt size="16.67px" color="#FFFFFF" />
              <p className="text-body_16">Customers</p>
            </li>
          </Link>
        </ul>
      </div>
      <ProfileInfo />
    </div>
  );
}
