import { useEffect, useState } from "react";
import { GetNewInstance } from "../../helpers/HttpCommon";
import {
  WindowsReleaseVersionDto,
  WindowsReleaseVersionClient,
} from "../../services";
import { Pagination } from "../layouts";
import { Store } from "../../helpers";
import { WindowsVersionListItem } from "./WindowsVersionListItem";
import { Spinner } from "../Misc";

export function WindowsVersionList() {
  const [windowsReleaseVersions, setWindowsReleaseVersions] = useState<
    WindowsReleaseVersionDto[]
  >([]);

  const [totalPageNumber, setTotalPageNumber] = useState(1);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const usersCustomerId = Store.getCustomerId();
  const client = new WindowsReleaseVersionClient(undefined, GetNewInstance());

  useEffect(() => {
    retrieveCanvases();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber]);

  useEffect(() => {
    setCurrentPageNumber(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retrieveCanvases = () => {
    if (usersCustomerId) {
      setIsLoading(true);
      client
        .getWindowsReleaseVersions(currentPageNumber)
        .then((response) => {
          response &&
            response.data &&
            setWindowsReleaseVersions(response?.data);
          response &&
            response.pageNumber &&
            setCurrentPageNumber(response?.pageNumber);
          response &&
            response.pageCount &&
            setTotalPageNumber(response?.pageCount);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="h-full flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="h-full overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent ">
          <div className="border-0 rounded-lg border-page-border text-body_14">
            <table className="w-full text-sm">
              <thead className="text-gray-300">
                <tr className="border-b border-page-border">
                  <td className="px-4 py-3">App Name</td>
                  <td className="px-4 py-3">Version Number</td>
                  <td className="px-4 py-3">Requirement</td>
                  <td className="px-4 py-3">Release Date</td>
                  <td className="px-4 py-3">Release Notes</td>
                  <td className="px-4 py-3">Download</td>
                </tr>
              </thead>
              <tbody className="text-gray-100 ">
                {windowsReleaseVersions.map((x) => (
                  <WindowsVersionListItem
                    key={x.version}
                    windowsReleaseVersion={x}
                  />
                ))}
              </tbody>
            </table>
            <Pagination
              totalPageCount={totalPageNumber}
              currentPageNumber={currentPageNumber}
              setCurrentPageNumber={setCurrentPageNumber}
            />
          </div>
        </div>
      )}
    </>
  );
}
