import { UilCalender } from "@iconscout/react-unicons";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "./DateTimePicker.css";

type Props = {
    value?: Date | null,
    onChange?: (value: Date | null) => void
};

export function CustomDateTimePicker({ value, onChange }: Props) {

    const [showCalander, setShowCalander] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(value);

    const handleOnChange = (value: Date | null) => {
        setSelectedDate(value);
        onChange && onChange(value);
    }

    return (
        <div onClick={() => setShowCalander(!showCalander)}
            className="h-11 rounded-md border border-page-border flex justify-center cursor-pointer items-center bg-input-bg overflow-hidden  focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300" >
            <div className="h-full w-full  bg-transparent mx-4 border-r border-page-border border-0 focus:border  ">
                <DatePicker
                    className="h-11 w-40 rounded-md flex justify-center cursor-pointer items-center bg-input-bg"
                    selected={selectedDate}
                    showPopperArrow={false}
                    showTimeSelect={true}
                    dateFormat={"MM/dd/yyyy hh:mm:ss aa"}
                    onChange={(date) => handleOnChange(date)}
                    calendarClassName="font-avenir rounded-md border-0 border-page-border flex justify-center"
                    placeholderText="None"
                    isClearable
                />
            </div>
            <UilCalender className="text-gray-300 w-4 h-4 mr-4" />
        </div>
    );
}