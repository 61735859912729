import { Circle } from "rc-progress";
import { useState } from "react";
import { X, CheckCircle } from "react-feather";
import { AssetUploadFile } from "../Svgs";
import { AssetDetailState, UploadState } from "../../atoms";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { UilRedo } from "@iconscout/react-unicons";

export function AssetUploadStatusItem({
  id,
  index,
  fileName,
  fileSize,
  isFinished,
  isQued,
  percentCompleted,
  handleCancel,
  handleResume,
}: UploadState) {
  const [isCanceled, setIsCanceled] = useState<boolean>(false);

  const navigate = useNavigate();
  const [assetDetailState, setAssetDetailState] =
    useRecoilState(AssetDetailState);

  const getHumanReadableFileSize = () => {
    const thresh = 1024;
    let currentFileSize = (fileSize * percentCompleted) / 100;

    if (Math.abs(currentFileSize) < thresh) {
      return currentFileSize + " B";
    }

    const units = currentFileSize
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** 2;

    do {
      currentFileSize /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(currentFileSize) * r) / r >= thresh &&
      u < units.length - 1
    );

    return currentFileSize.toFixed(3) + " " + units[u];
  };

  const handleCancelUpload = () => {
    handleCancel(index);
    setIsCanceled(true);
  };

  const handleGoToDetail = () => {
    setAssetDetailState({
      assetId: id ?? "",
      assetName: "",
      assetIds: assetDetailState ? assetDetailState.assetIds : [],
    });
    navigate("/asset/details");
  };

  const handleResumeUpload = () => {
    handleResume(index);
    setIsCanceled(false);
  };

  return (
    <li className="flex justify-between gap-2 items-center my-4 border rounded-md border-page-border p-4">
      <div className="flex items-center">
        <span className="w-10 h-10 flex justify-center items-center rounded-full bg-white bg-opacity-20">
          <AssetUploadFile className="w-6 h-6" />
        </span>
      </div>
      <div className="flex flex-grow items-center text-ellipsis overflow-hidden whitespace-nowrap">
        <p className=" ">
          {fileName} <br />
          {isCanceled && (
            <small className="text-text-status-danger">Canceled</small>
          )}
          {isQued && !isCanceled && (
            <small className="text-orange-300">Qued</small>
          )}
          {!isCanceled && !isQued && (
            <small className="opacity-50">
              {" "}
              {getHumanReadableFileSize()} - {percentCompleted.toFixed(3)}%
              uploaded
            </small>
          )}
        </p>
      </div>
      <div>
        {isCanceled && !isQued ? (
          <button
            onClick={handleResumeUpload}
            className="flex flex-row gap-1 items-center py-2.5 px-4 rounded-md border border-page-border bg-white bg-opacity-5 hover:bg-opacity-10 text-sm text-gray-100 cursor-pointer"
          >
            <UilRedo className="w-3 h-3" />
            Try again
          </button>
        ) : (
          <></>
        )}
        {!isCanceled && isFinished && !isQued && (
          <CheckCircle
            onClick={handleGoToDetail}
            className="w-6 h-6 text-Svg-success cursor-pointer"
          />
        )}
        {!isCanceled && !isFinished && !isQued && (
          <div className="flex justify-between">
            <Circle
              className="w-6 h-6"
              percent={percentCompleted}
              strokeWidth={10}
              strokeColor="#0767B9"
            />
            <X
              onClick={handleCancelUpload}
              className="w-6 h-6 cursor-pointer"
            />
          </div>
        )}
      </div>
    </li>
  );
}
