import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { ModalTypeState } from "../../atoms";
import { UilRedo, UilSearch } from "@iconscout/react-unicons";
import { GetNewInstance, Store } from "../../helpers";
import { PlaylistListDto, PlaylistsClient } from "../../services";
import { PlaylistListItem } from "./PlaylistListItem";
import { Pagination } from "../layouts";
import { AddPlaylistModal, DeletePlaylistModal } from "../modal";

export function PlaylistList() {
  const [playlists, setPlaylists] = useState<PlaylistListDto[]>([]);
  const [, setModalType] = useRecoilState(ModalTypeState);

  const [totalPageNumber, setTotalPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const usersCustomerId = Store.getCustomerId();
  const loggedInUser = Store.getLoggedInUser();
  const client = new PlaylistsClient(undefined, GetNewInstance());
  let timeOutId = useRef<NodeJS.Timeout>();

  useEffect(() => {
    retrievePlaylists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber]);

  useEffect(() => {
    setCurrentPageNumber(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentPageNumber(1);
    clearTimeout(timeOutId.current);
    timeOutId.current = setTimeout(() => {
      retrievePlaylists();
    }, 300);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const retrievePlaylists = () => {
    usersCustomerId &&
      client
        .getPlaylists(usersCustomerId, searchTerm ?? "", currentPageNumber)
        .then((response) => {
          if (
            currentPageNumber >
            (response?.pageCount === 0 ? 1 : response?.pageCount ?? 1)
          ) {
            setCurrentPageNumber(currentPageNumber - 1);
            retrievePlaylists();
          }

          response && response.data && setPlaylists(response?.data);
          response &&
            response.pageNumber &&
            setCurrentPageNumber(response?.pageNumber);
          response &&
            response.pageCount &&
            setTotalPageNumber(response?.pageCount);
        })
        .catch((error) => {
          console.log(error);
        });
  };

  const handleModalSuccess = () => {
    retrievePlaylists();
    setModalType(null);
  };

  const onAddPlaylistFailure = () => {
    setModalType(null);
  };

  return (
    <>
      <div className="h-full p-5 overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
        <div className="flex justify-between mb-5">
          <div className="flex flex-row items-center gap-3">
            <div className="h-11 rounded-md border border-page-border flex items-center bg-white bg-opacity-5 overflow-hidden">
              <UilSearch className="w-5 h-5 mx-4" />
              <input
                onChange={(e) => setSearchTerm(e.target.value)}
                className="h-full bg-transparent border-0"
                type="text"
                placeholder="Search for playlist"
                value={searchTerm}
              />
            </div>
            {searchTerm !== null &&
              searchTerm !== "" &&
              searchTerm !== undefined && (
                <UilRedo
                  className="text-gray-100 cursor-pointer"
                  onClick={() => setSearchTerm("")}
                />
              )}
          </div>
        </div>
        <div className="rounded-lg ">
          <table className="w-full text-sm">
            <thead className="border-b border-page-border text-gray-300">
              <tr className="">
                <td className="px-4 py-3 border-b border-page-border">
                  Playlist
                </td>
                <td className="px-4 py-3 border-b border-page-border">
                  Duration
                </td>
                <td className="px-4 py-3 border-b border-page-border">
                  Date created
                </td>
                <td className="px-4 py-3 border-b border-page-border">
                  Next schedule
                </td>
                <td className="px-4 py-3 border-b border-page-border"></td>
              </tr>
            </thead>
            <tbody>
              {playlists.map((x) => (
                <PlaylistListItem
                  key={x.id}
                  playlist={x}
                  loggedInUser={loggedInUser}
                />
              ))}
            </tbody>
          </table>
          <Pagination
            totalPageCount={totalPageNumber}
            currentPageNumber={currentPageNumber}
            setCurrentPageNumber={setCurrentPageNumber}
          />
        </div>
      </div>
      <AddPlaylistModal
        onSuccess={handleModalSuccess}
        onFailure={onAddPlaylistFailure}
      />
      <DeletePlaylistModal onSuccess={handleModalSuccess} />
    </>
  );
}
