import { AppButton, ButtonType } from "../buttons";
import { useRecoilState } from "recoil";
import { Link, useNavigate } from "react-router-dom";
import {
  PlaylistsClient,
  PutAssetPlaylistDto,
  UpdatePlaylistDto,
} from "../../services";
import { GetNewInstance, Store } from "../../helpers";
import { HeaderPolygon } from "../Svgs";
import { PlaylistUpdateState, SelectedPlaylistState } from "../../atoms";
import { toast } from "react-toastify";

export function PlaylistEditHeader() {
  const [playlist] = useRecoilState(SelectedPlaylistState);
  const [playlistUpdate] = useRecoilState(PlaylistUpdateState);

  const customerId = Store.getCustomerId();
  const navigate = useNavigate();
  const client = new PlaylistsClient(undefined, GetNewInstance());

  const handleCancel = () => {
    navigate("/playlist/details");
  };
  const handleSaveChanges = () => {
    if (playlistUpdate === null || playlistUpdate.id === undefined) {
      toast.error("No playlist change to save");
      return;
    }
    let request = playlistUpdate;

    if (
      playlistUpdate?.updatePlaylistAssets === undefined ||
      playlistUpdate.updatePlaylistAssets === null
    )
      request = {
        color: playlistUpdate.color,
        defaultImageDurationInSecounds:
          playlistUpdate.defaultImageDurationInSecounds,
        description: playlistUpdate.description,
        name: playlistUpdate.name,
        id: playlistUpdate.id,
        transitionDurationInSecounds:
          playlistUpdate.transitionDurationInSecounds,
        updatePlaylistAssets: [] as PutAssetPlaylistDto[],
        canvasIds: playlistUpdate.canvasIds,
      } as UpdatePlaylistDto;

    customerId &&
      playlistUpdate &&
      client.updatePlaylist(customerId, request).then((x) => {
        toast.success("Playlist updated successfully");
        navigate("/playlist/details");
      });
  };

  return (
    <div className="h-17 flex-none flex justify-between items-center p-5 border-b border-page-border">
      <div>
        <div className="flex justify-center items-center  gap-3 font-medium text-gray-50">
          <Link to="/playlist" className="text-gray-300">
            Playlists
          </Link>
          <HeaderPolygon className="w-3 h-2" />
          <span>{playlist?.name}</span>
        </div>
      </div>
      <div>
        <div className="flex justify-center items-center gap-3">
          <AppButton
            onClick={handleCancel}
            button_type={ButtonType.Secoundary}
            className="h-10"
          >
            Cancel
          </AppButton>
          <AppButton
            onClick={handleSaveChanges}
            button_type={ButtonType.Primary}
            className="h-10"
          >
            Save changes
          </AppButton>
        </div>
      </div>
    </div>
  );
}
