import { UserInfoListDto, UserRole } from "../../services";

const isEditorRoleMinimum = (user: UserInfoListDto | null): boolean =>
  user?.role !== UserRole.ViewOnly;

const isAdminRoleMinimum = (user: UserInfoListDto | null): boolean =>
  user?.role === UserRole.Admin || user?.role === UserRole.SuperAdmin;

const isSuperAdminRoleMinimum = (user: UserInfoListDto | null): boolean =>
  user?.role === UserRole.SuperAdmin;

export const RoleCheck = {
  isEditorRoleMinimum,
  isAdminRoleMinimum,
  isSuperAdminRoleMinimum,
};
