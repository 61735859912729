import { BaseModal } from "./BaseModal";
import { ModalTypes } from "./ModalTypes";
import { AuditLogDto, AuditLogsClient } from "../../services";
import { GetNewInstance, Store } from "../../helpers";
import { useEffect, useState } from "react";
import { AssetDetailState, ModalTypeState } from "../../atoms";
import { useRecoilState } from "recoil";

export function AssetAuditLogModal() {
  const [hasMore, setHasMore] = useState(false);
  const [assetDetailState] = useRecoilState(AssetDetailState);
  const [modalTypeState] = useRecoilState(ModalTypeState);
  const [auditLogs, setAuditLogs] = useState<AuditLogDto[]>([]);

  const usersCustomerId = Store.getCustomerId();
  const auditLogClient = new AuditLogsClient(undefined, GetNewInstance());

  useEffect(() => {
    if (modalTypeState !== ModalTypes.AssetAuditLogModal) {
      setHasMore(false);
      setAuditLogs([]);
      return;
    }
    usersCustomerId &&
      assetDetailState?.assetId &&
      auditLogClient
        .getAssetsAuditLogs(
          usersCustomerId,
          assetDetailState?.assetId,
          auditLogs.length === 0
            ? null
            : auditLogs[auditLogs.length - 1].createdAt ?? null
        )
        .then((response) => {
          response?.data && setAuditLogs([...auditLogs, ...response?.data]);
          setHasMore((response?.data?.length ?? 0) > 0);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalTypeState]);

  const handleLoadMore = () => {
    usersCustomerId &&
      assetDetailState?.assetId &&
      auditLogClient
        .getAssetsAuditLogs(
          usersCustomerId,
          assetDetailState?.assetId,
          auditLogs.length === 0
            ? null
            : auditLogs[auditLogs.length - 1].createdAt ?? null
        )
        .then((response) => {
          response?.data && setAuditLogs([...auditLogs, ...response?.data]);
          setHasMore((response?.data?.length ?? 0) > 0);
        });
  };

  return (
    <BaseModal
      id={ModalTypes.AssetAuditLogModal}
      title={assetDetailState?.assetName + "'s audit logs"}
      description={
        "These are the logs for all action made on " +
        assetDetailState?.assetName
      }
      wideMode
    >
      <div className="h-full overflow-auto text-body_14 ">
        <div className="border-0 rounded-lg border-page-border overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
          <table className="w-full text-sm ">
            <thead className="text-gray-300">
              <tr className="border-b border-page-border">
                <td className="px-4 py-3 w-1/6">Date</td>
                <td className="px-4 py-3">User Name</td>
                <td className="px-4 py-3">Message</td>
              </tr>
            </thead>
            <tbody className="text-gray-100 ">
              {auditLogs.map((x) => (
                <tr key={x.id}>
                  <td className="px-4 py-3 border-b border-page-border">
                    {x?.createdAt === null || x?.createdAt === undefined
                      ? ""
                      : x.createdAt.toLocaleDateString() +
                        " " +
                        x.createdAt?.toLocaleTimeString()}
                  </td>
                  <td className="px-4 py-3 border-b border-page-border">
                    {x.userFullName}
                  </td>
                  <td className="px-4 py-3 border-b border-page-border">
                    {x.description}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {hasMore && (
          <div
            className="text-button-bg-primary-default p-2 cursor-pointer text-center"
            onClick={() => handleLoadMore()}
          >
            load more
          </div>
        )}
      </div>
    </BaseModal>
  );
}
