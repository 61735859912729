import { IconStyle } from "../components/buttons";
import * as Icon from 'react-feather';
import CustomerList from "../components/mainContent/CusomerList";
import { BasePageLayout } from "../components/layouts/BasePageLayout";
import { UserRole } from "../services";
import { Store } from "../helpers";

export function CustomerListPage() {

    const loggedInUser = Store.getLoggedInUser();

    return (
        <BasePageLayout title="Customers" buttonText="Add customer" icon_style={IconStyle.None} icon={<Icon.Camera />} showButton={loggedInUser?.role === UserRole.SuperAdmin}>
            <CustomerList />
        </BasePageLayout>
    );
}