import { BaseModal } from "./BaseModal";
import { AddCustomerForm } from "../form";
import { ModalTypes } from "./ModalTypes";

type Props = {
    onSuccess: () => void;
    onFailure: () => void;
}

export function AddCustomerModal({ onSuccess, onFailure }: Props) {

    return (
        < BaseModal id={ModalTypes.HeaderModal} title='Add customer' description='Create a new customer account'>
            <AddCustomerForm onSuccess={onSuccess} onFailure={onFailure} />
        </BaseModal>


    );
}