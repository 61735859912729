import { useRecoilState } from "recoil";
import {
  UilAngleLeftB,
  UilAngleDown,
  UilAngleRightB,
  UilAngleUp,
} from "@iconscout/react-unicons";
import { ZoomIn, ZoomOut } from "react-feather";
import { forwardRef, useState } from "react";
import { ScheduleZoomInState, ScheduleZoomOutState } from "../../atoms";
import DatePicker from "react-datepicker";
import "../Misc/DateTimePicker.css";
import { SelectedCalanderDateState } from "../../atoms/SelectedCalanderDateState";
import dayjs from "dayjs";
import { Store } from "../../helpers";

export function ScheduleHeader() {
  const [, setZoomIn] = useRecoilState(ScheduleZoomInState);
  const [, setZoomOut] = useRecoilState(ScheduleZoomOutState);
  const [selectedDate, setSelectedDate] = useRecoilState(
    SelectedCalanderDateState
  );
  const [calanderIsOpen, setCalanderIsOpen] = useState(false);

  const currentCustomer = Store.getCurrentCustomer();

  const handleCalanderDateChange = (newDate: Date) => {
    setSelectedDate(newDate);
    setCalanderIsOpen(false);
  };

  const CustomInput = forwardRef(({ value, onClick }: any, ref) => (
    <div
      className="relative bg-Secondary-Input-bg text-gray-100 flex justify-center items-center rounded-md py-3 px-5"
      onClick={(e) => {
        onClick(e);
        setCalanderIsOpen(true);
      }}
    >
      <div className="flex">
        <span>{value}</span>
        {/* {
                    calanderIsOpen ?
                        <UilAngleUp className="pl-2 w-7 h-7 text-gray-200" /> :
                        <UilAngleDown className="pl-2 w-7 h-7 text-gray-200" />
                } */}
      </div>
    </div>
  ));

  return (
    <div className="h-17 flex-none flex justify-between items-center p-5 border-b border-page-border">
      <div className="flex items-center gap-x-3">
        <div className="font-medium text-h3">Schedules</div>
        {currentCustomer?.timeZoneName && (
          <div className="text-xs text-gray-300">
            (Times shown in {currentCustomer?.timeZoneName})
          </div>
        )}
      </div>
      <div className="flex  items-center gap-x-3 py-0 ">
        <ZoomOut
          className="w-7 h-7 cursor-pointer hover:opacity-60"
          onClick={() => setZoomOut(0.5)}
        />
        <ZoomIn
          className="w-7 h-7 cursor-pointer hover:opacity-60"
          onClick={() => setZoomIn(0.5)}
        />
        <UilAngleLeftB
          className="w-7 h-7 cursor-pointer"
          onClick={() => {
            setSelectedDate(dayjs(selectedDate).subtract(1, "day").toDate());
          }}
        />
        <DatePicker
          className=" rounded-md py-3 px-5"
          selected={selectedDate}
          onChange={(date) => date && handleCalanderDateChange(date)}
          customInput={<CustomInput />}
          showPopperArrow={false}
          dateFormat="MMM dd yyyy"
          todayButton={
            <small className="flex justify-center items-center text-gray-50 leading-4 p-2 cursor-pointer">
              Today
            </small>
          }
        />
        <UilAngleRightB
          className="w-7 h-7 cursor-pointer"
          onClick={() => {
            setSelectedDate(dayjs(selectedDate).add(1, "day").toDate());
          }}
        />
      </div>
    </div>
  );
}
