import { Store } from "../helpers";
import { AssetListPage } from "./AssetListPage";
import { CustomerListPage } from "./CustomerListPage";


export function HomePage() {

    const loggedInUser = Store.getLoggedInUser();

    return (
        loggedInUser?.customerId ?
            <AssetListPage />
            : <CustomerListPage />
    );
}