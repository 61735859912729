import { selector } from "recoil";
import { Store } from "../helpers";
import { UploadedFileState } from "./UploadedFileState";

export const UploadIsInProgressState = selector({
  key: "uploadIsInProgressState",
  get: ({ get }) => {
    const uploadState = get(UploadedFileState);
    const usersCustomerId = Store.getCustomerId();
    const response =
      uploadState.filter(
        (x) =>
          !x.isFinished && !x.isCanceled && usersCustomerId === x.customerId
      ).length > 0;

    return response;
  },
});
