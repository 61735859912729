import { ClipLoader } from "react-spinners";

type Props = {
  showSpinner: boolean;
  className: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children: string | JSX.Element;
};

export function SpinnerButton({
  className,
  showSpinner,
  onClick,
  children,
}: Props) {
  return (
    <button
      className={`${className}`}
      disabled={showSpinner}
      onClick={(e) => onClick(e)}
    >
      <div className="flex flex-row items-center justify-center gap-2">
        <ClipLoader size={22} color="#36d7b7" loading={showSpinner} />
        <span className="flex-1">{children}</span>
      </div>
    </button>
  );
}
