import { BaseModal } from "./BaseModal";
import { AddCanvasForm } from "../form";
import { ModalTypes } from "./ModalTypes";

type Props = {
  onSuccess: () => void;
  onFailure: () => void;
};

export function AddCanvasModal({ onSuccess, onFailure }: Props) {
  return (
    <BaseModal
      id={ModalTypes.AddCanvasModal}
      title="Add canvas"
      description="Add a canvas to display assets and playlists on it."
    >
      <AddCanvasForm onSuccess={onSuccess} onFailure={onFailure} />
    </BaseModal>
  );
}
