import { X } from "react-feather";

type Props = {
    className?: string,
    children: string,
    onCloseClicked?: (value: string) => void
};

export function Chip({ className, children, onCloseClicked }: Props) {

    const handleOnCloseClicked = () => {
        onCloseClicked && onCloseClicked(children);
    }

    return (
        <div className={`flex items-center border-2 rounded-full px-3 py-1  ${className}`}>
            <span className="pr-2 pl-1">{children}</span>
            {
                onCloseClicked && <X className="cursor-pointer w-3 inline" onClick={handleOnCloseClicked} />
            }
        </div>);
}