import {
  UilUser,
  UilSignOutAlt,
  UilAngleUp,
  UilAngleDown,
  UilLeftArrowToLeft,
  UilEnvelope,
} from "@iconscout/react-unicons";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { ModalTypeState } from "../../atoms";
import { Color } from "../../constants";
import { baseURL } from "../../constants/ApiConstans";
import { Store } from "../../helpers";
import { NameLogo } from "../Misc";
import { ModalTypes } from "../modal";
import { Tooltip } from "../Misc/Tooltip";

export function ProfileInfo() {
  const [, setIsShowing] = useRecoilState(ModalTypeState);
  const [showOption, setShowOptions] = useState(false);
  const [showBackToAdmin, setShowBackToAdmin] = useState(false);
  const [hasImage, setHasImage] = useState(true);

  const loggedInUser = Store.getLoggedInUser();
  const currentCustomer = Store.getCurrentCustomer();

  const navigate = useNavigate();

  useEffect(() => {
    const usersCustomerId = Store.getCustomerId();
    setShowBackToAdmin(
      loggedInUser?.customerId === null && usersCustomerId !== null
    );
  }, [loggedInUser?.customerId]);

  const toggleList = () => {
    setShowOptions(!showOption);
  };

  const handleLogoutClick = () => {
    setIsShowing(ModalTypes.LogoutModal);
  };

  const handleGoToHome = () => {
    Store.removeCurrentCustomer();
    navigate("/");
  };

  return (
    <div className="w-full">
      <div className="m-4 bg-white bg-opacity-5 rounded px-3 py-2">
        {showOption && (
          <ul>
            <Link to="/profile">
              <li className="flex items-center gap-1 py-2 cursor-pointer">
                <UilUser />
                <span>Profile</span>
              </li>
            </Link>

            <Tooltip text="Email PandorasBoxSchedulerSupport@ChristieDigital.com for customer support">
              <a href="mailto:PandorasBoxSchedulerSupport@ChristieDigital.com" target="_blank" rel="noreferrer">
                <li className="flex items-center gap-1 py-2 cursor-pointer">
                  <UilEnvelope />
                  Get Help
                </li>
              </a>
            </Tooltip>

            <li
              className="flex items-center gap-1 py-2 cursor-pointer"
              onClick={handleLogoutClick}
            >
              <UilSignOutAlt />
              Logout
            </li>
            {showBackToAdmin && (
              <li
                className="flex items-center gap-1 py-2 cursor-pointer"
                onClick={handleGoToHome}
              >
                <UilLeftArrowToLeft />
                Back to Admin
              </li>
            )}
          </ul>
        )}
        <div
          className="flex justify-between items-center cursor-pointer"
          onClick={toggleList}
        >
          {hasImage ? (
            <img
              className={`${
                currentCustomer !== undefined
                  ? "h-8 w-8 object-cover rounded-full mr-4"
                  : "h-6 w-14 object-cover"
              }`}
              src={`${
                currentCustomer !== undefined
                  ? `${baseURL}/api/customers/${currentCustomer?.id}/logo?logo=${currentCustomer?.logo}`
                  : require("../../assets/Pandoras_Box_Logo_RGB_negativ_SOLID 1.png")
              }`}
              alt={"logo"}
              onError={() => setHasImage(false)}
            />
          ) : (
            <NameLogo
              className={`text-gray-200 min-h-[2rem] min-w-[2rem] rounded-full text-sm`}
              color={Color.Gray}
              name={
                currentCustomer?.name ??
                `${loggedInUser?.firstName} ${loggedInUser?.lastName}`
              }
              letterCount={2}
            />
          )}

          {currentCustomer !== undefined &&
          currentCustomer !== null &&
          loggedInUser?.customerId === null ? (
            <div className="w-full truncate pl-4">
              <div className="truncate">
                <span className="text-sm" title={currentCustomer?.name}>
                  {currentCustomer?.name}{" "}
                </span>
              </div>
            </div>
          ) : (
            <div className="w-full truncate pl-4">
              <div className="truncate">
                <span
                  className="text-sm  "
                  title={loggedInUser?.firstName + " " + loggedInUser?.lastName}
                >
                  {loggedInUser?.firstName + " " + loggedInUser?.lastName}
                </span>
              </div>
              <small className="opacity-70 text-xs" title={loggedInUser?.email}>
                {loggedInUser?.email}
              </small>
            </div>
          )}

          <div>{showOption ? <UilAngleUp /> : <UilAngleDown />}</div>
        </div>
      </div>
    </div>
  );
}
