export const formatSizeFromBytes = (size: number): string => {
  const thresh = 1024;

  if (Math.abs(size) < thresh) {
    return `${size} B`;
  }

  const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let u = -1;
  const r = 10 ** 2;

  do {
    size /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(size) * r) / r >= thresh &&
    u < units.length - 1
  );

  return `${size.toFixed(2)} ${units[u]}`;
};
