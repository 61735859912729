import { useRecoilState } from "recoil";
import { ModalTypeState, SelectedCanvasState } from "../../atoms";
import { BaseModal } from "./BaseModal";
import { CanvasesClient } from "../../services";
import { GetNewInstance } from "../../helpers/HttpCommon";
import { ModalTypes } from "./ModalTypes";
import { Store } from "../../helpers";
import { toast } from "react-toastify";
import { useState } from "react";
import { SpinnerButton } from "../buttons";

type Props = {
  onSuccess: () => void;
  onFailure: () => void;
};

export function DeleteCanvasModal({ onSuccess, onFailure }: Props) {
  const [, setModalType] = useRecoilState(ModalTypeState);
  const [selectedCanvas, setSelectedCanvas] =
    useRecoilState(SelectedCanvasState);
  const [showSpinner, setShowSpinner] = useState(false);

  const client = new CanvasesClient(undefined, GetNewInstance());
  const usersCustomerId = Store.getCustomerId();

  const onCancel = () => {
    setSelectedCanvas(null);
    setModalType(null);
    onFailure();
  };

  const onDeleteClick = (
    e: React.MouseEvent<HTMLButtonElement> | undefined
  ) => {
    setShowSpinner(true);
    usersCustomerId &&
      selectedCanvas?.id &&
      client
        .deleteCanvas(usersCustomerId, selectedCanvas?.id)
        .then((x) => {
          toast.success("Canvas deleted successfully");
          onSuccess();
          setSelectedCanvas(null);
          setModalType(null);
        })
        .finally(() => {
          setShowSpinner(false);
        });
  };

  return (
    <BaseModal
      id={ModalTypes.DeleteCanvasModal}
      title="Delete canvas"
      description={
        "Are you sure you want to delete " +
        selectedCanvas?.name +
        "? All schedules assigned to it will also be deleted"
      }
    >
      <div className="py-4 text-sm text-white">
        <div className="mt-8 flex justify-end gap-4">
          <button
            onClick={onCancel}
            className="py-2.5 px-4 rounded-md border border-page-border bg-white bg-opacity-5 hover:bg-opacity-10"
          >
            Cancel
          </button>
          <SpinnerButton
            onClick={onDeleteClick}
            className="py-2.5 px-4 rounded-md bg-button-bg-destructive-default hover:bg-button-bg-destructive-hover active:shadow-button-bg-destructive-default"
            showSpinner={showSpinner}
          >
            Delete
          </SpinnerButton>
        </div>
      </div>
    </BaseModal>
  );
}
