import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { UpdatePasswordWithTokenDto, UsersClient } from "../services";
import * as yup from "yup";
import { PasswordStrength } from "../components/auth/PasswordStrength";
import { UilEye } from "@iconscout/react-unicons";
import { baseURL } from "../constants";

const changePasswordRequestSchema = yup
  .object({
    newPassword: yup
      .string()
      .required("New password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&;])(?=.{8,})/,
        "Must contain min 8 characters, Atleast one uppercase, one lowercase, one number and one special case character"
      ),
    confirmPassword: yup
      .string()
      .required("Password confirmation is required")
      .oneOf([yup.ref("newPassword")], "Passwords does not match"),
  })
  .required();

export function ResetPassword() {
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const [serverResponse, SetServerResponse] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const client = new UsersClient(baseURL);

  let code = searchParams.get("code") ?? undefined;
  let email = searchParams.get("email") ?? undefined;

  useEffect(() => {
    !(code && email) && navigate("/login");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<UpdatePasswordWithTokenDto>({
    resolver: yupResolver(changePasswordRequestSchema),
  });

  const watchPassword = watch("newPassword");

  const onSubmit = (request: UpdatePasswordWithTokenDto) => {
    request.email = email;
    request.token = code;

    client
      .changePasswordByToken(request)
      .then((x) => {
        navigate("/login");
      })
      .catch((x) => {
        SetServerResponse("Error try again");
      });
  };

  return (
    <div className="h-screen w-full overflow-auto bg-bg text-white font-avenir">
      <div className=" h-32 flex items-center pl-16">
        <img
          className=" h-12"
          src={require("../assets/Pandoras_Box_Logo_RGB_negativ_SOLID 1.png")}
          alt="Pandoras Box"
        />
      </div>
      <div className="flex justify-center">
        <div className="w-full max-w-md">
          <div className="font-medium text-gray-50 text-h3">
            Set new password
          </div>
          <small className="text-gray-200 text-body_14">
            Create a new password for your account
          </small>
          <form className="py-4 text-sm" onSubmit={handleSubmit(onSubmit)}>
            <div className="my-4">
              <label className="flex flex-col text-gray-100">
                <small className="order-2  w-full text-red-600">
                  {serverResponse}
                </small>
                <span>New Password</span>
                <div className="h-11 rounded-md border border-page-border flex items-center bg-input-bg overflow-hidden">
                  <input
                    {...register("newPassword")}
                    autoComplete="current-password"
                    className="h-full w-full pl-4 bg-transparent border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
                    type={showNewPassword ? "text" : "password"}
                  />
                  <UilEye
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    className="w-5 h-5 mx-4 cursor-pointer"
                  />
                </div>
                <small className="text-red-600">
                  {errors.newPassword?.message}
                </small>
              </label>
            </div>
            <div className="my-4">
              <label className="flex flex-col  text-gray-100">
                <small className="order-2  w-full text-red-600">
                  {serverResponse}
                </small>
                <span>Confirm Password</span>
                <div className="h-11 rounded-md border border-page-border flex items-center bg-input-bg overflow-hidden">
                  <input
                    {...register("confirmPassword")}
                    autoComplete="current-password"
                    className="h-full w-full pl-4 bg-transparent border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
                    type={showConfirmPassword ? "text" : "password"}
                  />
                  <UilEye
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="w-5 h-5 mx-4 cursor-pointer"
                  />
                </div>
                <small className="text-red-600">
                  {errors.confirmPassword?.message}
                </small>
              </label>
            </div>
            <div>
              <PasswordStrength className="py-1" password={watchPassword} />
            </div>
            <button className="order-1 my-8 w-full py-2.5 px-4 rounded-md bg-button-bg-primary-default hover:bg-button-bg-primary-hover active:shadow-button_primary">
              Set new password
            </button>
            <Link to="/login">
              <small className="text-gray-50 cursor-pointer w-full my-3 flex justify-center ">
                Back to login
              </small>
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}
