import { DataItem } from "vis-timeline";
import { ScheduleListDto } from "../../services";

type Props = {
    data: DataItem,
    schedule: ScheduleListDto | undefined
}

export default function ScheduleTimelinePlaylistItem({ data, schedule }: Props) {
    return (
        <div>
            <div>
                {schedule?.name}
                <small>{"PLAYLIST"}</small>
            </div>
        </div>
    );
}