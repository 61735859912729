import { Trash2, Edit } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  AssetDetailState,
  ModalTypeState,
  SelectedPlaylistState,
  SelectedScheduleState,
} from "../../atoms";
import { ShowContextMenuState } from "../../atoms/ShowContextMenuState";
import { Store } from "../../helpers";
import { RequireEditorRoleMinimum } from "../auth";
import { ModalTypes } from "../modal";
import { useEffect } from "react";

export function ScheduleContextMenu() {
  const [, setModalType] = useRecoilState(ModalTypeState);

  const [contextMenuState, setContextMenuState] =
    useRecoilState(ShowContextMenuState);
  const [selectedSchedule] = useRecoilState(SelectedScheduleState);
  const [, setAssetDetailState] = useRecoilState(AssetDetailState);
  const [, setSelectedPlaylist] = useRecoilState(SelectedPlaylistState);

  const navigate = useNavigate();
  const loggedInUser = Store.getLoggedInUser();

  const handleDeleteClick = () => {
    resetScheduleContextMenu();
    setModalType(ModalTypes.DeleteSchedulModal);
  };

  const handleEditClick = () => {
    resetScheduleContextMenu();
    setModalType(ModalTypes.UpdateScheduleModal);
  };

  const handleDetailClick = () => {
    resetScheduleContextMenu();
    if (contextMenuState.isPlaylist) {
      setSelectedPlaylist({
        id: selectedSchedule?.resourceId ?? "",
        name: selectedSchedule?.name ?? "",
      });
      navigate("/playlist/details");
    } else {
      setAssetDetailState({
        assetId: selectedSchedule?.resourceId,
        assetName: "",
        assetIds: [],
      });
      navigate("/asset/details");
    }
  };

  const resetScheduleContextMenu = () => {
    setContextMenuState({
      xPos: 0,
      yPos: 0,
      isShown: false,
      isPlaylist: false,
    });
  };

  useEffect(() => {
    return () => {
      const specifiedElement = document.getElementById("scheduleContextMenu");
      document.addEventListener("click", (event: any) => {
        const isClickInside = specifiedElement?.contains(event.target);
        if (!isClickInside) {
          resetScheduleContextMenu();
        }
      });
    };
  }, []);

  return (
    <div tabIndex={1}>
      <div
        id="scheduleContextMenu"
        className="absolute z-10"
        style={{ top: contextMenuState.yPos, left: contextMenuState.xPos }}
        tabIndex={1}
      >
        {contextMenuState.isShown && (
          <div
            className={` rounded-md border border-page-border bg-dropdown min-w-max cursor-pointer text-gray-100`}
          >
            <ul className=" max-h-64 rounded-md overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
              <li>
                <button
                  className="w-full bg-dropdown"
                  onClick={handleDetailClick}
                >
                  <label className="flex items-center rounded-md gap-2 p-2.5  cursor-pointer hover:bg-button-bg-primary-hover">
                    <Edit className="w-4 first-letter:text-gray-200" />
                    <span>Go to Details</span>
                  </label>
                </button>
              </li>
              <RequireEditorRoleMinimum user={loggedInUser}>
                <>
                  <li>
                    <button
                      className="w-full bg-dropdown"
                      onClick={handleEditClick}
                    >
                      <label className="flex items-center rounded-md gap-2 p-2.5  cursor-pointer hover:bg-button-bg-primary-hover">
                        <Edit className="w-4 first-letter:text-gray-200" />
                        <span>Edit schedule</span>
                      </label>
                    </button>
                  </li>
                  <li>
                    <button
                      className="w-full bg-dropdown"
                      onClick={handleDeleteClick}
                    >
                      <label className="flex items-center rounded-md gap-2 p-2.5  text-button-bg-destructive-default cursor-pointer hover:bg-button-bg-primary-hover">
                        <Trash2 className="w-4 first-letter:text-gray-200" />
                        <span>Delete schedule</span>
                      </label>
                    </button>
                  </li>
                </>
              </RequireEditorRoleMinimum>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
