import { selector } from "recoil";
import { Store } from "../helpers";
import { UploadedFileState } from "./UploadedFileState";

export const UploadFilePercentageState = selector({
  key: "uploadFilePercentageState",
  get: ({ get }) => {
    const uploadState = get(UploadedFileState);
    const usersCustomerId = Store.getCustomerId();

    const totalFileSizes =
      uploadState.filter(
        (x) => !x.isCanceled && x.customerId === usersCustomerId
      ).length === 0
        ? 0
        : uploadState
            .filter((x) => !x.isCanceled && x.customerId === usersCustomerId)
            .map((x) => x.fileSize)
            .reduce((previousValue, newValue) => {
              return previousValue + newValue;
            });

    const uploadedFileSizes =
      uploadState.filter(
        (x) => !x.isCanceled && x.customerId === usersCustomerId
      ).length === 0
        ? 0
        : uploadState
            .filter((x) => !x.isCanceled && x.customerId === usersCustomerId)
            .map((x) => x.percentCompleted * x.fileSize)
            .reduce((previousValue, newValue) => {
              return previousValue + newValue;
            });

    return totalFileSizes !== 0 ? uploadedFileSizes / totalFileSizes : 100;
  },
});
