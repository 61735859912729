import { BaseModal } from "./BaseModal";
import { UpdateCanvasForm } from "../form";
import { ModalTypes } from "./ModalTypes";

type Props = {
  onSuccess: () => void;
  onFailure: () => void;
};

export function UpdateCanvasModal({ onSuccess, onFailure }: Props) {
  return (
    <BaseModal
      id={ModalTypes.UpdateCanvasModal}
      title="Update canvas"
      description="Update the canvas information"
    >
      <UpdateCanvasForm onSuccess={onSuccess} onFailure={onFailure} />
    </BaseModal>
  );
}
