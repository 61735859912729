import { useEffect, useState } from "react";
import { UilSearch } from "@iconscout/react-unicons";
import { useRecoilState } from "recoil";
import { FilterIsShownState } from "../../atoms/FilterIsShownState";
import { AssetFilterState } from "../../atoms/AssetFilterState";
import { AssetListPageState } from "../../atoms/AssetListPageState";
import { AssetFilterOptions, AssetsClient } from "../../services";
import { Store, GetNewInstance } from "../../helpers";

export function AssetFilter() {
  const [filterIsShown, setFilterIsShown] = useRecoilState(FilterIsShownState);
  const [filterState, setFilterState] = useRecoilState(AssetFilterState);
  const [, setCurrentPageNumber] = useRecoilState(AssetListPageState);
  const [tagOptions, setTagsOptions] = useState<string[]>([]);
  const [filterOptions, setFilterOptions] = useState<AssetFilterOptions>();

  const usersCustomerId = Store.getCustomerId();

  let timeOutId: NodeJS.Timeout;

  useEffect(() => {
    const client = new AssetsClient(undefined, GetNewInstance());
    usersCustomerId &&
      client.getAssetFilterOptions(usersCustomerId).then((x) => {
        x?.data && setFilterOptions(x?.data);
        x?.data && setTagsOptions(x?.data.tags ?? []);
      });

    let element = document.getElementById("asset_filter");
    element?.focus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterIsShown]);

  const handleOnBlur = () => {
    timeOutId = setTimeout(() => {
      setFilterIsShown(false);
    }, 300);
  };

  const handleOnFocus = () => {
    clearTimeout(timeOutId);
  };

  const handleFileTypeChanged = (type: string) => {
    const fileTypes = filterState?.fileTypes ?? [];

    !filterState?.fileTypes.includes(type)
      ? setFilterState({
          excludedPlaylistId: filterState?.excludedPlaylistId,
          aspectRatios: filterState?.aspectRatios ?? [],
          resolution: filterState?.resolution ?? [],
          tags: filterState?.tags ?? [],
          fileCodecs: filterState?.fileCodecs ?? [],
          fileTypes: [...fileTypes, type],
        })
      : setFilterState({
          excludedPlaylistId: filterState?.excludedPlaylistId,
          aspectRatios: filterState?.aspectRatios ?? [],
          resolution: filterState?.resolution ?? [],
          tags: filterState?.tags ?? [],
          fileTypes: [...fileTypes.filter((x) => x !== type)],
          fileCodecs: filterState?.fileCodecs ?? [],
        });
    setCurrentPageNumber(1);
  };

  const handleFileCodecChanged = (codec: string) => {
    const fileCodecs = filterState?.fileCodecs ?? [];

    !filterState?.fileCodecs.includes(codec)
      ? setFilterState({
          excludedPlaylistId: filterState?.excludedPlaylistId,
          aspectRatios: filterState?.aspectRatios ?? [],
          resolution: filterState?.resolution ?? [],
          tags: filterState?.tags ?? [],
          fileTypes: filterState?.fileTypes ?? [],
          fileCodecs: [...fileCodecs, codec],
        })
      : setFilterState({
          excludedPlaylistId: filterState?.excludedPlaylistId,
          aspectRatios: filterState?.aspectRatios ?? [],
          resolution: filterState?.resolution ?? [],
          tags: filterState?.tags ?? [],
          fileCodecs: [...fileCodecs.filter((x) => x !== codec)],
          fileTypes: filterState?.fileTypes ?? [],
        });
    setCurrentPageNumber(1);
  };

  const handleAspectRatioChanged = (aspectRatio: string) => {
    const aspectRatios = filterState?.aspectRatios ?? [];

    !filterState?.aspectRatios.includes(aspectRatio)
      ? setFilterState({
          excludedPlaylistId: filterState?.excludedPlaylistId,
          fileTypes: filterState?.fileTypes ?? [],
          fileCodecs: filterState?.fileCodecs ?? [],
          resolution: filterState?.resolution ?? [],
          tags: filterState?.tags ?? [],
          aspectRatios: [...aspectRatios, aspectRatio],
        })
      : setFilterState({
          excludedPlaylistId: filterState?.excludedPlaylistId,
          fileTypes: filterState?.fileTypes ?? [],
          fileCodecs: filterState?.fileCodecs ?? [],
          resolution: filterState?.resolution ?? [],
          tags: filterState?.tags ?? [],
          aspectRatios: [...aspectRatios.filter((x) => x !== aspectRatio)],
        });
    setCurrentPageNumber(1);
  };

  const handleResolutionChanged = (resolution: string) => {
    const resolutions = filterState?.resolution ?? [];

    !filterState?.resolution.includes(resolution)
      ? setFilterState({
          excludedPlaylistId: filterState?.excludedPlaylistId,
          fileTypes: filterState?.fileTypes ?? [],
          fileCodecs: filterState?.fileCodecs ?? [],
          aspectRatios: filterState?.aspectRatios ?? [],
          tags: filterState?.tags ?? [],
          resolution: [...resolutions, resolution],
        })
      : setFilterState({
          excludedPlaylistId: filterState?.excludedPlaylistId,
          fileTypes: filterState?.fileTypes ?? [],
          fileCodecs: filterState?.fileCodecs ?? [],
          aspectRatios: filterState?.aspectRatios ?? [],
          tags: filterState?.tags ?? [],
          resolution: [...resolutions.filter((x) => x !== resolution)],
        });
    setCurrentPageNumber(1);
  };

  const handleResetAll = () => {
    clearTimeout(timeOutId);
    setFilterState({
      fileTypes: [],
      fileCodecs: [],
      aspectRatios: [],
      resolution: [],
      tags: [],
    });
    setCurrentPageNumber(1);
  };

  const handleTagClicked = (tag: string) => {
    const tags = filterState?.tags ?? [];

    !filterState?.tags.includes(tag)
      ? setFilterState({
          excludedPlaylistId: filterState?.excludedPlaylistId,
          fileTypes: filterState?.fileTypes ?? [],
          fileCodecs: filterState?.fileCodecs ?? [],
          aspectRatios: filterState?.aspectRatios ?? [],
          resolution: filterState?.resolution ?? [],
          tags: [...tags, tag],
        })
      : setFilterState({
          excludedPlaylistId: filterState?.excludedPlaylistId,
          fileTypes: filterState?.fileTypes ?? [],
          fileCodecs: filterState?.fileCodecs ?? [],
          aspectRatios: filterState?.aspectRatios ?? [],
          resolution: filterState?.resolution ?? [],
          tags: [...tags.filter((x) => x !== tag)],
        });
    setCurrentPageNumber(1);
  };

  const handleTagsSearch = (e: any) => {
    if (!filterOptions?.tags) return;

    const updatedTags =
      e.target.value === ""
        ? filterOptions?.tags
        : filterOptions?.tags.filter((x) =>
            x.toLowerCase().includes(e.target.value.toLowerCase())
          );

    setTagsOptions([...updatedTags]);
  };

  return (
    <>
      {filterIsShown && (
        <aside className="h-screen w-80 flex flex-col fixed right-0 top-0 z-10 bg-gray-800 shadow-2xl shadow- text-white ">
          <div
            tabIndex={1}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            className="overflow-hidden"
            id="asset_filter"
          >
            <div className="h-17 flex-none flex justify-between items-center p-5 border-b border-page-border">
              <div className="font-medium text-h5">Filters</div>
              <div className="flex items-center gap-4">
                <span
                  onClick={handleResetAll}
                  className="cursor-pointer text-sm text-blue-500"
                >
                  Reset all
                </span>
              </div>
            </div>
            <div
              className="p-5 text-sm overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent "
              style={{ height: "calc(100vh - 50px)" }}
            >
              <div className="pb-4">
                <p className="uppercase py-3">File type</p>
                <div className="flex flex-row">
                  <div className="w-1/2">
                    <ul>
                      {filterOptions?.fileTypes &&
                        filterOptions?.fileTypes
                          .filter((x, i) => i % 2 === 0)
                          .map((item) => (
                            <li key={item}>
                              <div className="flex items-center gap-4 px-1 py-2">
                                <input
                                  className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4 cursor-pointer"
                                  name={item.toString()}
                                  type="checkbox"
                                  onChange={(e) => {
                                    handleFileTypeChanged(item);
                                  }}
                                  checked={
                                    filterState?.fileTypes?.includes(item) ??
                                    false
                                  }
                                />
                                <span
                                  className="cursor-pointer"
                                  onClick={() => handleFileTypeChanged(item)}
                                >
                                  {item}
                                </span>
                              </div>
                            </li>
                          ))}
                    </ul>
                  </div>
                  <div className="w-1/2">
                    <ul>
                      {filterOptions?.fileTypes &&
                        filterOptions?.fileTypes
                          .filter((x, i) => i % 2 !== 0)
                          .map((item) => (
                            <li key={item}>
                              <div className="flex items-center gap-4 px-1 py-2">
                                <input
                                  className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4 cursor-pointer"
                                  name={item.toString()}
                                  type="checkbox"
                                  onChange={(e) => {
                                    handleFileTypeChanged(item);
                                  }}
                                  checked={
                                    filterState?.fileTypes?.includes(item) ??
                                    false
                                  }
                                />
                                <span
                                  className="cursor-pointer"
                                  onClick={() => handleFileTypeChanged(item)}
                                >
                                  {item}
                                </span>
                              </div>
                            </li>
                          ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="pb-4">
                <p className="uppercase py-3">File codec</p>
                <div className="flex flex-row">
                  <div className="w-1/2">
                    <ul>
                      {filterOptions?.fileCodecs &&
                        filterOptions?.fileCodecs
                          .filter((x, i) => i % 2 === 0)
                          .map((item) => (
                            <li key={item}>
                              <div className="flex items-center gap-4 px-1 py-2">
                                <input
                                  className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4 cursor-pointer"
                                  name={item.toString()}
                                  type="checkbox"
                                  onChange={(e) => {
                                    handleFileCodecChanged(item);
                                  }}
                                  checked={
                                    filterState?.fileCodecs?.includes(item) ??
                                    false
                                  }
                                />
                                <span
                                  className="cursor-pointer"
                                  onClick={() => handleFileCodecChanged(item)}
                                >
                                  {item}
                                </span>
                              </div>
                            </li>
                          ))}
                    </ul>
                  </div>
                  <div className="w-1/2">
                    <ul>
                      {filterOptions?.fileCodecs &&
                        filterOptions?.fileCodecs
                          .filter((x, i) => i % 2 !== 0)
                          .map((item) => (
                            <li key={item}>
                              <div className="flex items-center gap-4 px-1 py-2">
                                <input
                                  className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4 cursor-pointer"
                                  name={item.toString()}
                                  type="checkbox"
                                  onChange={(e) => {
                                    handleFileCodecChanged(item);
                                  }}
                                  checked={
                                    filterState?.fileCodecs?.includes(item) ??
                                    false
                                  }
                                />
                                <span
                                  className="cursor-pointer"
                                  onClick={() => handleFileCodecChanged(item)}
                                >
                                  {item}
                                </span>
                              </div>
                            </li>
                          ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="pb-4">
                <p className="uppercase py-3">Aspect ratio</p>
                <div className="flex flex-row">
                  <div className="w-1/2">
                    <ul>
                      {filterOptions?.aspectRatios &&
                        filterOptions?.aspectRatios
                          .filter((x, i) => i % 2 === 0)
                          .map((item) => (
                            <li key={item}>
                              <div className="flex items-center gap-4 px-1 py-2">
                                <input
                                  className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4 cursor-pointer"
                                  name={item.toString()}
                                  type="checkbox"
                                  onChange={(e) => {
                                    handleAspectRatioChanged(item);
                                  }}
                                  checked={
                                    filterState?.aspectRatios?.includes(item) ??
                                    false
                                  }
                                />
                                <span
                                  className="cursor-pointer"
                                  onClick={() => handleAspectRatioChanged(item)}
                                >
                                  {item}
                                </span>
                              </div>
                            </li>
                          ))}
                    </ul>
                  </div>
                  <div className="w-1/2">
                    <ul>
                      {filterOptions?.aspectRatios &&
                        filterOptions?.aspectRatios
                          .filter((x, i) => i % 2 !== 0)
                          .map((item) => (
                            <li key={item}>
                              <div className="flex items-center gap-4 px-1 py-2">
                                <input
                                  className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4 cursor-pointer"
                                  name={item.toString()}
                                  type="checkbox"
                                  onChange={(e) => {
                                    handleAspectRatioChanged(item);
                                  }}
                                  checked={
                                    filterState?.aspectRatios?.includes(item) ??
                                    false
                                  }
                                />
                                <span
                                  className="cursor-pointer"
                                  onClick={() => handleAspectRatioChanged(item)}
                                >
                                  {item}
                                </span>
                              </div>
                            </li>
                          ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="pb-4">
                <p className="uppercase py-3">Resolution</p>
                <div className="flex flex-row">
                  <div className="w-1/2">
                    <ul>
                      {filterOptions?.resolutions &&
                        filterOptions?.resolutions
                          .filter((x, i) => i % 2 === 0)
                          .map((item) => (
                            <li key={item}>
                              <div className="flex items-center gap-4 px-1 py-2">
                                <input
                                  className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4 cursor-pointer"
                                  name={item.toString()}
                                  type="checkbox"
                                  onChange={(e) => {
                                    handleResolutionChanged(item);
                                  }}
                                  checked={
                                    filterState?.resolution?.includes(item) ??
                                    false
                                  }
                                />
                                <span
                                  className="cursor-pointer"
                                  onClick={() => handleResolutionChanged(item)}
                                >
                                  {item}
                                </span>
                              </div>
                            </li>
                          ))}
                    </ul>
                  </div>
                  <div className="w-1/2">
                    <ul>
                      {filterOptions?.resolutions &&
                        filterOptions?.resolutions
                          .filter((x, i) => i % 2 !== 0)
                          .map((item) => (
                            <li key={item}>
                              <div className="flex items-center gap-4 px-1 py-2">
                                <input
                                  className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4 cursor-pointer"
                                  name={item.toString()}
                                  type="checkbox"
                                  onChange={(e) => {
                                    handleResolutionChanged(item);
                                  }}
                                  checked={
                                    filterState?.resolution?.includes(item) ??
                                    false
                                  }
                                />
                                <span
                                  className="cursor-pointer"
                                  onClick={() => handleResolutionChanged(item)}
                                >
                                  {item}
                                </span>
                              </div>
                            </li>
                          ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="pb-4 overflow-hidden">
                <p className="uppercase py-3">Tags</p>
                <div className="h-10 mb-2 rounded-md border border-page-border flex items-center bg-bg ">
                  <UilSearch className="w-4 h-4 mx-4" />
                  <input
                    onChange={handleTagsSearch}
                    className="h-full bg-transparent border-0"
                    type="text"
                    placeholder="Search tag"
                  />
                </div>
                <div className="flex flex-row">
                  <div className="w-1/2">
                    <ul>
                      {tagOptions
                        .filter((x, i) => i % 2 === 0)
                        .map((tag) => (
                          <li key={tag}>
                            <div className="flex items-center gap-4 px-1 py-2">
                              <input
                                className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4 cursor-pointer"
                                name={tag}
                                type="checkbox"
                                onChange={(e) => {
                                  handleTagClicked(tag);
                                }}
                                checked={
                                  filterState?.tags?.includes(tag) ?? false
                                }
                              />
                              <span
                                className="cursor-pointer"
                                onClick={() => handleTagClicked(tag)}
                              >
                                {tag}
                              </span>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="w-1/2">
                    <ul>
                      {tagOptions
                        .filter((x, i) => i % 2 !== 0)
                        .map((tag) => (
                          <li key={tag}>
                            <div className="flex items-center gap-4 px-1 py-2">
                              <input
                                className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4 cursor-pointer"
                                name={tag}
                                type="checkbox"
                                onChange={(e) => {
                                  handleTagClicked(tag);
                                }}
                                checked={
                                  filterState?.tags?.includes(tag) ?? false
                                }
                              />
                              <span
                                className="cursor-pointer"
                                onClick={() => handleTagClicked(tag)}
                              >
                                {tag}
                              </span>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>
      )}
    </>
  );
}
