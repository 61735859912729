import { UilEllipsisV } from "@iconscout/react-unicons";
import { useState } from "react";
import { Activity, Archive, Trash2, Mail, Edit } from "react-feather";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { ModalTypeState, SelectedUserState } from "../../atoms";
import { GetNewInstance } from "../../helpers/HttpCommon";
import { UserInfoListDto, UsersClient, UserStatus } from "../../services";
import { ModalTypes } from "../modal";

type Props = {
  user: UserInfoListDto;
};

export function UserActionButton({ user }: Props) {
  const [selectedUser, setSelectedUser] =
    useRecoilState<UserInfoListDto | null>(SelectedUserState);
  const [, setModalType] = useRecoilState(ModalTypeState);
  const [showOption, setShowOption] = useState(false);

  const client = new UsersClient(undefined, GetNewInstance());
  let timeOutId: NodeJS.Timeout;

  const selectUser = () => {
    if (user.email === selectedUser?.email) {
      setSelectedUser(null);
      setShowOption(false);
    } else {
      setSelectedUser(user);
      setShowOption(true);
    }
  };

  const handleArchiveClick = () => {
    setShowOption(false);
    setModalType(ModalTypes.ArchiveUserModal);
  };

  const handleActivateClick = () => {
    setShowOption(false);
    setModalType(ModalTypes.ActivateUserModal);
  };

  const handleEditClick = () => {
    setShowOption(false);
    // setModalType("updateCustomer");
  };

  const handleDeleteClick = () => {
    setShowOption(false);
    setModalType(ModalTypes.DeleteUserModal);
  };

  const handleSendInviteClick = () => {
    user?.email &&
      client
        .sendActivationRequest(user?.email)
        .then((x) => {
          toast.success("Invitation has been sent successfully");
        })
        .finally(() => {
          setShowOption(false);
          setModalType(null);
        });
  };

  const handleOnBlur = () => {
    timeOutId = setTimeout(() => {
      setShowOption(false);
    }, 100);
  };

  const handleOnFocus = () => {
    clearTimeout(timeOutId);
  };

  return (
    <div
      className="flex justify-between gap-2"
      tabIndex={1}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
    >
      <div className="relative">
        <UilEllipsisV
          className=" relative cursor-pointer"
          onClick={selectUser}
        />
        {selectedUser?.email === user.email && showOption && (
          <div
            className={`absolute top-1 z-10 right-4 rounded-md border border-page-border bg-dropdown min-w-max cursor-pointer`}
          >
            <ul className=" max-h-64 overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
              {/* {user.status !== UserStatus.Pending && (
                <li>
                  <label
                    className="flex items-center rounded-md gap-2 p-2.5  cursor-pointer hover:bg-button-bg-primary-hover"
                    onClick={handleEditClick}
                  >
                    <Edit className="w-4 text-gray-200" />
                    <span>Update</span>
                  </label>
                </li>
              )} */}
              {user.status === UserStatus.Pending && (
                <>
                  <li>
                    <label
                      className="flex items-center rounded-md gap-2 p-2.5  cursor-pointer hover:bg-button-bg-primary-hover"
                      onClick={handleSendInviteClick}
                    >
                      <Mail className="w-4 text-gray-200" />
                      <span>Resend invite</span>
                    </label>
                  </li>
                  <li>
                    <label
                      className="flex items-center rounded-md gap-2 p-2.5  text-button-bg-destructive-default cursor-pointer hover:bg-button-bg-primary-hover"
                      onClick={handleDeleteClick}
                    >
                      <Trash2 className="w-4 " />
                      <span>Delete</span>
                    </label>
                  </li>
                </>
              )}
              {user.status === UserStatus.Active && (
                <li>
                  <label
                    className="flex items-center rounded-md gap-2 p-2.5  cursor-pointer hover:bg-button-bg-primary-hover"
                    onClick={handleArchiveClick}
                  >
                    <Archive className="w-4 text-gray-200" />
                    <span>Archive</span>
                  </label>
                </li>
              )}
              {user.status === UserStatus.Archived && (
                <li>
                  <label
                    className="flex items-center rounded-md gap-2 p-2.5  cursor-pointer hover:bg-button-bg-primary-hover"
                    onClick={handleActivateClick}
                  >
                    <Activity className="w-4 text-gray-200" />
                    <span>Activate</span>
                  </label>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
