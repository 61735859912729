import { Download } from "react-feather";
import { WindowsReleaseVersionClient, WindowsReleaseVersionDto } from "../../services";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { GetNewInstance, Store } from "../../helpers";

type Props = {
  windowsReleaseVersion: WindowsReleaseVersionDto;
};

export function WindowsVersionListItem({ windowsReleaseVersion }: Props) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const currentCustomer = Store.getCurrentCustomer();
  const client = new WindowsReleaseVersionClient(undefined, GetNewInstance());

  const DownloadWindowsReleaseVersion = () => () => {
    if (windowsReleaseVersion.blobName) {
      client
        .downloadWindowsReleaseVersion(windowsReleaseVersion.blobName)
        .then((response) => {
          if (!response?.data) {
            return;
          }

          const link = document.createElement("a");
          link.href = response.data;
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <tr key={windowsReleaseVersion.version}>
      <td className="px-4 py-3 border-b w-28 border-page-border items-center justify-center">
        {windowsReleaseVersion.appName}
      </td>
      <td className="px-4 py-3 border-b w-28 border-page-border items-center justify-center">
        {windowsReleaseVersion.version}
      </td>
      <td className="px-4 py-3 border-b w-28 border-page-border items-center justify-center">
        {windowsReleaseVersion.requirement}
      </td>
      <td className="px-4 py-3 border-b w-28 border-page-border items-center justify-center">
        {windowsReleaseVersion.releaseDate
          ? dayjs(windowsReleaseVersion.releaseDate)
              .tz(currentCustomer?.timeZoneIANA)
              .format("MM/DD/YYYY")
              .toString()
          : ""}
      </td>
      <td className="px-4 py-3 border-b w-28 border-page-border items-center justify-center">
        {windowsReleaseVersion.releaseNote}
      </td>
      <td className="px-4 py-3 border-b w-28 border-page-border items-center justify-center">
        <span
          className="flex items-center cursor-pointer"
          onClick={DownloadWindowsReleaseVersion()}
        >
          <Download className="w-5 pr-2 text-gray-200" />
          <span>Download release</span>
        </span>
      </td>
    </tr>
  );
}
