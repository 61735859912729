import { UserInfoListDto, UserStatus } from "../../services";
import { StatusText, StatusType } from "../typography";
import { UserActionButton } from "../actionButtons";

type Props = {
    User: UserInfoListDto
}

export function UserListItem({ User }: Props) {

    const getStatusType = () => {
        switch (User.status) {
            case UserStatus.Active:
                return StatusType.Active;
            case UserStatus.Pending:
                return StatusType.Inactive;
            case UserStatus.Archived:
                return StatusType.Danger;
            default:
                return StatusType.Active;
        }
    }

    return (<tr key={User.email}>
        <td className="px-4 py-3 border-b border-page-border">
            <div className="flex items-end gap-2">
                {User.firstName}
            </div>
        </td>
        <td className="px-4 py-3 border-b border-page-border">
            {User.lastName}
        </td>
        <td className="px-4 py-3 border-b border-page-border">
            {User.email}
        </td>
        <td className="px-4 py-3 border-b border-page-border">
            {User.role === "ViewOnly" ? "View only" : User.role}
        </td>
        <td className="px-4 py-3 border-b border-page-border">
            {User.dateCreated?.toLocaleDateString()}
        </td>
        <td className="px-4 py-3 border-b border-page-border">
            <StatusText status={getStatusType()}>{User.status ?? ""}</StatusText>
        </td>
        <td className="px-4 py-3 border-b border-page-border">
            <UserActionButton user={User} />
        </td>
    </tr>);

}
