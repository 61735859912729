import { atom } from "recoil";
import { string } from "yup";

type Props = {
  resourceId: string | undefined;
  resourceType: "Asset" | "Playlist" | undefined;
  durationInSecounds: number;
  canvasId: string;
  canvaseName: string;
  scheduleStart: Date;
};

export const AddScheduleModalState = atom<Props | undefined>({
  key: "addScheduleModalState",
  default: undefined,
});
