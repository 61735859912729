import { UilEllipsisV } from "@iconscout/react-unicons";
import { useState } from "react";
import { Trash2, Edit } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { ModalTypeState, SelectedPlaylistState } from "../../atoms";
import { PlaylistListDto } from "../../services";
import { ModalTypes } from "../modal";

type Props = {
  playlist: PlaylistListDto;
};

export function PlaylistActionButton({ playlist }: Props) {
  const [selectedPlaylist, setSelectedPlaylist] = useRecoilState(
    SelectedPlaylistState
  );
  const [, setModalType] = useRecoilState(ModalTypeState);
  const [showOption, setShowOption] = useState(false);

  const navigate = useNavigate();
  let timeOutId: NodeJS.Timeout;

  const selectPlaylist = () => {
    if (selectedPlaylist?.id === playlist.id) {
      setSelectedPlaylist(null);
      setShowOption(false);
    } else {
      playlist?.id &&
        playlist.name &&
        setSelectedPlaylist({ id: playlist?.id, name: playlist.name });
      setShowOption(true);
    }
  };

  const handleDeleteClick = () => {
    setModalType(ModalTypes.DeletePlaylistModal);
  };

  const handleEditClick = () => {
    navigate("edit");
    // setModalType(ModalTypes.UpdatePlaylistModal);
  };

  const handleOnBlur = () => {
    timeOutId = setTimeout(() => {
      setShowOption(false);
    }, 100);
  };

  const handleOnFocus = () => {
    clearTimeout(timeOutId);
  };

  return (
    <div
      className="relative"
      tabIndex={1}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
    >
      <UilEllipsisV className="cursor-pointer" onClick={selectPlaylist} />
      {selectedPlaylist?.id === playlist.id && showOption && (
        <div
          className={`absolute top-1 z-10 right-4 rounded-md border border-page-border bg-dropdown min-w-max cursor-pointer`}
        >
          <ul className=" max-h-64 rounded-md overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
            <li>
              <label
                className="flex items-center rounded-md gap-2 p-2.5  cursor-pointer hover:bg-button-bg-primary-hover"
                onClick={handleEditClick}
              >
                <Edit className="w-4 text-gray-200" />
                <span>Edit playlist</span>
              </label>
            </li>
            <li>
              <label
                className="flex items-center rounded-md gap-2 p-2.5  text-button-bg-destructive-default cursor-pointer hover:bg-button-bg-primary-hover"
                onClick={handleDeleteClick}
              >
                <Trash2 className="w-4 " />
                <span>Delete playlist</span>
              </label>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
