import React from "react";
import ReactDOM from "react-dom";
import { useRecoilState } from "recoil";
import { ModalTypeState } from "../../atoms";
import { X } from "react-feather";
import { ModalTypes } from ".";

type Props = {
  id: ModalTypes;
  title: string;
  description: string;
  children?: JSX.Element;
  wideMode?: boolean | null;
  onClose?: () => void;
};

export function BaseModal({
  id,
  title,
  description,
  wideMode,
  children,
  onClose,
}: Props) {
  const [modalType, setModalType] = useRecoilState(ModalTypeState);

  const handleCloseClick = () => {
    setModalType(null);
    if (onClose) onClose();
  };

  return modalType === id
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="fixed w-full h-full top-0 left-0 z-20 bg-white bg-opacity-30 flex justify-center items-center overflow-auto py-4">
            <div
              className="w-full max-w-xl max-h-full"
              style={wideMode ? { maxWidth: "48rem" } : {}}
            >
              <div className="w-full p-5 bg-modal-bg  rounded-lg">
                <div className="flex justify-between items-start">
                  <div className="flex flex-col gap-3">
                    <h3 className="text-lg font-medium text-gray-50">
                      {title}
                    </h3>
                    <small className="font-normal text-gray-200">
                      {description}
                    </small>
                  </div>
                  <X
                    className="text-gray-200 cursor-pointer rounded-full hover:text-gray-50 hover:bg-Secondary-Input-bg  w-6 h-6"
                    onClick={handleCloseClick}
                  ></X>
                </div>
                <div>{children}</div>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;
}
