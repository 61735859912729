import { AssetListDto } from "../../services";
import { useState } from "react";
import { baseURL } from "../../constants/ApiConstans";
import { Image, Video } from "react-feather";
import { TimeDuration } from "../Misc";
import { Dragable } from "../Svgs";

type Props = {
  asset: AssetListDto;
  onAssetAddClick?: (asset: AssetListDto) => void;
  className?: string;
  onDragStart?: (e: React.DragEvent<HTMLLIElement> | undefined) => void;
  onDragEnter?: (e: React.DragEvent<HTMLLIElement> | undefined) => void;
  onDragEnd?: (e: React.DragEvent<HTMLLIElement> | undefined) => void;
  onDragOver?: (e: React.DragEvent<HTMLLIElement> | undefined) => void;
  defaultImageDurationInSecounds?: number;
};

export function PlaylistAssetSelectionItem({
  className,
  asset,
  onAssetAddClick,
  defaultImageDurationInSecounds,
  onDragStart,
  onDragEnter,
  onDragEnd,
  onDragOver,
}: Props) {
  const [thumbnailLink] = useState(
    baseURL +
      "/api/customer/" +
      asset.customerId +
      "/assets/" +
      asset.id +
      "/thumbnail/" +
      asset.thumbnailImage
  );
  const [isDragging, setIsDragging] = useState<boolean>();

  const handleDragStart = (event: React.DragEvent<HTMLLIElement>) => {
    if (onDragStart) {
      onDragStart(event);
      setIsDragging(true);
    }
  };

  const handleDragEnd = (event: React.DragEvent<HTMLLIElement>) => {
    if (onDragEnd) {
      onDragEnd(event);
      setIsDragging(false);
    }
  };

  const getAssetsDuration = (): number | undefined => {
    let defaultImageDuration =
      defaultImageDurationInSecounds === null ||
      defaultImageDurationInSecounds === undefined
        ? 30
        : defaultImageDurationInSecounds;

    return asset?.durationInSecounds === 0
      ? defaultImageDuration
      : asset?.durationInSecounds;
  };

  return (
    <li
      className="px-4 py-2 border-b border-page-border group-d"
      key={asset.id}
      draggable={true}
      onDragStart={handleDragStart}
      onDragEnter={(e) => onDragEnter && onDragEnter(e)}
      onDragEnd={handleDragEnd}
      onDragOver={(e) => onDragOver && onDragOver(e)}
    >
      {isDragging ? (
        <div className="h-12"></div>
      ) : (
        <div className="flex gap-4 items-center w-full overflow-hidden">
          <div className="flex flex-none items-center gap-2 w-20">
            <Dragable className="text-gray-50" />
            <img
              className="h-12 w-12 object-cover rounded"
              src={` ${thumbnailLink} `}
              alt="logo"
              draggable="false"
              onDragStart={(event) => event.preventDefault()}
            />
          </div>
          <div className="group overflow-hidden">
            <span className="text-gray-50 text-body_16 break-all">
              {asset.name}
            </span>
            <div className="flex justify-between">
              <div className="inline-flex py-1 items-center gap-2 text text-gray-300">
                <span>
                  {asset.type === "Image" ? (
                    <Image className="w-4 h-4 p-0 m-0" />
                  ) : (
                    <Video className="w-4 h-4 p-0 m-0" />
                  )}
                </span>
                <span>{asset.fileExtension}</span>
                <small className="w-1 h-1 bg-white rounded-full "></small>
                <TimeDuration
                  nameId={"timeduration" + asset.id}
                  totalSecounds={getAssetsDuration()}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </li>
  );
}
