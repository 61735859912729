import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CanvasListDto, CanvasesClient, UpdateCanvasDto } from "../../services";
import { GetNewInstance, Store } from "../../helpers";
import * as yup from "yup";
import { useRecoilState } from "recoil";
import { SelectedCanvasState } from "../../atoms";
import { useEffect, useRef, useState } from "react";
import { SpinnerButton } from "../buttons";
import { toast } from "react-toastify";

const updateCanvasRequestSchema = yup
  .object({
    description: yup.string().required("Description is required"),
    ipAddress: yup.string().required("IpAddress is required"),
    resolution: yup.string().required("Resolution is required"),
    siteId: yup
      .number()
      .typeError("Site number must be a positive number")
      .positive("Site number must be a positive number")
      .integer("Site number must be a number"),
    multiplexNumber: yup
      .number()
      .integer("Layer group must be a number")
      .typeError("Layer group must be a positive number")
      .min(1, "Layer group must be 1 or greater"),
  })
  .required();

type Props = {
  onSuccess: () => void;
  onFailure: () => void;
};

export function UpdateCanvasForm({ onSuccess, onFailure }: Props) {
  const [selectedCanvas, setSelectedCanvas] =
    useRecoilState<CanvasListDto | null>(SelectedCanvasState);
  const [showSpinner, setShowSpinner] = useState(false);

  const canvasId = useRef<string>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateCanvasDto>({
    resolver: yupResolver(updateCanvasRequestSchema),
  });

  const client = new CanvasesClient(undefined, GetNewInstance());
  const usersCustomerId = Store.getCustomerId();

  useEffect(() => {
    canvasId.current = selectedCanvas?.id;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (request: UpdateCanvasDto) => {
    let resolutionSplit = request.resolution?.split("x");

    if (resolutionSplit?.length === 1) {
      resolutionSplit = request.resolution?.split("X");
    }

    request.widthResolution = resolutionSplit
      ? parseInt(resolutionSplit[0])
      : 0;
    request.heightResolution = resolutionSplit
      ? parseInt(resolutionSplit[1])
      : 0;
    request.id = canvasId.current;
    request.name = selectedCanvas?.name;

    usersCustomerId &&
      client
        .updateCanvas(usersCustomerId, request)
        .then((response) => {
          toast.success("Canvas updated successfully");
          setShowSpinner(false);
          setSelectedCanvas(null);
          onSuccess();
        })
        .catch((error) => {
          setShowSpinner(false);
          console.log(error);
        });
    setShowSpinner(true);
  };

  return (
    <form className="py-4 text-sm text-white">
      <div className="my-4">
        <label className="flex flex-col">
          <span>Canvas name</span>
          <input
            {...register("name")}
            className="border-page-border bg-white bg-opacity-5 p-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
            defaultValue={selectedCanvas?.name}
            type="text"
            disabled
          />
          <small className="text-red-600">{errors.name?.message}</small>
        </label>
      </div>
      <div className="my-4">
        <label className="flex flex-col">
          <span>Canvas description</span>
          <textarea
            {...register("description")}
            className="border-page-border bg-white bg-opacity-5 p-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
            defaultValue={selectedCanvas?.description}
          />
          <small className="text-red-600">{errors.description?.message}</small>
        </label>
      </div>
      <div className="my-4">
        <div className="flex flex-row justify-between gap-5">
          <label className="flex flex-col w-1/6">
            <span>Site number</span>
            <input
              {...register("siteId")}
              className="border-page-border bg-white bg-opacity-5 p-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
              type="text"
              defaultValue={selectedCanvas?.siteId}
            />
            <small className="text-red-600">{errors.siteId?.message}</small>
          </label>
          <label className="flex flex-col w-1/6">
            <span>Layer group</span>
            <input
              {...register("multiplexNumber")}
              className="border-page-border bg-white bg-opacity-5 p-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
              type="text"
              defaultValue={selectedCanvas?.multiplexNumber}
            />
            <small className="text-red-600">
              {errors.multiplexNumber?.message}
            </small>
          </label>
          <label className="flex flex-col w-2/3">
            <span>IP address</span>
            <input
              {...register("ipAddress")}
              className="border-page-border bg-white bg-opacity-5 p-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
              type="text"
              defaultValue={selectedCanvas?.ipAddress}
            />
            <small className="text-red-600">{errors.ipAddress?.message}</small>
          </label>
        </div>
      </div>
      <div className="my-4">
        <label className="flex flex-col">
          <span>Resolution</span>
          <input
            {...register("resolution")}
            className="border-page-border bg-white bg-opacity-5 p-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
            defaultValue={
              selectedCanvas?.widthResolution +
              "x" +
              selectedCanvas?.heightResolution
            }
            type="text"
            placeholder="1920x1080"
          />
          <small className="text-red-600">{errors.resolution?.message}</small>
        </label>
      </div>
      <div className="mt-8 flex justify-end gap-4">
        <button
          onClick={onFailure}
          className="py-2.5 px-4 rounded-md border border-page-border bg-white bg-opacity-5 hover:bg-opacity-10"
        >
          Cancel
        </button>
        <SpinnerButton
          className={`py-2.5 px-4 rounded-md bg-button-bg-primary-default hover:bg-button-bg-primary-hover active:shadow-button_primary ${
            showSpinner ? "disabled" : ""
          } `}
          showSpinner={showSpinner}
          onClick={handleSubmit(onSubmit)}
        >
          Update canvas
        </SpinnerButton>
      </div>
    </form>
  );
}
