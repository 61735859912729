import { BaseModal } from "./BaseModal";
import { AddScheduleForm } from "../form";
import { ModalTypes } from "./ModalTypes";
import { CanvasListDto } from "../../services";
import { useRecoilState } from "recoil";
import { AddScheduleModalState } from "../../atoms";

type Props = {
    onSuccess: () => void;
    onFailure?: () => void;
}

export function AddScheduleModal(props: Props) {
    const [addScheduleInfo, ] = useRecoilState(AddScheduleModalState);

    return (
        < BaseModal id={ModalTypes.AddScheduleModal} title='Add to schedule' description={"Specify date and time for your "+ addScheduleInfo?.resourceType?.toString().toLowerCase()}>
            <AddScheduleForm {...props} />
        </BaseModal>


    );
}