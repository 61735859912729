import { ScheduleHeader } from "../components/layouts";
import { Schedule } from "../components/mainContent";
import { LogoutModal } from "../components/modal";
import { CustomerSidebar } from "../components/sidebar";

export function SchedulePage() {
  return (
    <div>
      <div className="h-screen w-full flex bg-bg text-white overflow-hidden">
        <CustomerSidebar />
        <div className="h-screen w-full flex flex-col ">
          <ScheduleHeader />
          <Schedule />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}
