import { PlaylistListDto } from "../../services";
import { Color } from "../../constants";
import { NameLogo, TimeDuration } from "../Misc";
import { Dragable } from "../Svgs";
import { useState } from "react";

type Props = {
  playlist: PlaylistListDto;
  draggable?: boolean;
};

export function SearchedPlaylistItem({ playlist, draggable }: Props) {
  const typedColorString = playlist.color as keyof typeof Color;
  const [isDragging, setIsDragging] = useState<boolean>();

  const handleDragStart = (event: any) => {
    event.dataTransfer.effectAllowed = "move";

    let item = {
      id: new Date(),
      type: "box",
      content: "Placeholder",
      resourceId: playlist?.id,
      resourceType: "Playlist",
      canvasIds: playlist?.canvasIds,
      durationInSecounds: playlist?.totalDurationInSecound,
    };
    event.dataTransfer.setData("text", JSON.stringify(item));
  };

  return (
    <tr
      key={playlist.id}
      draggable={draggable}
      onDrag={() => setIsDragging(true)}
      onDragStart={handleDragStart}
      onDragEnd={() => setIsDragging(false)}
    >
      {isDragging ? (
        <td className="px-4 py-2 border-b border-page-border group-d">
          <div className="h-12"></div>
        </td>
      ) : (
        <td className="px-4 py-2 border-b border-page-border">
          <div className="flex w-full gap-4 items-center">
            <div className="flex flex-row items-center gap-2">
              {draggable && <Dragable className="text-gray-50" />}
              <NameLogo
                className="w-12 h-12 text-body_16 object-cover text-Palette-dark-1 rounded-sm cursor-pointer"
                color={Color[typedColorString]}
                name={playlist.name ?? ""}
                letterCount={2}
              />
            </div>
            <div className="w-full">
              <p className="text-gray-50 truncate text-body_16">
                {playlist.name}
              </p>
              <div className="flex justify-between">
                <div className="inline-flex py-1 items-center gap-2 text text-gray-300">
                  <span>{playlist.assetsCount + " assets"}</span>
                  <small className="w-1 h-1 bg-white rounded-full "></small>
                  <TimeDuration
                    nameId={"timeduration" + playlist.id}
                    totalSecounds={playlist.totalDurationInSecound}
                  />
                </div>
              </div>
            </div>
          </div>
        </td>
      )}
    </tr>
  );
}
