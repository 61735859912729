import AssetList from "../components/mainContent/AssetList";
import AssetListHeader from "../components/layouts/AssetListHeader";
import { LogoutModal } from "../components/modal";
import { CustomerSidebar } from "../components/sidebar";

export function AssetListPage() {

    return (
        // <BasePageLayout title="Assets" buttonText="Upload asset" icon_style={IconStyle.None} icon={<Icon.Camera />} showButton={loggedInUser?.role === UserRole.Admin || loggedInUser?.role === UserRole.Editor || loggedInUser?.role === UserRole.SuperAdmin} >
        //     <AssetList />
        // </BasePageLayout>

        <div className="flex">
            <div className="h-screen w-full flex bg-bg text-white">
                <CustomerSidebar />
                <div className="h-screen w-full flex flex-col">
                    <AssetListHeader />
                    <AssetList />
                </div>
            </div>
            <LogoutModal />
        </div>

    );
}