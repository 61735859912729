import { LoginRequest } from "../datas";
import axios from "axios";
import { baseURL } from "../constants";

const signin = (data: LoginRequest) => {
  const authAxios = axios.create({
    baseURL: baseURL,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    transformRequest(data) {
      const params = new URLSearchParams();
      params.append("username", data.username);
      params.append("password", data.password);
      params.append("grant_type", "password");
      params.append("client_id", "api");
      params.append("scope", "api");
      params.append("client_secret", "846B62D0-DEF9-4215-A99D-86E6B8DAB342");

      return params;
    },
  });

  return authAxios.post("connect/token", data);
};

export const AuthService = {
  signin,
};
