import { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import {
  DataGroupCollectionType,
  DataItem,
  DataItemCollectionType,
  TimelineItem,
  TimelineOptions,
} from "vis-timeline";
import { ShowContextMenuState } from "../../atoms/ShowContextMenuState";
import * as VisTimeline from "vis-timeline/standalone";
import * as VisData from "vis-data";
import {
  AddScheduleModalState,
  ModalTypeState,
  ScheduleZoomInState,
  ScheduleZoomOutState,
  SelectedScheduleState,
} from "../../atoms";
import ReactDOM from "react-dom";
import renderToStaticMarkup from "react-dom/server";
import {
  AddScheduleModal,
  DeleteSchedulModal,
  ModalTypes,
  UpdateScheduleModal,
} from "../modal";
import {
  AssetStatus,
  CanvasBasicDto,
  CanvasListDto,
  DayOfWeek,
  RecurringType,
  ScheduleListDto,
  ScheduleResourceType,
  SchedulesClient,
  UpdateScheduleDto,
  UserInfoListDto,
} from "../../services";
import { GetNewInstance, Store } from "../../helpers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { SelectedCalanderDateState } from "../../atoms/SelectedCalanderDateState";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import "./ScheduleTimeline.css";
import ScheduleTimelinePlaylistItem from "./ScheduleTimelinePlaylistItem";
import ScheduleTimelineAssetItem from "./ScheduleTimelineAssetItem";
import ScheduleTimelineTooltipItem from "./ScheduleTimelineTooltipItem";
import { toast } from "react-toastify";
import { ScheduleContextMenu } from "../actionButtons";
import { RoleCheck } from "../auth";

type props = {
  loggedInUser: UserInfoListDto | null;
};

export function ScheduleTimeline({ loggedInUser }: props) {
  const [zoomIn, setZoomIn] = useRecoilState(ScheduleZoomInState);
  const [zoomOut, setZoomOut] = useRecoilState(ScheduleZoomOutState);
  const [, setSelectedSchedule] = useRecoilState(SelectedScheduleState);
  const [, setAddScheduleInfoState] = useRecoilState(AddScheduleModalState);

  let TimelineObject = useRef<VisTimeline.Timeline>();
  const addScheduleInfo = useRef<any>();
  const scheduleClient = new SchedulesClient(undefined, GetNewInstance());
  const usersCustomerId = Store.getCustomerId();
  const schedules = useRef<ScheduleListDto[]>([]);
  const canvases = useRef<CanvasBasicDto[]>([]);
  const timlineNode = useRef<HTMLDivElement>(null);
  dayjs.extend(duration);
  dayjs.extend(isBetween);
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const [, setContextMenuState] = useRecoilState(ShowContextMenuState);
  const [, setModalType] = useRecoilState(ModalTypeState);
  const [selectedCalendarDate, setSelectedCalendarDate] = useRecoilState(
    SelectedCalanderDateState
  );

  const currentCustomer = Store.getCurrentCustomer();

  const scheduleDataDate = useRef<dayjs.Dayjs>(
    selectedCalendarDate ? dayjs(selectedCalendarDate) : dayjs()
  );
  useEffect(() => {
    setContextMenuState({ isPlaylist: true, isShown: false, xPos: 0, yPos: 0 });

    setSelectedCalendarDate(scheduleDataDate.current.toDate());

    return () => {
      setSelectedCalendarDate(null);
      setContextMenuState({
        xPos: 0,
        yPos: 0,
        isShown: false,
        isPlaylist: false,
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retrieveTimeLineData = () => {
    usersCustomerId &&
      scheduleClient
        .getSchedules(
          usersCustomerId,
          scheduleDataDate.current.subtract(7, "day").toDate(),
          scheduleDataDate.current.add(7, "day").toDate()
        )
        .then((response) => {
          if (response && response.data) {
            canvases.current = response?.data.canvases ?? [];
            schedules.current = populateRecuringSchedules(
              response?.data.schedules ?? []
            );
            updateTimlineDatas();
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };

  const updateTimlineDatas = () => {
    let screens: DataGroupCollectionType = canvases.current.map((x, i) => ({
      id: x.id,
      content: x.name,
    })) as DataGroupCollectionType;

    let emptyScreens: DataGroupCollectionType = canvases.current.map(
      (x, i) => ({
        id: x.id,
        content: "",
      })
    ) as DataGroupCollectionType;

    let scheduleItems = schedules.current.map((x) => ({
      id: x.id,
      className: "schedule " + x.color?.toLowerCase(),
      title: x.name,
      content: x.name,
      start: x.startDate,
      end: x.endDate,
      group: x.canvasId,
      subgroup: "sch",
    }));

    let screenItems = screens.map((screen) => ({
      id: `Canvas_${screen.id}`,
      subgroup: "screen",
      group: screen.id,
      content: screen.content,
      start: "0000-01-01",
      end: "9999-01-01",
      className: "screen",
      editable: false,
    }));
    let items = new VisData.DataSet([
      ...screenItems,
      ...scheduleItems,
    ]) as DataItemCollectionType;

    if (!TimelineObject.current && canvases.current.length > 0) {
      initializeTimeline(items, emptyScreens);
      (window as any).TimelineObject = TimelineObject.current;
    } else {
      console.log(items);
      TimelineObject.current && TimelineObject.current?.setItems(items);
    }
  };

  const getScheduleById = (
    id: string | undefined
  ): ScheduleListDto | undefined => {
    let selected = schedules.current.find(
      (x) => x.id?.split("_Recuring_")[0] === id?.split("_Recuring_")[0]
    );
    if (selected) {
      selected.id = selected.id?.split("_Recuring_")[0];
      return selected;
    }
    return undefined;
  };

  const getCanvasById = (id: string | undefined): CanvasListDto | undefined => {
    return canvases.current.find((x) => x.id === id);
  };

  const handleScheduleMove = (
    item: TimelineItem,
    callback: (item: TimelineItem | null) => void
  ) => {
    let movedSchedule = getScheduleById(item.id as string);
    if (movedSchedule && movedSchedule?.startDate && movedSchedule?.endDate) {
      movedSchedule.canvasId = getCanvasById(item.group as string)?.id;
      movedSchedule.startDate = new Date(item.start);
      movedSchedule.endDate = new Date(item.end as string);

      let updateScheduleRequest = new UpdateScheduleDto();

      updateScheduleRequest.id = movedSchedule.id;
      updateScheduleRequest.canvasId = movedSchedule.canvasId;
      updateScheduleRequest.startDate = movedSchedule.startDate;
      updateScheduleRequest.endDate = movedSchedule.endDate;
      updateScheduleRequest.recurringDays = movedSchedule.recurringDays;
      updateScheduleRequest.recurringType = movedSchedule.recurringType;
      updateScheduleRequest.recurringStartDateTime =
        movedSchedule.recurringStartDateTime;
      updateScheduleRequest.recurringEndDateTime =
        movedSchedule.recurringEndDateTime;

      usersCustomerId &&
        scheduleClient
          .updateSchedule(usersCustomerId, updateScheduleRequest)
          .then((response) => {
            toast.success("Schedule updated successfully");
            retrieveTimeLineData();
          })
          .catch((error) => {
            console.log(error);
          });
    }
  };

  const handleScheduleAdd = (
    item: any,
    callback: (item: TimelineItem | null) => void
  ) => {
    try {
      const selectedCanvas = getCanvasById(item.group);
      let canvasIds: string[] = item?.canvasIds;
      if (!canvasIds.includes(item.group)) {
        toast.error(
          item?.resourceType +
            " has not been configured for " +
            selectedCanvas?.name
        );
        return;
      }

      if (item.status === AssetStatus.Pending)
        toast.warning(
          `${item.name} is still being pushed to your connected devices. You can still schedule this asset, but it will not play until the download process is completed. Check the asset details screen to view the download status for this asset.`
        );

      addScheduleInfo.current = {
        resourceId: item?.resourceId,
        resourceType: item?.resourceType,
        durationInSecounds: item?.durationInSecounds,
        scheduleStart: new Date(item.start),
        canvasId: item.group,
        canvaseName: selectedCanvas?.name,
      };
      setAddScheduleInfoState(addScheduleInfo.current);
      setModalType(ModalTypes.AddScheduleModal);
    } catch (error) {
      console.log(error);
    }
  };

  const getScheduleTemplate = (
    item?: DataItem,
    element?: any,
    data?: any
  ): any => {
    if (!item) {
      return "";
    }

    if (item.className === "screen") {
      return item.content;
    }

    const schedule = getScheduleById(item.id?.toString());

    ReactDOM.unmountComponentAtNode(element);
    let render =
      schedule?.resourceType === ScheduleResourceType.Playlist
        ? renderToStaticMarkup.renderToString(
            <ScheduleTimelinePlaylistItem data={item} schedule={schedule} />
          )
        : renderToStaticMarkup.renderToString(
            <ScheduleTimelineAssetItem data={item} schedule={schedule} />
          );

    return render;
  };

  const getTooltipTemplate = (
    item?: DataItem,
    element?: any,
    data?: any
  ): any => {
    if (!item) {
      return "";
    }

    if (item.className === "screen") {
      return item.content;
    }

    const schedule = getScheduleById(item.id?.toString());

    if (!schedule) return "";

    let render = renderToStaticMarkup.renderToString(
      <ScheduleTimelineTooltipItem data={item} schedule={schedule} />
    );

    return render;
  };

  const options: TimelineOptions = {
    orientation: "top",
    start: scheduleDataDate.current
      .toDate()
      .setHours(scheduleDataDate.current.hour() - 7),
    end: scheduleDataDate.current
      .toDate()
      .setHours(scheduleDataDate.current.hour() + 7),
    editable: {
      add: RoleCheck.isEditorRoleMinimum(loggedInUser),
      updateTime: RoleCheck.isEditorRoleMinimum(loggedInUser),
      updateGroup: RoleCheck.isEditorRoleMinimum(loggedInUser),
      overrideItems: false,
    },
    margin: {
      item: 0,
    },
    showTooltips: true,
    multiselect: true,
    // onDropObjectOnItem: handleSchedlueAddOnItem,
    onAdd: handleScheduleAdd,
    onMove: handleScheduleMove,
    template: getScheduleTemplate,
    groupTemplate: () => "",
    stack: false,
    stackSubgroups: true,
    zoomMin: 50000,
    zoomMax: 150000000,
    tooltip: {
      followMouse: false,
      overflowMethod: "cap",
      template: getTooltipTemplate,
    },
    // zoomable: false,
    horizontalScroll: true,
    zoomKey: "altKey",
    // verticalScroll: true,
    moment: function (date: any) {
      // Show in customer timezone
      return VisTimeline.moment(date).utcOffset(
        currentCustomer?.timeZoneOffset ?? "+00:00"
      );
    },
  };

  const initializeTimeline = (
    items: DataItemCollectionType,
    groups: DataGroupCollectionType
  ) => {
    if (timlineNode.current) {
      TimelineObject.current = new VisTimeline.Timeline(
        timlineNode.current,
        items,
        groups,
        options
      );
      TimelineObject.current.on("contextmenu", (props: any) => {
        props.event.preventDefault();
        const schedule = getScheduleById(props.item);
        if (schedule) {
          setSelectedSchedule({
            ...schedule,
            canvaseName: getCanvasById(schedule.canvasId)?.name,
          });
          setContextMenuState({
            xPos: props.pageX,
            yPos: props.pageY,
            isShown: true,
            isPlaylist:
              schedule?.resourceType === ScheduleResourceType.Playlist,
          });
        } else {
          setContextMenuState({
            xPos: 0,
            yPos: 0,
            isShown: false,
            isPlaylist: false,
          });
        }
      });
      TimelineObject.current.on("rangechanged", (props: any) => {
        if (props.byUser) {
          const startDay = dayjs(props.start).tz(currentCustomer?.timeZoneIANA);
          const endDay = dayjs(props.end).tz(currentCustomer?.timeZoneIANA);
          if (
            !startDay.isBetween(
              scheduleDataDate.current.subtract(6, "day"),
              scheduleDataDate.current.add(6, "day")
            )
          ) {
            scheduleDataDate.current = startDay;
            retrieveTimeLineData();
          } else if (
            !endDay.isBetween(
              scheduleDataDate.current.subtract(6, "day"),
              scheduleDataDate.current.add(6, "day")
            )
          ) {
            scheduleDataDate.current = endDay;
            retrieveTimeLineData();
          }
        }
      });
    }
  };

  const populateRecuringSchedules = (data: ScheduleListDto[]) => {
    const noneRecurringSchedules = data.filter(
      (x) => x.recurringType === RecurringType.None
    );

    const recuringSchedules = data.filter(
      (x) => x.recurringType !== RecurringType.None
    );
    const repeatedSchedules: ScheduleListDto[] = [];
    for (let i = 0; i < 14; i++) {
      const currentDate = scheduleDataDate.current
        .tz(currentCustomer?.timeZoneIANA)
        .add(i, "day")
        .subtract(7, "day");
      const currentDay = Object.values(DayOfWeek)[currentDate.day()];

      recuringSchedules.forEach((x) => {
        if (
          (x.recurringType === RecurringType.Daily ||
            x.recurringDays?.includes(currentDay)) &&
          x.startDate &&
          x.endDate &&
          x.recurringStartDateTime &&
          x.recurringStartDateTime < currentDate.endOf("day").toDate() &&
          (!x.recurringEndDateTime ||
            x.recurringEndDateTime > currentDate.toDate())
        ) {
          const startDay = dayjs(x.startDate).tz(currentCustomer?.timeZoneIANA);
          const endDay = dayjs(x.endDate).tz(currentCustomer?.timeZoneIANA);
          const durationInSecound = dayjs
            .duration(endDay.diff(startDay))
            .asSeconds();
          const newStartDate = dayjs(
            new Date(
              currentDate.year(),
              currentDate.month(),
              currentDate.date(),
              startDay.hour(),
              startDay.minute(),
              startDay.second()
            )
          )
            .tz(currentCustomer?.timeZoneIANA, true)
            .toDate();

          const newEndDate = dayjs(newStartDate)
            .add(durationInSecound, "second")
            .toDate();

          const dailySchedule: ScheduleListDto = {
            ...x,
            id: x.id + "_Recuring_" + i,
            startDate: newStartDate,
            endDate: newEndDate,
            init: function (_data?: any): void {
              throw new Error("Function not implemented.");
            },
            toJSON: function (data?: any) {
              throw new Error("Function not implemented.");
            },
          };
          repeatedSchedules.push(dailySchedule);
        }
      });
    }
    return [...noneRecurringSchedules, ...repeatedSchedules];
  };

  useEffect(() => {
    if (selectedCalendarDate === null) return;

    TimelineObject.current &&
      TimelineObject.current.setWindow(
        dayjs(selectedCalendarDate).add(-3, "hour").toDate(),
        dayjs(selectedCalendarDate).add(4, "hour").toDate()
      );

    scheduleDataDate.current = dayjs(selectedCalendarDate).tz(
      currentCustomer?.timeZoneIANA
    );
    retrieveTimeLineData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCalendarDate]);

  useEffect(() => {
    if (zoomIn) {
      TimelineObject.current && TimelineObject.current.zoomIn(zoomIn);
      setZoomIn(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomIn]);

  useEffect(() => {
    if (zoomOut) {
      TimelineObject.current && TimelineObject.current.zoomOut(zoomOut);
      setZoomOut(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomOut]);

  return (
    <div>
      <div ref={timlineNode}></div>

      <ScheduleContextMenu />
      <AddScheduleModal onSuccess={retrieveTimeLineData} />
      <UpdateScheduleModal onSuccess={retrieveTimeLineData} />
      <DeleteSchedulModal onSuccess={retrieveTimeLineData} />
    </div>
  );
}
