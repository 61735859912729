import { Color } from "../../constants";
import { UilCheck } from "@iconscout/react-unicons";

type Props = {
  currentColor: Color;
  onSelected: (selectedColor: Color) => void;
};

export function ColorInput({ currentColor, onSelected }: Props) {
  const colors = Object.entries(Color).slice(-6);

  return (
    <div className="flex gap-2 justify-start flex-row flex-none text-black">
      {colors.map((x, i) => (
        <div
          key={i}
          onClick={() => onSelected(x[1] as Color)}
          className={`rounded-full cursor-pointer h-6 w-6 
                    ${x[1] === Color.Red ? " bg-Palette-1" : ""}
                    ${x[1] === Color.Orange ? " bg-Palette-2" : ""}
                    ${x[1] === Color.Lime ? " bg-Palette-3" : ""}
                    ${x[1] === Color.Green ? " bg-Palette-4" : ""}
                    ${x[1] === Color.Teal ? " bg-Palette-5" : ""}
                    ${
                      x[1] === Color.Blue ? " bg-Palette-6" : ""
                    }                        
                        `}
        >
          <UilCheck
            className={` ${currentColor === x[1] ? "visible" : "invisible"}`}
          />
        </div>
      ))}
    </div>
  );
}
