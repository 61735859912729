import { SpinnerButton } from "../buttons";
import { useRecoilState } from "recoil";
import { Link, useNavigate } from "react-router-dom";
import { UilFileAlt, UilEllipsisV } from "@iconscout/react-unicons";
import { Download, Trash2, X } from "react-feather";
import { SelectedAssetState } from "../../atoms/SelectedAssetState";
import { useState } from "react";
import { AssetsClient, UpdateAssetDto } from "../../services";
import { GetNewInstance, Store } from "../../helpers";
import { AssetUpdateState } from "../../atoms/AssetUpdateState";
import { toast } from "react-toastify";
import { DeleteAssetModal, ModalTypes } from "../modal";
import { ModalTypeState } from "../../atoms";
import { RequireEditorRoleMinimum } from "../auth";

export default function AssetDetailHeader() {
  const [selectedAsset] = useRecoilState(SelectedAssetState);
  const [showOption, setShowOption] = useState(false);
  const [assetUpdate] = useRecoilState(AssetUpdateState);
  const [showSpinner, setShowSpinner] = useState(false);
  const [, setModalType] = useRecoilState(ModalTypeState);

  const client = new AssetsClient(undefined, GetNewInstance());
  const loggedInUser = Store.getLoggedInUser();
  const customerId = Store.getCustomerId();
  const navigate = useNavigate();

  let timeOutId: NodeJS.Timeout;

  function downloadAsset(customerId: string, assetId: string) {
    client.getDownloadAssetDataUrl(customerId, assetId).then((x) => {
      if (x) {
        const link = document.createElement("a");
        link.href = x;
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  }

  const handleDeleteAsset = (e: React.MouseEvent<HTMLLabelElement>) => {
    e.stopPropagation();

    setModalType(ModalTypes.DeleteAssetModal);
  };

  const toggleAssetOptions = () => {
    setShowOption(!showOption);
  };
  const handleSubmitChanges = () => {
    customerId &&
      selectedAsset &&
      client
        .updateAsset(customerId, new UpdateAssetDto(assetUpdate))
        .then((x) => {
          toast.success("Asset updated successfully");
          setShowSpinner(false);
        });
    setShowSpinner(true);
  };

  const handleOnBlur = () => {
    timeOutId = setTimeout(() => {
      setShowOption(false);
    }, 100);
  };

  const handleOnFocus = () => {
    clearTimeout(timeOutId);
  };

  const handleAssetLog = (e: React.MouseEvent<HTMLLabelElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setModalType(ModalTypes.AssetAuditLogModal);
  };

  return (
    <div className="h-17 flex-none flex justify-between items-center p-5 border-b border-page-border">
      <div>
        <div className="font-medium text-h3">
          <Link to="/asset">Assets</Link>
        </div>
      </div>

      <div
        className="flex  items-center gap-x-4 py-0"
        tabIndex={1}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
      >
        <RequireEditorRoleMinimum user={loggedInUser}>
          <>
            <div>
              <UilEllipsisV
                onClick={toggleAssetOptions}
                className="cursor-pointer"
              />
              {showOption && (
                <ul className=" focus text-sm rounded-lg bg-gray-800 absolute top-12 right-60 z-10">
                  <li onClick={(e) => e.preventDefault}>
                    <span
                      className="flex items-center gap-3 cursor-pointer"
                      onClick={() =>
                        downloadAsset(customerId ?? "", selectedAsset ?? "")
                      }
                    >
                      <label className="flex items-center rounded-md gap-2 p-2.5  cursor-pointer hover:bg-button-bg-primary-hover">
                        <Download className="w-4 text-gray-200" />
                        <span>Download asset</span>
                      </label>
                    </span>
                  </li>
                  <li>
                    <label
                      className="flex items-center rounded-md gap-2 p-2.5  cursor-pointer hover:bg-button-bg-primary-hover"
                      onClick={handleAssetLog}
                    >
                      <UilFileAlt className="w-4 text-gray-200" />
                      <span>View log</span>
                    </label>
                  </li>
                  <li>
                    <label
                      className="flex items-center rounded-md gap-2 p-2.5  text-button-bg-destructive-default cursor-pointer hover:bg-button-bg-primary-hover"
                      onClick={handleDeleteAsset}
                    >
                      <Trash2 className="w-4" />
                      <span>Delete asset</span>
                    </label>
                  </li>
                </ul>
              )}
            </div>
            <div>
              <SpinnerButton
                className={`pt-3 pb-3 pr-5 pl-5 rounded-md bg-button-bg-primary-default hover:bg-button-bg-primary-hover active:shadow-button_primary ${
                  showSpinner ? "disabled" : ""
                } `}
                showSpinner={showSpinner}
                onClick={handleSubmitChanges}
              >
                Save changes
              </SpinnerButton>
            </div>
          </>
        </RequireEditorRoleMinimum>
        <div className="border-l-button-bg-secondary-500 px-3 border-l-2">
          <Link to="/asset">
            <X />
          </Link>
        </div>
      </div>

      <DeleteAssetModal
        onSuccess={() => {
          navigate("/asset");
        }}
      />
    </div>
  );
}
