import { atom } from "recoil";

type SelectedAssetDetail = {
  assetId: string | undefined;
  assetName: string | undefined;
  assetIds: string[];
};

export const AssetDetailState = atom<SelectedAssetDetail | null>({
  key: "assetDetailState",
  default: null,
});
