import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { PlaylistAssetListItem } from ".";
import { SelectedCalanderDateState, SelectedPlaylistState } from "../../atoms";
import { Color } from "../../constants";
import { GetNewInstance, Store } from "../../helpers";
import {
  AssetPlaylistListDto,
  AssetsClient,
  PlaylistDetailDto,
  PlaylistScheduleDto,
  PlaylistsClient,
  SchedulesClient,
} from "../../services";
import { NameLogo, TimeDuration } from "../Misc";
import { UilAngleRightB } from "@iconscout/react-unicons";
import { DeletePlaylistAssetModal } from "../modal";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export function PlaylistDetail() {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const [selectedPlaylist] = useRecoilState(SelectedPlaylistState);
  const [color, setColor] = useState<keyof typeof Color>("Red");
  const [playlist, setPlaylist] = useState<PlaylistDetailDto | null>(null);
  const [assets, setAssets] = useState<AssetPlaylistListDto[]>([]);
  const [scheduleTime, setScheduleTime] = useState<Date>(new Date());
  const [schedules, setSchedules] = useState<PlaylistScheduleDto[]>([]);
  const [showLoadMoreSchedule, setShowLoadMoreSchedule] =
    useState<boolean>(true);
  const [, setSelectedCalendarDate] = useRecoilState(SelectedCalanderDateState);

  const schedulePageLimit = 5;
  const usersCustomerId = Store.getCustomerId();
  const currentCustomer = Store.getCurrentCustomer();
  const loggedInUser = Store.getLoggedInUser();
  const navigate = useNavigate();

  const retrieveAssets = () => {
    const assetClient = new AssetsClient(undefined, GetNewInstance());

    selectedPlaylist &&
      usersCustomerId &&
      assetClient
        .getPlaylistsAssets(usersCustomerId, selectedPlaylist.id)
        .then((x) => {
          x?.data && setAssets(x?.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
  };

  useEffect(() => {
    const schedulClient = new SchedulesClient(undefined, GetNewInstance());
    usersCustomerId &&
      selectedPlaylist?.id &&
      schedulClient
        .getPlaylistsSchedules(
          usersCustomerId,
          selectedPlaylist?.id,
          scheduleTime,
          schedulePageLimit
        )
        .then((response) => {
          response?.data && setSchedules([...schedules, ...response?.data]);
          setShowLoadMoreSchedule(response?.data?.length === schedulePageLimit);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleTime]);

  useEffect(() => {
    const client = new PlaylistsClient(undefined, GetNewInstance());
    selectedPlaylist === null && navigate("/playlist");

    selectedPlaylist &&
      usersCustomerId &&
      client
        .getPlaylistById(usersCustomerId, selectedPlaylist.id)
        .then((response) => {
          response?.data &&
            setColor(response?.data.color as keyof typeof Color);
          response?.data && setPlaylist(response?.data);
        })
        .catch((error) => {
          console.log(error);
        });

    retrieveAssets();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScheduleSelection = (schedule: PlaylistScheduleDto) => {
    setSelectedCalendarDate(schedule?.startDate ?? new Date());
    navigate("/schedules");
  };

  const handleLoadMoreSchedule = () => {
    const lastSchedule = schedules[schedules.length - 1];
    if (lastSchedule?.endDate) setScheduleTime(lastSchedule?.endDate);
    else setShowLoadMoreSchedule(false);
  };

  if (playlist === null) return <></>;
  else
    return (
      <div className="h-full overflow-auto p-5 gap-40 scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
        <div className=" flex justify-between border-b pb-5 border-page-border ">
          <div className="flex gap-3 items-center w-2/3">
            <div>
              <NameLogo
                className="text-Palette-dark-1 h-27 w-27 rounded-xl text-6xl"
                color={Color[color]}
                name={playlist?.name ?? ""}
                letterCount={2}
              />
            </div>
            <div className="">
              <div className="text-gray-50 text-xl justify-start">
                {playlist?.name}
              </div>
              <div className="text-gray-100 text-body_14">
                {playlist?.description}
              </div>
              <div className="flex items-center gap-2 text-gray-100 pt-2">
                <span className="flex items-center gap-2">
                  {playlist?.assetsCount + " assets"}
                </span>
                <small className="w-1 h-1 bg-white rounded-full "></small>
                <TimeDuration
                  nameId={"timeduration_detail_playlist" + playlist?.id}
                  totalSecounds={playlist?.totalDurationInSecound}
                  showLongformat
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between">
            <div className="">{/* button */}</div>
            <div className="flex items-center gap-2 text-gray-100 text-body_14">
              <span>Transition duration: </span>
              <TimeDuration
                nameId={"timeduration_transiontion" + playlist?.id}
                totalSecounds={playlist?.transitionDurationInSecounds}
                showLongformat
              />
            </div>
          </div>
        </div>
        <div className=" flex justify-between h-full w-full  pt-8 overflow-hidden">
          <div className="w-2/3  overflow-y-auto  scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
            <table className="w-full text-sm">
              <thead className="text-gray-300 text-body_14 px-4 py-3 border-b border-page-border">
                <tr className="">
                  <td>Assets</td>
                  <td>Play time</td>
                </tr>
              </thead>
              <tbody>
                {assets.map((x, i) => (
                  <PlaylistAssetListItem
                    key={x.assetPlaylistId}
                    asset={x}
                    loggedInUser={loggedInUser}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <div className="ml-7 w-1/3 overflow-y-auto  scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
            <span className="text-gray-100 text-body_14">
              Upcoming schedules
            </span>
            {schedules.map((x, i) => (
              <div
                key={i}
                className="bg-Secondary-Input-bg flex justify-between items-center w-full p-3 my-3 cursor-pointer"
                onClick={() => handleScheduleSelection(x)}
              >
                <div className="flex gap-4 items-center">
                  <div className="h-12 w-12 flex flex-col items-center justify-center rounded bg-button-bg-primary-default">
                    <small>
                      {dayjs(x.startDate)
                        .tz(currentCustomer?.timeZoneIANA)
                        .format("MMM")}
                    </small>
                    <b>
                      {dayjs(x.startDate)
                        .tz(currentCustomer?.timeZoneIANA)
                        .format("DD")}
                    </b>
                  </div>
                  <div>
                    <p>{x.canvasName}</p>
                    <span>
                      {dayjs(x.startDate)
                        .tz(currentCustomer?.timeZoneIANA)
                        .format("hh:mma") +
                        " - " +
                        dayjs(x.endDate)
                          .tz(currentCustomer?.timeZoneIANA)
                          .format("hh:mma")}
                    </span>
                  </div>
                </div>
                <div>
                  <UilAngleRightB />
                </div>
              </div>
            ))}
            <div>
              {showLoadMoreSchedule && (
                <div
                  className="text-button-bg-primary-default p-2 cursor-pointer text-center"
                  onClick={handleLoadMoreSchedule}
                >
                  load more
                </div>
              )}
            </div>
          </div>
        </div>
        <DeletePlaylistAssetModal onSuccess={retrieveAssets} />
      </div>
    );
}
